<template>
  <AppPage>
    <AppPageHeader>
      Asset Groups
      <template #title-adjacent>
        <div class="flex gap-5">
          <WcCTA text="Connect my data" weight="heavy" icon="wc-carbon:lightning" />
        </div>
      </template>
      <template #description>
        View and manage assets that you have registered on WEATS. Each of these represents either an energy-consuming asset or an energy intervention
        resulting in EACs. Examples of energy interventions include solar panels, batteries, demand response events, and buildings that underwent an
        energy efficiency upgrade.
        <LearnMore :href="`${WWW_BASE_URL}/faq#suppliers`" />
      </template>
    </AppPageHeader>
    <AppPageContent>
      <div class="flex items-baseline justify-between">
        <h3 class="text-subheading-1 mb-3">Asset Groups</h3>
        <WcCTA text="Create Asset Group" size="medium" weight="heavy" icon="wc-carbon:add" icon-position="right" @click="showCreateAssetGroupModal" />
      </div>
      <hr />
      <div class="mt-6 flex grow flex-wrap items-center justify-between gap-3">
        <div class="flex flex-wrap gap-3">
          <WcButton
            v-for="(label, key) in ASSET_GROUP_SORT_BY"
            :key="key"
            :variant="key === assetGroupSort.key ? 'primary' : 'secondary'"
            color="sage"
            :text="label"
            size="small"
            :icon="iconForSortButton(key)"
            icon-position="right"
            @click="handleToggleSortGroups(key)" />
        </div>
        <WcInputText
          v-model="filterAssetGroupName"
          icon="wc-carbon:search"
          icon-position="left"
          class="shrink-0 basis-56"
          name="filterAssetGroupName"
          inset-label="Search"
          size="small" />
      </div>
      <div class="mb-5 mt-8 overflow-x-scroll">
        <AssetGroupsSection
          v-if="!isLoading && !hasAssetGroupError"
          :asset-groups="assetGroups"
          @row-clicked="handleAssetGroupRowClicked"
          @row-delete-clicked="handleAssetGroupRowDeleteClicked"
          @asset-group-updated="handleAssetGroupUpdated" />
        <InlineLoading v-if="isLoading" />
        <InlineError v-if="hasAssetGroupError" error-message="There was a problem loading asset groups. Please try again." />
      </div>
    </AppPageContent>
    <AddAssetGroupModal ref="addAssetGroupModal" @asset-group-added="handleAssetGroupAdded" />
    <AddAssetGroupWithAssetsModal ref="addAssetGroupWithAssetsModal" @asset-group-added="handleAssetGroupAdded" />
    <AddAssetsToGroupModal ref="addAssetsToGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { WcButton, WcCTA } from "@/components/button"
import { WcInputText } from "@/components/input"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import LearnMore from "@/components/ui/LearnMore.vue"
import { AssetGroupOrderBy, AssetGroupSortBy, ASSET_GROUP_SORT_BY } from "@/modules/asset/asset.service"
import type { AssetGroupWithSummary } from "@/modules/asset/asset.service"
import type { AssetGroup } from "@/models/asset"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import { getEnvironment } from "@/environment"
import AddAssetGroupModal from "./components/AddAssetGroupModal.vue"
import AddAssetGroupWithAssetsModal from "./components/AddAssetGroupWithAssetsModal.vue"
import AddAssetsToGroupModal from "./components/AddAssetsToGroupModal.vue"
import AssetGroupsSection from "./components/AssetGroupsSection.vue"

const { WWW_BASE_URL } = getEnvironment()

const router = useRouter()
const toast = useToast()
const assetService = useAssetService()

const assetGroups = ref<AssetGroupWithSummary[]>([])
const isLoading = ref<boolean>(true)

const selectedAssetGroupIds = ref<number[]>([])

const addAssetGroupModal = ref()
const addAssetGroupWithAssetsModal = ref()
const addAssetsToGroupModal = ref()

const hasAssetGroupError = ref<boolean>(false)
const assetGroupsPageInfo = ref<PageInfo>()
const assetGroupSort = ref<{ key: AssetGroupSortBy; direction: AssetGroupOrderBy }>({
  key: AssetGroupSortBy.group_name,
  direction: AssetGroupOrderBy.asc,
})

const filterAssetGroupName = ref<string | null | undefined>(null)

const loadAssetGroups = async (url?: string) => {
  try {
    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetGroupName.value && { name: filterAssetGroupName.value }),
    }
    const result = await assetService.listAssetGroups({
      per_page: 12,
      url,
      orderBy: assetGroupSort.value.direction,
      sortBy: assetGroupSort.value.key,
      ...filters,
    })
    assetGroups.value = result.data
    assetGroupsPageInfo.value = result.pageInfo
    selectedAssetGroupIds.value = []
    hasAssetGroupError.value = false
  } catch (error) {
    hasAssetGroupError.value = true
    console.error("There was an error loading asset groups", error)
  }
  isLoading.value = false
}

onMounted(async () => {
  loadAssetGroups()
})

const iconForSortButton = (key: AssetGroupSortBy) => {
  if (assetGroupSort.value.key === key) {
    return assetGroupSort.value.direction === AssetGroupOrderBy.asc ? "wc-carbon:arrow-up" : "wc-carbon:arrow-down"
  }
  return undefined
}

const handleToggleSortGroups = (key: AssetGroupSortBy) => {
  if (assetGroupSort.value.key === key) {
    assetGroupSort.value.direction = assetGroupSort.value.direction === AssetGroupOrderBy.asc ? AssetGroupOrderBy.desc : AssetGroupOrderBy.asc
  } else {
    assetGroupSort.value.key = key
    if (key === AssetGroupSortBy.created_time || key === AssetGroupSortBy.updated_time) {
      // For the date sort options, default to descending order (newest first)
      assetGroupSort.value.direction = AssetGroupOrderBy.desc
    } else {
      assetGroupSort.value.direction = AssetGroupOrderBy.asc
    }
  }
  loadAssetGroups()
}

const showCreateAssetGroupModal = () => {
  addAssetGroupModal.value.openModal()
}

const handleAssetGroupRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/asset-groups/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleAssetGroupRowDeleteClicked = async (assetGroupId: number, event: MouseEvent) => {
  event.stopPropagation()
  const assetGroup = assetGroups.value.find((assetGroup) => assetGroup.id === assetGroupId)
  if (assetGroup && confirm(`Are you sure you want to delete asset group '${assetGroup.name}'?`)) {
    try {
      await assetService.deleteAssetGroup(assetGroup.id)
      loadAssetGroups()
      toast.success(`Asset group ${assetGroup.name} deleted`)
    } catch (error) {
      console.error("There was an error deleting the asset group", error)
      toast.error("There was an error deleting the asset group. Please try again.")
    }
  }
}

const handleAssetGroupUpdated = () => {
  loadAssetGroups()
}

const handleAssetGroupAdded = (addedGroup: AssetGroup) => {
  router.push({ name: "wc-asset-groups-asset-group", params: { assetGroupId: addedGroup.id } })
}
</script>
