<template>
  <input
    :id="id"
    v-model="model"
    type="date"
    class="w-full rounded border border-sagetone-light"
    :class="inputClass"
    :name="name"
    :required="required" />
</template>

<script setup lang="ts">
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<string | null>()
const props = withDefaults(
  defineProps<{
    name: string
    id?: string
    required?: boolean
    size?: InputSize
  }>(),
  {
    required: false,
  }
)

const inputClass = useInputClass(props)
</script>
