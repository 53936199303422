<template>
  <div>
    <div v-if="isBrowser" class="chart-container">
      <div :id="chartId" :style="{ width: width || '100%', height: height || '400px' }"></div>
    </div>
    <div v-else class="loading-placeholder" :style="{ width: width || '100%', height: height || '400px' }">
      <span>Loading chart...</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, PropType, onBeforeUnmount } from "vue"
import type { EChartsOption } from "echarts"

const props = defineProps({
  chartOptions: {
    type: Object as PropType<EChartsOption>,
    required: true,
  },
  width: { type: String },
  height: { type: String },
  chartId: {
    type: String,
    default: () => `wc-chart-${Date.now()}`,
  },
})

const emit = defineEmits(["chartReady", "dataZoom", "click", "legendselectchanged"])

const isBrowser = ref(false)
let chart: any = null
let windowResizeHandler: (() => void) | null = null

onMounted(async () => {
  isBrowser.value = true

  setTimeout(async () => {
    const echarts = await import("echarts")
    const chartDom = document.getElementById(props.chartId)

    if (chartDom) {
      chart = echarts.init(chartDom)
      // Add padding to grid if it doesn't exist or merge with existing grid
      const enhancedOptions = { ...props.chartOptions }

      // Add or update grid configuration with padding
      enhancedOptions.grid = {
        left: 35,
        right: 10,
        top: 60,
        bottom: 35,
        containLabel: true,
        ...props.chartOptions.grid,
      }

      chart.setOption(enhancedOptions)

      // Set up event listeners
      chart.on("dataZoom", (params: any) => {
        emit("dataZoom", params)
      })

      chart.on("click", (params: any) => {
        emit("click", params)
      })

      chart.on("legendselectchanged", (params: any) => {
        emit("legendselectchanged", params)
      })

      // Set up resize handler
      new ResizeObserver(() => chart.resize()).observe(chartDom)

      // Window resize handler
      windowResizeHandler = () => {
        chart?.resize()
      }
      window.addEventListener("resize", windowResizeHandler)

      emit("chartReady", chart)
    }
  }, 100)
})

// Watch for changes to chart options
watch(
  () => props.chartOptions,
  (newOptions) => {
    if (chart) {
      // Apply the same padding logic on updates
      const enhancedOptions = { ...newOptions }
      enhancedOptions.grid = {
        left: 35,
        right: 35,
        top: 60,
        bottom: 35,
        containLabel: true,
        ...newOptions.grid,
      }
      chart.setOption(enhancedOptions)
    }
  },
  { deep: true }
)

// Clean up event listeners and observers
onBeforeUnmount(() => {
  if (windowResizeHandler) {
    window.removeEventListener("resize", windowResizeHandler)
  }

  chart?.dispose()
})

// Expose methods to parent
defineExpose({
  getChart: () => chart,
  setOption: (options: any, notMerge = false) => {
    chart?.setOption(options, notMerge)
  },
  resize: () => {
    chart?.resize()
  },
  clear: () => {
    chart?.clear()
  },
})
</script>

<style scoped>
.loading-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
</style>
