import { parseLinkHeaderRelUrl } from "@/utils/parseHeaderPagination"

type SdkResponse<T> = {
  data: T
  response: Response
}

export type Paginated<T> = SdkResponse<T> & {
  pageInfo: {
    nextPageUrl: string | undefined
    prevPageUrl: string | undefined
  }
}

export function withPagination<T>(sdkFn: (options: any) => Promise<SdkResponse<T>>, options?: any): Promise<Paginated<T>> {
  return sdkFn(options).then((response) => {
    const linkHeader = response.response.headers.get("link") || undefined
    const nextPageUrl = parseLinkHeaderRelUrl("next", linkHeader)
    const prevPageUrl = parseLinkHeaderRelUrl("prev", linkHeader)

    return {
      ...response,
      pageInfo: {
        nextPageUrl,
        prevPageUrl,
      },
    }
  })
}

// Utility function to wrap paginated endpoints with proper type inference
export function wrapPaginatedEndpoint<F extends (options?: any) => Promise<any>>(
  endpoint: F
): (options?: Parameters<F>[0]) => Promise<Paginated<NonNullable<Awaited<ReturnType<F>>["data"]>>> {
  return (options?: Parameters<F>[0]) =>
    withPagination<NonNullable<Awaited<ReturnType<F>>["data"]>>(endpoint, options).then((result) => ({
      ...result,
      data: result.data || ([] as any),
    }))
}
