<template>
  <div :class="`form-field form-field-${field.type}`">
    <label class="flex flex-col">
      <span v-if="isLabelBeforeInput" class="text-body-3 pl-0 text-black">{{ field.title }}{{ field.required ? "*" : "" }}</span>

      <!-- Render the appropriate input based on field type -->
      <WcDropdown
        v-if="field.type === 'select'"
        :id="id"
        v-model="modelValue"
        :name="name"
        :required="field.required"
        :options="field.options ?? []"
        size="medium" />

      <input v-else-if="field.type === 'checkbox'" :id="id" v-model="modelValue" type="checkbox" :name="name" />

      <WcInputDate v-else-if="field.type === 'date'" :id="id" v-model="modelValue" :name="name" :required="field.required" size="medium" />

      <WcInputText
        v-else-if="isTextInputField"
        :id="id"
        v-model="modelValue"
        :inputmode="field.inputmode"
        :min="field.min"
        :max="field.max"
        :name="name"
        :pattern="field.pattern"
        :required="field.required"
        :type="getInputType"
        :inset-label="field.placeholder"
        :use-grouping="false"
        size="medium" />

      <!-- Default input fields -->
      <input
        v-else
        :id="id"
        v-model="modelValue"
        :type="field.type"
        :name="name"
        :min="field.min"
        :max="field.max"
        :minlength="field.minLength"
        :maxlength="field.maxLength"
        :inputmode="field.inputmode"
        :pattern="field.pattern"
        :placeholder="field.placeholder"
        :required="field.required" />

      <span v-if="!isLabelBeforeInput" class="text-body-3 pl-0 text-black">{{ field.title }}{{ field.required ? " *" : "" }}</span>
    </label>
    <div v-if="error" class="text-caption mt-1 text-error">{{ error }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import WcInputText from "@/components/input/WcInputText.vue"
import { WcDropdown } from "@/components/input"
import WcInputDate from "@/components/input/WcInputDate.vue"
import type { SelectOption } from "@/components/input"

const props = defineProps<{
  field: {
    title: string
    type: string
    name: string
    required?: boolean
    options?: SelectOption<string>[]
    placeholder?: string
    min?: number
    max?: number
    minLength?: number
    maxLength?: number
    inputmode?: "text" | "email" | "search" | "tel" | "url" | "none" | "numeric" | "decimal"
    pattern?: string
    multiple?: boolean
  }
  error?: string
  id?: string
  modelValue?: any
  name: string
  prefix?: string
}>()

const emit = defineEmits(["update:modelValue"])

// Two-way binding for modelValue to map to/from select options
const modelValue = computed({
  get: () => {
    if (props.field.type === "select") {
      const options = props.field.options || []
      return options.find((opt) => opt.value === props.modelValue) || null
    }
    return props.modelValue
  },
  set: (value) => {
    if (props.field.type === "select") {
      const options = props.field.options || []
      if (value && "value" in value) {
        emit("update:modelValue", value.value)
      } else {
        emit("update:modelValue", options.find((opt) => opt.value === value)?.value || null)
      }
    } else {
      emit("update:modelValue", value)
    }
  },
})

const isLabelBeforeInput = computed(() => {
  return props.field.type !== "checkbox"
})

const isTextInputField = computed(() => {
  return ["text", "email"].includes(props.field.type)
})

const getInputType = computed(() => {
  return props.field.type === "email" ? "email" : undefined
})
</script>

<style lang="scss" scoped>
.form-field-checkbox label {
  @apply flex-row gap-2;

  input {
    @apply mt-1;
  }
}
</style>
