import { onBeforeUnmount, ref } from "vue"

export function usePolling(pollFn: () => Promise<void>, options?: { frequency?: number; maxPollCount?: number | null; immediate?: boolean }) {
  const { frequency = 5000, maxPollCount = 24 } = options ?? {}

  const isPolling = ref(false)
  const pollCount = ref(0)
  const pollInterval = ref<NodeJS.Timeout>()

  const startPolling = () => {
    stopPolling()

    if (options?.immediate) {
      pollFn()
    }

    pollCount.value = 0
    isPolling.value = true
    pollInterval.value = setInterval(async () => {
      if (maxPollCount !== null && pollCount.value >= maxPollCount) {
        stopPolling()
        return
      }

      pollCount.value++

      await pollFn()
    }, frequency)
  }

  const stopPolling = () => {
    if (pollInterval.value) {
      clearInterval(pollInterval.value)
      pollInterval.value = undefined
    }
    isPolling.value = false
  }

  onBeforeUnmount(stopPolling)

  return {
    isPolling,
    pollCount,
    startPolling,
    stopPolling,
  }
}
