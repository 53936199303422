import type { InjectionKey, Ref } from "vue"
import { inject, provide } from "vue"
import type { MenuItem } from "primevue/menuitem"

let nextId = 0

export interface WcMenuContext {
  addItem: (item: MenuItem) => void
  removeItem: (key: string) => void
  updateItem: (key: string, newItem: MenuItem) => void
  generateKey: () => string
}

const MenuKey = Symbol() as InjectionKey<WcMenuContext>

export function provideMenuContext(menuItems: Ref<MenuItem[]>) {
  const context: WcMenuContext = {
    addItem: (item: MenuItem) => {
      menuItems.value = [...menuItems.value, item]
    },
    removeItem: (key: string) => {
      menuItems.value = menuItems.value.filter((item) => item.key !== key)
    },
    updateItem: (key: string, newItem: MenuItem) => {
      const index = menuItems.value.findIndex((item) => item.key === key)
      if (index !== -1) {
        const newItems = [...menuItems.value]
        newItems[index] = newItem
        menuItems.value = newItems
      }
    },
    generateKey: () => {
      return `menu-item-${nextId++}`
    },
  }

  provide(MenuKey, context)
  return context
}

export function useMenuContext() {
  const context = inject(MenuKey)

  if (!context) {
    throw new Error("WcMenuItem must be used within a WcMenu")
  }

  return context
}
