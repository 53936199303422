<template>
  <div>
    <label for="internal-notes">Internal Notes (changes autosave)</label>
    <textarea id="internal-notes" :value="asset.reviewNotesInternal" rows="4" class="w-full" @keydown="handleInternalNotesChange" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useToast } from "vue-toastification"
import type { Asset } from "@/models/asset"
import { getAssetDisplayName } from "@/models/asset"
import { debounce } from "@/utils/debounce"
import { devicesBatchAlterDevicesAdmin } from "@/client"

const toast = useToast()

const emit = defineEmits(["on-asset-update-state"])
const { asset } = defineProps<{ asset: Asset }>()
const assetDisplayName = computed(() => getAssetDisplayName(asset))

const handleInternalNotesChange = debounce(async (event: Event) => {
  const assetId = asset.id
  if (!assetId) {
    return
  }
  const note = (event.target as HTMLTextAreaElement).value
  try {
    const review = {
      status: asset!.status,
      reviewNotesInternal: note,
      reviewNotesExternal: asset.reviewNotes,
    }
    emit("on-asset-update-state", { reviewNotesInternal: note })
    await devicesBatchAlterDevicesAdmin({
      body: [{ op: "review", deviceId: assetId, review }],
    })
  } catch (error) {
    const message = `There was a problem updating the note for ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
  }
})
</script>
