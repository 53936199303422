<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AppPage class="w-full">
    <section class="bg-background-sagelight">
      <header>
        <div class="wc-page-container w-full pb-16 pt-12">
          <div class="flex flex-wrap items-center justify-between gap-4">
            <h1 class="text-heading-6 bg-white px-7 py-4">
              {{ store.account.name }}
            </h1>
            <div class="flex gap-5">
              <template v-if="isAristotleEnabled">
                <WcCTA text="Connect my data" weight="heavy" icon="wc-carbon:lightning" @click="addDataProviderModal.openModal()" />
                <div class="block h-6 w-px bg-divider-light" />
                <WcCTA text="Meters" weight="heavy" icon="wc-carbon:meter" @click="router.push({ name: 'wc-meters' })" />
                <div class="block h-6 w-px bg-divider-light" />
              </template>

              <WcCTA text="Manage EACs" icon="wc-carbon:settings" weight="heavy" @click="router.push({ name: 'wc-eacs' })" />
              <template v-if="!isAristotleEnabled">
                <div class="block h-6 w-px bg-divider-light" />
                <WcCTA text="Benchmarking" icon="wc-ic:compare-arrows" weight="heavy" @click="router.push({ name: 'wc-accounting' })" />
              </template>
            </div>
          </div>
        </div>
      </header>

      <section class="wc-page-container wc-homepage-grid min-h-8 w-full">
        <section v-if="isAristotleEnabled === false" class="col-span-12">
          <div class="text-subheading-large mb-8">Your Overview</div>
          <DashboardMetrics />
        </section>
      </section>
      <WcTabs class="mt-10">
        <WcTabRouterLink :to="{ name: 'wc-dashboard-assets' }"> Assets </WcTabRouterLink>
        <WcTabRouterLink :to="{ name: 'wc-dashboard-eac-activity-log' }"> Activity Log </WcTabRouterLink>
      </WcTabs>
    </section>
    <section class="wc-page-container mt-9 w-full">
      <router-view></router-view>
    </section>
    <AddDataProviderModal ref="addDataProviderModal" />
  </AppPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { AppPage, WcTabRouterLink, WcTabs } from "@/components/layout"
import { WcCTA } from "@/components/button"
import { useAristotle } from "@/features"
import AddDataProviderModal from "@/modules/dataProvider/components/AddDataProviderModal.vue"
import DashboardMetrics from "./DashboardMetrics.vue"

// Services
import { useMainStore } from "@/store"

const router = useRouter()
const store = useMainStore()
const isAristotleEnabled = useAristotle()

const addDataProviderModal = ref()
</script>

<style scoped lang="scss">
table th {
  @apply text-left font-normal pr-8;
}
</style>
