<template>
  <Dialog
    modal
    :visible="isOpen"
    close-on-escape
    :closable="size !== 'full'"
    dismissable-mask
    :class="{
      'modal-40': size === '40' || !size,
      'modal-60': size === '60',
      'modal-80': size === '80',
      'modal-drawer': size === 'drawer',
      'modal-full': size === 'full',
      'p-dialog-maximized': size === 'full',
      disrupt: type === 'disrupt',
    }"
    :draggable="false"
    :position="size === 'drawer' ? 'bottom' : undefined"
    :style="{ width }"
    @update:visible="$emit('update:isOpen', $event)">
    <template #header>
      <template v-if="size === 'full'">
        <div class="flex w-full flex-wrap items-center text-white sm:justify-between sm:gap-3">
          <div class="lg:basis-[78px]">
            <WcIconButton
              v-if="icon !== null"
              class="-ml-2"
              size="medium"
              :icon="icon ?? 'wc-carbon:arrow-left'"
              color="green"
              dark-mode
              @click="$emit('update:isOpen', false)" />
          </div>
          <h1 class="text-body-1 my-auto max-sm:grow">{{ header }}</h1>
          <div v-if="$slots.actions" class="flex gap-3">
            <slot name="actions"></slot>
          </div>
          <div v-else>
            <WcButton text="Close" size="medium" variant="secondary" dark-mode @click="$emit('update:isOpen', false)" />
          </div>
        </div>
      </template>
      <template v-else>
        <Icon v-if="type === 'disrupt' && icon" :icon="icon" class="size-8" />
        <div class="flex flex-col gap-2">
          <div class="text-black" :class="{ 'text-subheading-large-bold': size === 'drawer', 'text-subheading-1': size !== 'drawer' }">
            {{ header }}
          </div>
          <div v-if="description" class="text-body-2">{{ description }}</div>
        </div>
      </template>
    </template>
    <slot name="panel"><slot></slot></slot>
    <template v-if="$slots.actions && size !== 'full'" #footer>
      <div class="footer-buttons ph-no-capture flex w-full flex-wrap justify-between sm:justify-end">
        <slot name="actions"></slot>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"
import { computed, watch } from "vue"
import Dialog from "primevue/dialog"
import { WcButton, WcIconButton } from "@/components/button"
import { useScrollLock } from "@/composables/useScrollLock"

const { lockScroll, unlockScroll } = useScrollLock()

defineEmits(["update:isOpen"])

defineSlots<{
  actions?: () => any
  panel?: () => any
  default?: () => any
}>()

type Props = {
  header: string
  description?: string
  icon?: string | null
  isOpen: boolean
  size?: "40" | "60" | "80" | "full" | "drawer"
}

type NonDisruptProps = Props & {
  type?: never
  icon?: never
}

type DisruptProps = Props & {
  type: "disrupt"
  icon: string | null
}

const props = withDefaults(defineProps<NonDisruptProps | DisruptProps>(), {
  size: "40",
})

// Full-screen modals need to use this to prevent the background from scrolling
watch(
  [() => props.isOpen, () => props.size],
  ([newIsOpen, newSize]) => {
    if (newSize !== "full") {
      return
    }

    if (newIsOpen) {
      lockScroll()
    } else {
      unlockScroll()
    }
  },
  { immediate: true }
)

const width = computed(() => {
  switch (props.size) {
    case "full":
      return undefined // Let p-dialog-maximized handle this
    case "drawer":
      return "100%"
    case "80":
      return "1152px" // 80% of the 1440px container
    case "60":
      return "864px" // 60% of the 1440px container
    case "40":
    default:
      return "576px" // 40% of the 1440px container
  }
})
</script>

<style lang="scss">
.p-dialog {
  margin: 0;
  border-radius: 0.5rem;
}
.p-dialog-header {
  border-radius: 0.5rem 0.5rem 0 0;
  align-items: flex-start;
  gap: 1rem;
}
.p-dialog-footer {
  border-radius: 0 0 0.5rem 0.5rem;
}
.p-dialog .p-dialog-content {
  @apply py-6;
}
.p-dialog {
  .p-dialog-content {
    @apply py-6;
  }
  .p-dialog-footer {
    @apply pt-2 pb-6;
  }
}
.p-dialog.modal-40 {
  .p-dialog-content,
  .p-dialog-header,
  .p-dialog-footer {
    @apply px-6;
  }

  .p-dialog-header {
    @apply py-4;
  }

  .p-dialog-footer {
    .footer-buttons {
      @apply justify-end gap-3;
    }
  }

  &.disrupt {
    .p-dialog-header-icons {
      display: none;
    }
    .p-dialog-header {
      padding-top: 3rem;
      padding-bottom: 0.25rem;
      border-bottom: none;
      flex-direction: column;
      gap: 1.5rem;
    }

    .p-dialog-content {
      padding-top: 0;
    }

    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
.p-dialog.modal-60 {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-8;
  }

  .p-dialog-header {
    @apply py-6;
  }

  .p-dialog-footer {
    .footer-buttons {
      @apply gap-3;
    }
  }
}
.p-dialog.modal-80 {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-8;
  }

  .p-dialog-header {
    @apply py-6;
  }

  .p-dialog-footer {
    .footer-buttons {
      @apply gap-4;
    }
  }
}

.p-dialog.modal-drawer {
  @apply absolute bottom-0 top-[100px];
  max-height: none;

  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-4 md:px-[60px];
  }

  .p-dialog-header {
    border-bottom: none;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .p-dialog-content {
    @apply grow;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .p-dialog-footer {
    @apply shadow-[0_-2px_2px_-1px_rgba(0,0,0,0.085)];
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
}

/* Full-screen modal styles */
.p-dialog.modal-full {
  /* PrimeVue's p-dialog-maximized provides core sizing */

  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    border-radius: 0;
  }

  .p-dialog-header {
    @apply px-4 md:px-[60px] py-3 bg-black h-[65px];
  }

  .p-dialog-content {
    @apply p-0;
    height: calc(100vh - 65px);
  }

  .p-dialog-header-icons {
    display: none;
  }
}

.p-dialog-header {
  border-bottom: 1px solid theme("colors.divider.light");
  @apply pt-[36px];
}
.p-dialog-footer {
  @apply pb-[36px];
}
.p-dialog-header-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.p-dialog-header-icon > svg {
  color: theme("colors.black");
  height: 1.125rem;
  width: 1.125rem;
}
</style>
