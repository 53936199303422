<template>
  <div v-if="!!pricingScheme" class="min-h-[600px] rounded-md bg-white p-3 shadow-md">
    <div class="flex w-full flex-row justify-between">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-xl break-words">{{ pricingScheme.name }}</h1>

        <table>
          <tr>
            <th scope="row">ID</th>
            <td class="font-mono">{{ pricingScheme.id }}</td>
          </tr>
          <tr>
            <th scope="row">Pricing Function</th>
            <td class="font-mono">{{ pricingScheme.pricingFunction }}</td>
          </tr>
        </table>
      </div>
    </div>

    <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
      <router-link class="tab" :to="{ name: 'wc-admin-pricing-scheme', params: { pricingSchemeId } }">Configuration</router-link>
    </nav>

    <template v-if="pricingScheme">
      <AdminMandVPricingSchemeView v-if="pricingScheme.pricingFunction === 'measurement_and_verification'" :pricing-scheme="pricingScheme" />

      <pre v-else>{{ pricingScheme }}</pre>
    </template>
  </div>
  <div v-else>
    <p>Pricing Scheme found.</p>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect, ref } from "vue"
import { pricingSchemesGetPricingScheme } from "@/client/sdk.gen"
import { PricingSchemesGetPricingSchemeResponses } from "@/client/types.gen"
import AdminMandVPricingSchemeView from "./AdminMandVPricingSchemeView.vue"

type PricingSchemeOut = PricingSchemesGetPricingSchemeResponses[200]

const props = defineProps<{
  pricingSchemeId: number
}>()

const pricingScheme = ref<PricingSchemeOut | null>(null)
watchEffect(async () => {
  if (props.pricingSchemeId !== undefined) {
    try {
      const response = await pricingSchemesGetPricingScheme({ path: { pricing_scheme_id: props.pricingSchemeId } })
      pricingScheme.value = response.data
    } catch {
      // ignore
    }
  }
})
</script>

<style scoped lang="scss">
table th {
  @apply text-left font-normal pr-8;
}

.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
