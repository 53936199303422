<template>
  <template v-for="field in form.fields" :key="field.name">
    <!-- Hidden fields -->
    <template v-if="hide.includes(`${prefix}${field.name}`)">
      <input v-model="model[field.name]" type="hidden" :name="`${prefix}${field.name}`" />
    </template>

    <!-- Nested form fields -->
    <template v-else-if="field.type === 'form'">
      <fieldset :id="`${prefix}${field.name}`" :name="`${prefix}${field.name}`" class="flex flex-col gap-y-4">
        <legend>{{ field.title }}</legend>
        <WcOpenApiFormFields
          v-model="model[field.name]"
          :form="field"
          :prefix="`${prefix}${field.name}.`"
          :hide="hide"
          :errors="getNestedErrors(field.name)" />
      </fieldset>
    </template>

    <!-- All other field types are handled by WcFormField -->
    <template v-else>
      <WcFormField
        :id="`${prefix}${field.name}`"
        v-model="model[field.name]"
        :field="field"
        :name="`${prefix}${field.name}`"
        :error="getFieldError(field.name)"
        :prefix="prefix" />
    </template>
  </template>
</template>

<script lang="ts" setup>
import { watch } from "vue"
import WcFormField from "./WcFormField.vue"
import { ApiValidationError, FormComponent } from "./WcOpenApiForm.utils"

const model = defineModel<any>({ default: {} })
const {
  form,
  prefix = "",
  hide = [],
  errors = [],
} = defineProps<{
  form: FormComponent
  prefix?: string
  hide?: string[]
  errors?: Array<ApiValidationError>
}>()

const initializeNestedFields = () => {
  if (!model.value) {
    model.value = {}
    return
  }

  const ensureNestedFieldObjectExists = (fields: any[], parentObj: any) => {
    fields.forEach((field) => {
      if (field.type === "form") {
        if (!parentObj[field.name]) {
          parentObj[field.name] = {}
        }
        ensureNestedFieldObjectExists(field.fields, parentObj[field.name])
      }
    })
  }

  ensureNestedFieldObjectExists(form.fields, model.value)
}

watch(
  () => form.fields,
  () => {
    initializeNestedFields()
  },
  { immediate: true }
)

// Helper function to get an error for a specific field
const getFieldError = (fieldName: string) => {
  const fieldPath = prefix ? `${prefix}${fieldName}` : fieldName
  const error = errors.find((err) => {
    const errPath = err.loc.slice(1).join(".")
    return errPath === fieldPath || errPath.endsWith(`.${fieldName}`)
  })
  return error?.msg
}

// Helper function to get errors for nested fields
const getNestedErrors = (fieldName: string) => {
  const fieldPath = prefix ? `${prefix}${fieldName}` : fieldName
  return errors.filter((err) => {
    const errPath = err.loc.slice(1).join(".")
    return errPath.startsWith(fieldPath + ".")
  })
}
</script>

<style lang="scss" scoped>
fieldset {
  @apply border border-neutral-50 rounded p-4;
}
</style>
