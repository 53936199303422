<template>
  <AppPage>
    <section class="bg-background-sagelight">
      <AppPageHeader class="-mb-9" show-account-context>
        EAC Management
        <template #description>
          <div>
            View your transactions, and view, retire, and report on your Energy Attribute Certificates (EACs). Each EAC represents a unique claim to
            the environmental benefits of an energy decarbonization project. This includes EACs purchased from suppliers as well as any EACs that were
            minted from assets you've registered.
          </div>
          <WcButton variant="secondary" size="small" text="Learn more" :href="`${WWW_BASE_URL}/faq#eacs`" class="my-4" />
        </template>
      </AppPageHeader>

      <div class="wc-page-container w-full">
        <div class="w-3/4 pb-10">
          <EacBalanceSummary />
        </div>
      </div>
    </section>

    <div class="wc-page-container mt-9 w-full">
      <router-view></router-view>
    </div>
  </AppPage>
</template>

<script setup lang="ts">
import { WcButton } from "@/components/button"
import { AppPage, AppPageHeader } from "@/components/layout"
import { getEnvironment } from "@/environment"
import EacBalanceSummary from "@/modules/accounts/components/EacBalanceSummary.vue"

const { WWW_BASE_URL } = getEnvironment()
</script>
