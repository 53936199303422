<template>
  <table class="w-full table-fixed border-t border-darktone-main">
    <thead>
      <tr class="text-subheading-2 text-left *:p-2">
        <th class="w-4/12">Asset name</th>
        <th class="w-4/12">Address</th>
        <th class="w-1/12">Category</th>
        <th class="w-2/12">Billing period</th>
        <th class="w-1/12 text-right">Total cost</th>
      </tr>
    </thead>
    <tbody class="border-y-[3px] border-darktone-main">
      <template v-if="invoice">
        <tr v-for="lineItem in invoice?.lineItems" :key="lineItem.device.id" class="text-body-2 *:px-2 *:py-3 *:align-top">
          <td class="underline">
            <router-link :to="{ name: 'wc-admin-asset-metadata', params: { assetId: lineItem.device.id } }">
              {{ lineItem.device.name ?? `Asset ${lineItem.device.id}` }}
            </router-link>
          </td>
          <td>{{ lineItem.device.address }}</td>
          <td>{{ lineItem.device.category == "consumption" ? "Asset tracking" : "M&V" }}</td>
          <td>
            <span class="whitespace-nowrap">{{ lineItem.billingPeriod.lower }}</span
            >&ndash;<wbr /><span class="whitespace-nowrap">{{ lineItem.billingPeriod.upper }}</span>
          </td>
          <td class="text-right">{{ formatCurrency(lineItem.pricePenniesUsd) }}</td>
        </tr>
        <tr v-if="invoice.lineItems.length === 0">
          <td colspan="5" class="px-2 py-4">None</td>
        </tr>
      </template>
      <template v-else-if="isLoading">
        <tr>
          <td colspan="5" class="p-4">
            <Skeleton class="w-full" height="10em" />
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="5" class="p-4">
            <InlineError error-message="Please try again" />
          </td>
        </tr>
      </template>
    </tbody>
    <tfoot>
      <tr class="text-subheading-2 *:px-2 *:py-3 *:align-top">
        <th class="text-left" scope="row">Total</th>
        <td>
          <template v-if="invoice"> {{ invoice.deviceCount.toLocaleString() }} assets </template>
          <template v-else-if="isLoading">
            <Skeleton class="w-12" height="1em" />
          </template>
        </td>
        <td></td>
        <td></td>
        <td class="text-right">
          <template v-if="invoice">
            {{ formatCurrency(invoice.totalPricePenniesUsd) }}
          </template>
          <template v-else-if="isLoading">
            <Skeleton class="w-12" height="1em" />
          </template>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script setup lang="ts">
import { invoicesGetInvoiceAdmin } from "@/client/sdk.gen"
import { ref, onMounted } from "vue"
import { Invoice } from "@/client/types.gen"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"
import Skeleton from "primevue/skeleton"
import InlineError from "@/components/ui/InlineError.vue"

const props = defineProps<{
  accountId: number
}>()

const formatCurrency = formatCurrencyInPenniesFactory()

const invoice = ref<Invoice | null>(null)
const isLoading = ref(true)
const error = ref<boolean>(false)
onMounted(async () => {
  isLoading.value = true
  try {
    const response = await invoicesGetInvoiceAdmin({
      path: {
        account_id: props.accountId,
      },
    })
    invoice.value = response.data
  } catch {
    error.value = true
  } finally {
    isLoading.value = false
  }
})
</script>
