import { AppPortal } from "@/models/models"
import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import AppLayout from "@/components/layout/AppLayout.vue"
import BillingView from "./BillingView.vue"

export const billingRoutes: Array<RouteRecordRaw> = [
  {
    path: "/billing",
    name: "wc-billing",
    component: BillingView,
    meta: { layout: AppLayout, appPortal: AppPortal.Dashboard, title: "Billing" },
    beforeEnter: authorizedRoute,
  },
]
