import ApiFetcher from "@/services/api-fetcher"
import { EacRetirementCreate, EacUnits } from "./certificate.model"
import { format } from "date-fns"
import { AccountName } from "@/models/models"

export interface DateRangeQuery {
  startDate: Date
  endDate: Date
}

export interface DateTimeRangeQuery extends DateRangeQuery {
  startHour: number
  endHour: number
}

export interface GetBalancesOptions {
  dateTimeRange?: DateTimeRangeQuery
  url?: string
  supplierIds?: number[]
}

export interface DateTimeRange {
  lower: string
  upper: string
}

export interface PublicWeatsSummary {
  avgPricePenniesUsdPerGco2: number
  totalEacs: number
  totalAssetsRegistered: number
}

export interface CertificateBalanceSummary {
  suppliers: AccountName[]
  balances: {
    interval: DateTimeRange
    subaccountKind: "active" | "retirement"
    units: EacUnits
    balance: number
  }[]
}

export default class CertificateService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  retireEacs = async (eacRetirements: EacRetirementCreate) => {
    return await this.fetcher.httpPut("/certificates/retire", eacRetirements)
  }

  getWeatsSummaryPublic = async () => {
    return await this.fetcher.httpGet<PublicWeatsSummary>("/meta/summary", {})
  }

  getBalanceSummary = async (options: { aggregateBy: string; firstDatetime?: Date; lastDatetime?: Date; supplierIds?: number[] }) => {
    return await this.fetcher.httpGet<CertificateBalanceSummary>("/certificates/summary", {
      aggregateBy: options.aggregateBy,
      ...(options.firstDatetime && { firstDatetime: format(options.firstDatetime, "yyyy-MM-dd") }),
      ...(options.lastDatetime && { lastDatetime: format(options.lastDatetime, "yyyy-MM-dd") }),
      ...(options.supplierIds && { supplierId: options.supplierIds }),
    })
  }

  getBalances = async (options: GetBalancesOptions) => {
    let response
    if (options.dateTimeRange) {
      const { startDate, endDate, startHour, endHour } = options.dateTimeRange
      response = await this.fetcher.httpGetPaginated<string>("/certificates/balances", {
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        startHour,
        endHour,
        ...(options.supplierIds && { supplierId: options.supplierIds }),
      })
    } else if (options.url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = options.url.indexOf("/certificates/balances")
      const url = options.url.slice(pathIndex)
      response = await this.fetcher.httpGetPaginated<string>(url, {})
    } else {
      throw Error("one of dateTimeRange or url required")
    }
    return response
  }
}
