<template>
  <div v-if="pageInfo?.prevPageUrl || pageInfo?.nextPageUrl" class="flex justify-end gap-8">
    <WcCTA
      text="Previous"
      icon="wc-carbon:chevron-left"
      icon-position="left"
      :is-disabled="pageInfo?.prevPageUrl === undefined || isDisabled"
      size="medium"
      weight="heavy"
      @click="handleClick(pageInfo?.prevPageUrl)" />
    <WcCTA
      text="Next"
      icon="wc-carbon:chevron-right"
      icon-position="right"
      :is-disabled="pageInfo?.nextPageUrl === undefined || isDisabled"
      size="medium"
      weight="heavy"
      @click="handleClick(pageInfo?.nextPageUrl)" />
  </div>
</template>

<script setup lang="ts">
import { WcCTA } from "@/components/button"
import type { PageInfo } from "@/services/base-fetcher"

const emit = defineEmits<{ "load-items": [url?: string, page?: string | null] }>()
defineProps<{ pageInfo?: PageInfo; isDisabled?: boolean }>()

function handleClick(url: string | undefined) {
  if (!url) {
    return
  }

  const page = new URL(url).searchParams.get("page")
  emit("load-items", url, page)
}
</script>
