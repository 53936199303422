<template>
  <WcModal
    :is-open="true"
    size="full"
    header="Add assets to portfolio"
    icon="wc-carbon:close"
    @update:is-open="(value) => !value && $emit('modal-reject')">
    <div v-if="portfolio" class="wc-page-container py-12">
      <h2 class="text-subheading-large-bold">{{ portfolio.name }}&ndash;{{ portfolio.id }}</h2>

      <div class="mt-9 w-full border-t border-divider-extralight pt-9">
        <section class="flex flex-wrap gap-2 py-2">
          <WcDropdown
            v-model="filterAssetStatus"
            class="basis-40"
            name="filterAssetStatus"
            :options="statusOptions"
            inset-label="Status"
            size="small" />
          <WcDropdown
            v-if="accountOptions"
            v-model="filterAssetAccount"
            class="basis-40"
            name="filterAssetAccount"
            :options="accountOptions"
            inset-label="Account" />
          <WcCalendarRange
            v-model="filterAssetCreatedDateRange"
            class="shrink-0 basis-40"
            :class="{ 'basis-56': (filterAssetCreatedDateRange || [])[1] != null }"
            name="filterAssetCreatedDateRange"
            inset-label="Date created"
            size="small" />
          <WcInputText
            v-model="filterAssetLocationKeyword"
            icon="wc-carbon:search"
            class="shrink-0 basis-48"
            name="filterAssetLocationKeyword"
            inset-label="Filter by location"
            size="small" />
          <WcCTA
            class="shrink-0 basis-52 pl-1"
            icon-position="right"
            icon="wc-carbon:close-outline"
            text="Clear Filters"
            size="small"
            @click="handleClearFilters" />
        </section>

        <div class="mt-4 overflow-x-scroll pb-3">
          <AssetsTable
            :assets="availableAssets"
            allow-multiple-selection
            select-on-row-click
            :selected-row-ids="selectedAvailableAssetIds"
            show-eacs
            show-kind
            show-account
            table-class="w-full"
            @rows-selected="handleAvailableRowsSelected" />
        </div>
        <PaginationButtons class="mt-3" :page-info="availableAssetsPageInfo" :is-disabled="isLoading" @load-items="loadAvailableAssets" />
        <EmptyState v-if="!isLoading && !hasError && availableAssets.length === 0" title="No assets available to add">
          No assets match your filters
        </EmptyState>
        <InlineLoading v-if="isLoading && availableAssets.length === 0" />
        <InlineError v-if="hasError" error-message="There was a problem loading assets. Please try again." />
        <div
          v-if="selectedAvailableAssetIds.length > 0"
          class="mt-8 flex h-16 w-full flex-wrap items-center justify-between gap-2 bg-darktone-lightbackground px-5 py-3">
          <div class="text-body-2 shrink-0">
            <div class="flex items-baseline gap-2">
              <span class="text-subheading-large-bold">{{ selectedAvailableAssetIds.length }}</span>
              <span class="text-subheading-1">assets selected</span>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <WcButton
              text="Cancel"
              size="medium"
              variant="secondary"
              :is-disabled="selectedAvailableAssetIds.length === 0"
              @click="handleAvailableClearSelection" />
            <WcButton text="Add Assets" size="medium" :is-disabled="selectedAvailableAssetIds.length === 0" @click="handleAddAssets" />
          </div>
        </div>
      </div>
    </div>
    <InlineLoading v-if="portfolio == null && isLoading" />
    <InlineError v-if="portfolio == null && hasError" error-message="There was a problem loading the portfolio. Please try again." />
  </WcModal>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { format } from "date-fns"
import type { AssetStatus } from "@/models/asset"
import { ASSET_STATUS } from "@/models/asset"
import { WcButton, WcCTA } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { WcCalendarRange, WcDropdown, WcInputText } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcModal from "@/components/WcModal.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { debounce } from "@/utils/debounce"
import AssetsTable from "./AssetsTable.vue"
import { accountsListAccounts, BaseDeviceOutWithSummary, devicesListDevicesAdminPaginated, PortfolioOut, portfoliosGetPortfolio } from "@/client"
import { useConfirm } from "@/components/confirm/ConfirmContext"

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))

const props = defineProps<{ portfolioId: string }>()
const emit = defineEmits<{
  (e: "modal-reject"): void
  (e: "modal-resolve", value: { assetIds: number[] }): void
}>()

const { confirm } = useConfirm()

const isLoading = ref<boolean>(false)
const hasError = ref<boolean>(false)
const portfolio = ref<PortfolioOut | null>(null)
const availableAssets = ref<BaseDeviceOutWithSummary[]>([])
const availableAssetsPageInfo = ref<PageInfo>()
const accountOptions = ref<SelectOption<number>[] | null>(null)

const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)
const filterAssetLastUpdateDateRange = ref<Date[] | null | undefined>(null)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetAccount = ref<SelectOption<number> | null>(null)
const filterAssetEacProductionDateRange = ref<Date[] | null | undefined>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)

const selectedAvailableAssetIds = ref<number[]>([])

const loadPortfolio = async () => {
  try {
    const response = await portfoliosGetPortfolio({ path: { portfolio_id: props.portfolioId } })
    portfolio.value = response.data
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset group", error)
  }
  isLoading.value = false
}

const loadAvailableAssets = async (url?: string, page?: string | null) => {
  isLoading.value = true
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]

    const lastUpdateStart = (filterAssetLastUpdateDateRange.value ?? [])[0]
    const lastUpdateEnd = (filterAssetLastUpdateDateRange.value ?? [])[1]

    const eacProductionStart = (filterAssetEacProductionDateRange.value ?? [])[0]
    const eacProductionEnd = (filterAssetEacProductionDateRange.value ?? [])[1]

    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetAccount.value && { accountIds: [filterAssetAccount.value.value] }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
      ...(lastUpdateStart && { lastUpdateStart: format(lastUpdateStart, "yyyy-MM-dd") }),
      ...(lastUpdateEnd && { lastUpdateEnd: format(lastUpdateEnd, "yyyy-MM-dd") }),
      ...(eacProductionStart && { eacProductionStart: format(eacProductionStart, "yyyy-MM-dd") }),
      ...(eacProductionEnd && { eacProductionEnd: format(eacProductionEnd, "yyyy-MM-dd") }),
    }
    const result = await devicesListDevicesAdminPaginated({
      query: {
        page,
        excludedPortfolioId: props.portfolioId,
        per_page: 10,
        ...filters,
      },
    })
    availableAssets.value = result.data as BaseDeviceOutWithSummary[]
    availableAssetsPageInfo.value = result.pageInfo
    selectedAvailableAssetIds.value = []
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading available assets", error)
  }
  isLoading.value = false
}

const loadAccountOptions = async () => {
  const result = await accountsListAccounts({ query: { hasDevices: true } })
  accountOptions.value = result.data.map(({ id, name }) => ({ label: name, value: id }))
}

onMounted(() => {
  loadPortfolio()
  loadAvailableAssets()
  loadAccountOptions()
})

watch(
  [
    filterAssetCreatedDateRange,
    filterAssetLastUpdateDateRange,
    filterAssetStatus,
    filterAssetAccount,
    filterAssetEacProductionDateRange,
    filterAssetLocationKeyword,
  ],
  debounce(() => {
    loadAvailableAssets()
  })
)

const handleClearFilters = () => {
  filterAssetCreatedDateRange.value = null
  filterAssetLastUpdateDateRange.value = null
  filterAssetStatus.value = null
  filterAssetEacProductionDateRange.value = null
  filterAssetLocationKeyword.value = null
  filterAssetAccount.value = null
}

const handleAvailableRowsSelected = (selectedRowIDs: number[]) => {
  selectedAvailableAssetIds.value = selectedRowIDs
}

const handleAvailableClearSelection = () => {
  selectedAvailableAssetIds.value = []
}

const handleAddAssets = async () => {
  const hasSelectedAssetsInExistingPortfolios = availableAssets.value.some(
    (asset) => !!asset.portfolioId && selectedAvailableAssetIds.value.indexOf(asset.id) > -1
  )
  if (hasSelectedAssetsInExistingPortfolios) {
    const result = await confirm({
      title: "Assets already in a portfolio",
      message:
        "One or more of the selected assets are already in a portfolio and this will move them to the new portfolio. Existing allocations will remain but future EACs will be transferred to stakeholders in this portfolio instead. Are you sure you want to continue?",
      confirmText: "Move Assets To This Portfolio",
    })
    if (!result) {
      return
    }
  }

  emit("modal-resolve", { assetIds: selectedAvailableAssetIds.value })
}
</script>
