<template>
  <Icon
    :icon="statusInfo?.icon ?? 'wc-carbon:circle-solid'"
    :title="statusInfo?.summary ?? props.status"
    class="mr-2 size-4 min-w-4 stroke-[4px] drop-shadow"
    :class="{
      'stroke-cornyellow text-cornyellow': status === AssetStatus.draft,
      'stroke-highlight text-highlight': status === AssetStatus.ready,
      'text-neutral': status === AssetStatus.archived,
      'size-6 drop-shadow-none': !!statusInfo?.icon,
    }" />
</template>

<script setup lang="ts">
import { AssetStatus, ASSET_STATUS } from "@/models/asset"
import { Icon } from "@iconify/vue"
import { computed } from "vue"

const props = defineProps<{ status: AssetStatus }>()

const statusInfo = computed(() => ASSET_STATUS[props.status])
</script>
