<template>
  <template v-if="false"></template>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, watch } from "vue"
import type { MenuItem } from "primevue/menuitem"
import type { ButtonColorLightMode } from "@/components/button/WcButton"
import { useMenuContext } from "./useMenuContext"

const props = withDefaults(
  defineProps<{
    color?: ButtonColorLightMode
    disabled?: boolean
    icon?: string
    text: string
    onClick?: () => void
  }>(),
  {
    color: "green",
    disabled: false,
  }
)

const menu = useMenuContext()

const itemKey = menu.generateKey()

const item = computed<MenuItem>(() => ({
  key: itemKey,
  class: `wc-menu-item--${props.color}`,
  color: props.color,
  command: props.onClick,
  disabled: props.disabled,
  icon: props.icon,
  label: props.text,
}))

onMounted(() => {
  menu.addItem(item.value)
})

onUnmounted(() => {
  menu.removeItem(itemKey)
})

watch(
  () => ({
    color: props.color,
    disabled: props.disabled,
    icon: props.icon,
    text: props.text,
  }),
  () => {
    menu.updateItem(itemKey, item.value)
  }
)
</script>
