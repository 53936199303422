<template>
  <WcModal size="60" :is-open="isOpen" header="Partner integrations" @update:is-open="handleClose">
    <template #panel>
      <!-- Step: Select Provider -->
      <div v-if="currentStep === Step.SELECT_PROVIDER" class="partner-selection">
        <div class="my-6 flex flex-wrap justify-between gap-8 md:mx-8">
          <div v-for="partner in PARTNERS" :key="partner.apiProvider" class="flex min-h-[100px] w-[200px] flex-col" @click="selectPartner(partner)">
            <div
              class="flex h-[80px] flex-col items-center justify-center rounded-[4px] border border-sagetone-light px-6"
              :class="{
                'cursor-pointer hover:border-sagetone-dark': partner.enabled,
              }">
              <img v-if="partner.logo" :src="partner.logo" :alt="partner.name" />
              <span v-else class="text-subheading-1">{{ partner.name }}</span>
            </div>
            <div v-if="!partner.enabled" class="text-body-3 pt-1 text-center text-hint">Coming soon</div>
          </div>
        </div>
        <div class="mb-8 mt-2 w-full border-t border-divider-extralight" />

        <div class="text-body-1 align-center mb-4 ml-8 flex gap-2 text-black">
          <Icon icon="wc-carbon:help" class="size-[22px]" />
          <span>I don't have a data provider, or it isn't listed. <a href="mailto:info@wattcarbon.com" class="text-body-1-link">Contact us</a></span>
        </div>
      </div>

      <!-- Step: Configure Connection -->
      <div v-else-if="currentStep === Step.CONFIGURE_CONNECTION" class="api-key-entry">
        <div class="mb-4 mt-6 md:mx-20 md:mb-20">
          <label class="text-subheading-large-bold mb-9 flex flex-row items-center text-black">
            Enter {{ selectedPartner!.name }} API key
            <ToolTip
              v-if="selectedPartner!.apiProvider === 'utility_api'"
              button-class="w-4 mx-2 !pt-1"
              opener-color="primary"
              panel-class="!left-1 !-top-10 w-[158px]">
              <a href="https://utilityapi.com/settings#api-settings" target="_blank" rel="noopener" class="text-body-2-link">Create an API token</a>
            </ToolTip>
          </label>
          <WcInputText v-model="apiKey" name="apiKey" placeholder="API key" autofocus />
        </div>
      </div>

      <!-- Step: Import Meters -->
      <div v-else-if="currentStep === Step.IMPORT_METERS" class="mb-12 mt-6 text-black">
        <div class="text-subheading-large-bold mb-5">Importing meters</div>
        <div class="text-body-1 mb-9">Please wait while we set up your connection...</div>
        <InlineLoading />
      </div>

      <!-- Step: Connection Error -->
      <div v-else-if="currentStep === Step.CONNECTION_ERROR" class="mb-12 mt-6 text-black">
        <div class="text-subheading-large-bold mb-5">Import Failed</div>
        <div class="text-body-2 max-w-[600px]">
          There was a problem connecting to {{ selectedPartner!.name }}. Please check your API key and try again. If the problem persists, contact our
          support team.
        </div>
      </div>
    </template>

    <template #actions>
      <div class="grow"></div>
      <WcButton v-if="currentStep !== Step.IMPORT_METERS" text="Cancel" variant="secondary" size="small" @click="handleClose" />
      <WcButton
        v-if="currentStep === Step.CONFIGURE_CONNECTION"
        text="Import meters"
        size="small"
        :is-disabled="!canProceed || isLoading"
        :is-loading="isLoading"
        @click="startImport()" />
      <WcButton v-if="currentStep === Step.CONNECTION_ERROR" text="Try Again" size="small" @click="goToStep(Step.SELECT_PROVIDER)" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"
import { WcButton } from "@/components/button"
import { WcInputText } from "@/components/input"
import ToolTip from "@/components/ui/ToolTip.vue"
import WcModal from "@/components/WcModal.vue"
import { useDataProviderService } from "@/services/service-container"
import type { DataProvider, Partner } from "../dataProvider.service"
import { PARTNERS } from "../dataProvider.service"
import { useRouter } from "vue-router"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import type { ApiDataProvider } from "@/client/types.gen"
import { dataProvidersTriggerInitialMeterPull, metersListMetersPaginated } from "@/client/sdk.gen"

const router = useRouter()

enum Step {
  SELECT_PROVIDER = "select-provider",
  CONFIGURE_CONNECTION = "configure-connection",
  IMPORT_METERS = "import-meters",
  CONNECTION_ERROR = "connection-error",
}

const dataProviderService = useDataProviderService()

const isOpen = ref(false)
const currentStep = ref<Step>(Step.SELECT_PROVIDER)
const selectedPartner = ref<Partner | null>(null)
const apiKey = ref("")
const isLoading = ref(false)

const canProceed = computed(() => {
  if (currentStep.value === Step.SELECT_PROVIDER) return selectedPartner.value !== null
  if (currentStep.value === Step.CONFIGURE_CONNECTION) return apiKey.value.trim() !== ""
  return true
})

const selectPartner = (partner: Partner) => {
  if (!partner.enabled) {
    return
  }
  selectedPartner.value = partner
  goToStep(Step.CONFIGURE_CONNECTION)
}

const goToStep = (step: Step) => {
  currentStep.value = step
}

const startImport = async () => {
  isLoading.value = true

  try {
    const dataProvider = await dataProviderService.createDataProvider({
      api_provider: selectedPartner.value!.apiProvider!,
      credentials: { api_key: apiKey.value },
    })

    await dataProvidersTriggerInitialMeterPull({
      path: {
        data_provider_client_id: dataProvider.id,
      },
    })

    goToStep(Step.IMPORT_METERS)

    let pollCount = 0
    const MAX_POLLS = 10 // Poll for meters to be available for up to 30 seconds (10 * 3 second intervals)
    const pollInterval = setInterval(async () => {
      try {
        const result = await metersListMetersPaginated({
          query: {
            data_provider_id: dataProvider.id,
            api_provider: dataProvider.apiProvider as ApiDataProvider,
            per_page: 10,
          },
        })

        if (result.data.length > 0) {
          clearInterval(pollInterval)
          handleDataProviderComplete(dataProvider)
        }

        if (pollCount++ >= MAX_POLLS) {
          clearInterval(pollInterval)
          handleDataProviderComplete(dataProvider)
        }
      } catch (error) {
        console.error("Error polling for meters", error)
        clearInterval(pollInterval)
        handleDataProviderComplete(dataProvider)
      }
    }, 3000)
  } catch (err) {
    console.error("Error importing meters", err)
    goToStep(Step.CONNECTION_ERROR)
  } finally {
    isLoading.value = false
  }
}

const handleClose = () => {
  goToStep(Step.SELECT_PROVIDER)
  selectedPartner.value = null
  apiKey.value = ""
  isLoading.value = false
  isOpen.value = false
}

const handleDataProviderComplete = (dataProvider: DataProvider) => {
  router.push({ name: "wc-data-provider-detail", params: { dataProviderId: dataProvider.id } })
  handleClose()
}

const openModal = () => {
  isOpen.value = true
}

defineExpose({ openModal })
</script>
