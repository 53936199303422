<template>
  <AppPage>
    <AppPageHeader> Meters </AppPageHeader>
    <AppPageContent>
      <AppPageSection>
        <div v-if="meters.length > 0">
          <MetersTable v-if="meters.length > 0" :meters="meters" show-data-provider @row-clicked="handleMeterRowClick" />
          <PaginationButtons class="mt-3" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadMeters" />
        </div>
        <EmptyState v-else-if="!isLoading" title="No meters found">
          <a href="#" class="text-hyperlink" @click="addDataProviderModal.openModal()">Connect a data provider</a> to import meters into your account.
        </EmptyState>
        <InlineLoading v-if="isLoading" />
        <InlineError v-if="hasError" error-message="There was a problem loading meters. Please try again." />
      </AppPageSection>
    </AppPageContent>
    <AddDataProviderModal ref="addDataProviderModal" />
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { AppPage, AppPageContent, AppPageHeader, AppPageSection } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { metersListMetersPaginated } from "@/client/sdk.gen"
import type { Meter } from "@/client/types.gen"
import AddDataProviderModal from "@/modules/dataProvider/components/AddDataProviderModal.vue"
import type { PageInfo } from "@/services/base-fetcher"
import MetersTable from "./components/MetersTable.vue"

const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)
const meters = ref<Meter[]>([])
const pageInfo = ref<PageInfo>()
const addDataProviderModal = ref()

const loadMeters = async (url?: string, page?: string | null) => {
  hasError.value = false

  try {
    const result = await metersListMetersPaginated({
      query: {
        per_page: 10,
        page,
      },
    })

    meters.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    hasError.value = true
    console.error("Error loading meters", error)
  } finally {
    isLoading.value = false
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleMeterRowClick = (meter: Meter) => {
  // Do nothing for now
}

onMounted(async () => {
  await loadMeters()
})
</script>
