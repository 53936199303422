<template>
  <section class="w-full">
    <template v-if="hasAvailableDateRange">
      <p class="text-body-2">Show values for the selected date range, aggregated over a given time period:</p>
      <div class="mb-5 mt-8 flex items-start justify-between pb-2">
        <div class="flex grow flex-wrap items-center gap-3">
          <div class="basis-[180px]">
            <label class="text-body-3 pb-1 text-black" for="interval">Time period</label>
            <WcDropdown id="interval" v-model="interval" name="interval" :options="intervalOptions" required size="small"> </WcDropdown>
          </div>
          <div class="shrink-0 basis-40" :class="{ 'basis-56': (dateRange || [])[1] != null }">
            <label class="text-body-3 pb-1 text-black" for="dateRange">Date range</label>
            <WcCalendarRange id="dateRange" v-model="dateRange" name="dateRange" size="small">
              <template #presetPanel="{ selectPreset, isPresetActive }">
                <div class="grid grid-cols-3 gap-4">
                  <WcCTA
                    v-for="(presetDateRange, label) in presets"
                    :key="label"
                    :text="label"
                    variant="text"
                    size="large"
                    :weight="isPresetActive(presetDateRange[0], presetDateRange[1]) ? 'heavy' : 'normal'"
                    @click="() => selectPreset(presetDateRange)" />
                </div>
              </template>
            </WcCalendarRange>
          </div>
          <div v-if="!isTrackingAsset" class="basis-[200px]">
            <label class="text-body-3 pb-1 text-black" for="source">EAC source</label>
            <WcDropdown id="source" v-model="source" name="source" :options="sourceOptions" required size="small"> </WcDropdown>
          </div>
        </div>
        <WcLoadingSpinner v-if="isLoading" class="mt-0.5 h-9 w-16" />
      </div>
      <div v-if="assetLoadshapeRows.length > 0" class="mb-8 flex flex-wrap gap-3">
        <LegendToggleItem v-if="hasElectricityRows" label="Energy" :checked="seriesVisibility.energy" @click="() => toggleSeries('energy')">
          <Icon icon="material-symbols:circle-outline" class="ml-2 size-[14px] text-energy" />
        </LegendToggleItem>
        <LegendToggleItem
          v-if="hasElectricityReferenceModelRows"
          label="Reference model"
          :checked="seriesVisibility.energyReferenceModel"
          @click="() => toggleSeries('energyReferenceModel')">
          <svg class="ml-2 size-[14px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="paint0_linear_488_10146" x1="0" y1="1" x2="0" y2="0">
                <stop stop-color="#CDD7DA" stop-opacity="0.3" />
                <stop offset="1" stop-color="#CDD7DA" />
              </linearGradient>
            </defs>
            <circle cx="12" cy="12" r="10" fill="url(#paint0_linear_488_10146)" />
          </svg>
        </LegendToggleItem>
        <LegendToggleItem label="Carbon" :checked="seriesVisibility.carbon" @click="() => toggleSeries('carbon')">
          <Icon icon="material-symbols:circle-outline" class="ml-2 size-[14px] text-carbon" />
        </LegendToggleItem>
        <LegendToggleItem
          v-if="hasElectricityRows && enableCarbonIntensity"
          label="Carbon intensity"
          :checked="seriesVisibility.carbonIntensity"
          @click="() => toggleSeries('carbonIntensity')">
          <Icon icon="wc-carbon:circle-dash" class="ml-2 size-[14px] text-neonred-400" />
        </LegendToggleItem>
      </div>
      <AssetEnergyOverTime
        :data="assetLoadshapeRows"
        height="382"
        :interval="interval?.value"
        :has-electricity-rows="hasElectricityRows"
        :has-electricity-reference-model-rows="hasElectricityReferenceModelRows"
        :enable-carbon-intensity="!isTrackingAsset"
        :series-visibility="seriesVisibility" />
    </template>
    <template v-else>
      <EmptyState v-if="isTrackingAsset" title="No data available">
        Once tracking data is available for your asset, that will be shown here.
      </EmptyState>
      <EmptyState v-else title="No EACs produced"> Once your asset(s) start producing EACs, those will be shown here. </EmptyState>
    </template>
  </section>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { Icon } from "@iconify/vue"
import { addDays, differenceInDays } from "date-fns"
import { WcCTA } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { WcCalendarRange, WcDropdown } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import { AssetKind } from "@/models/asset"
import type { AssetGroupSummary, AssetSummary, LoadshapeRequestOptions } from "@/modules/asset/asset.service"
import type { APIAssetLoadshapeRow, LoadshapeInterval } from "@/modules/asset/assetLoadshape.model"
import {
  AssetLoadshapeRow,
  convertToDaily,
  convertToMonthly,
  convertToUTCTimeOfDay,
  dateRangePresetGivenAvailableDataRange,
  LoadshapeEacSource,
  LOADSHAPE_EAC_SOURCE,
  LOADSHAPE_INTERVAL,
  makePresetDateRanges,
} from "@/modules/asset/assetLoadshape.model"
import { ELECTRICITY_UNITS } from "@/services/api/certificate.model"
import AssetEnergyOverTime from "./AssetEnergyOverTime.vue"
import LegendToggleItem from "./LegendToggleItem.vue"

const presets = makePresetDateRanges()

const intervalOptions = Object.entries(LOADSHAPE_INTERVAL).map(([value, label]) => ({ label, value }) as SelectOption<LoadshapeInterval>)

const props = defineProps<{
  isLoading: boolean
  assetKind?: AssetKind
  rows: APIAssetLoadshapeRow[]
  summary?: AssetSummary | AssetGroupSummary
}>()

const sources = []
if (props.summary?.eacs.sum.length) {
  sources.push(LoadshapeEacSource.minted_eacs)
}
if (props.summary?.provisionalEacs.sum.length) {
  sources.push(LoadshapeEacSource.provisional_eacs)
}
const sourceOptions = sources.map((value) => ({ label: LOADSHAPE_EAC_SOURCE[value], value }) as SelectOption<LoadshapeEacSource>)
const source = ref<SelectOption<LoadshapeEacSource> | undefined>(sourceOptions[0])

const parseAvailableDateRange = (summary?: AssetSummary | AssetGroupSummary) => {
  const sum = source.value?.value === LoadshapeEacSource.minted_eacs ? summary?.eacs?.sum : summary?.provisionalEacs?.sum
  const sumsWithDateRange = (sum || []).filter((eac) => eac.lower && eac.upper) as { lower: string; upper: string }[]
  if (sumsWithDateRange.length === 0) {
    return {
      lower: null,
      upper: null,
    }
  }
  return {
    lower: sumsWithDateRange.map(({ lower }) => lower).reduce((a, b) => (a < b ? a : b)),
    upper: sumsWithDateRange.map(({ upper }) => upper).reduce((a, b) => (a > b ? a : b)),
  }
}

const availableDataRange = parseAvailableDateRange(props.summary)

const defaultDateRange =
  availableDataRange.lower && availableDataRange.upper ? dateRangePresetGivenAvailableDataRange(availableDataRange) : presets["Last 12 Months"]

const hasAvailableDateRange = computed(() => availableDataRange.lower && availableDataRange.upper)
const dateRange = ref<Date[] | null | undefined>(defaultDateRange)
const interval = ref<SelectOption<LoadshapeInterval> | null>(intervalOptions[0])
const seriesVisibility = ref({
  energy: true,
  energyReferenceModel: true,
  carbon: false,
  carbonIntensity: false,
})

const emit = defineEmits<{
  "load-rows": [options: LoadshapeRequestOptions]
}>()

const isTrackingAsset = computed(() => props.assetKind === AssetKind.consumption)

// Tracking assets use provisional EACs, so select that for the source
watch(isTrackingAsset, (isTrackingAsset) => {
  if (isTrackingAsset) {
    source.value = sourceOptions[1]
  }
})

watch(
  [dateRange, source],
  () => {
    const startDate = (dateRange.value ?? [])[0] ?? defaultDateRange[0]
    let endDate = (dateRange.value ?? [])[1] ?? defaultDateRange[0]

    // Ensure end date is not before start date
    if (startDate > endDate) {
      endDate = startDate
    }

    // Ensure the date range is not greater than the API limit of 366 days
    if (differenceInDays(endDate, startDate) > 366) {
      endDate = addDays(startDate, 366)
      dateRange.value = [startDate, endDate]
    }

    const options = {
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      source: source.value?.value || LoadshapeEacSource.minted_eacs,
    } as LoadshapeRequestOptions
    emit("load-rows", options)
  },
  { immediate: true }
)

const assetLoadshapeRows = computed(() => {
  let rows
  if (interval.value?.value === "monthly") {
    rows = convertToMonthly(props.rows).map((row) => new AssetLoadshapeRow(row))
  } else if (interval.value?.value === "daily") {
    rows = convertToDaily(props.rows).map((row) => new AssetLoadshapeRow(row))
  } else if (interval.value?.value === "timeOfDay") {
    rows = convertToUTCTimeOfDay(props.rows).map((row) => new AssetLoadshapeRow(row))
  } else {
    rows = props.rows.map((row) => new AssetLoadshapeRow(row))
  }

  // Depending on data and time zones, we'll sometimes end up with an extra row at the end without any data. If so, remove it.
  if (rows.length > 0 && rows[rows.length - 1].values.length === 0) {
    rows = rows.slice(0, -1)
  }

  return rows
})

const hasElectricityRows = computed(() => {
  return props.rows.some((row) => row.values.some((v) => ELECTRICITY_UNITS.indexOf(v.units) !== -1))
})

const hasElectricityReferenceModelRows = computed(() => {
  if (!hasElectricityRows.value) {
    return false
  }
  return props.rows.some((row) => row.values.some((v) => v.comparisonQuantity !== null && v.comparisonQuantity > 0))
})

const enableCarbonIntensity = computed(() => !isTrackingAsset.value)

const toggleSeries = (series: keyof typeof seriesVisibility.value) => {
  seriesVisibility.value[series] = !seriesVisibility.value[series]
}

watch(
  hasElectricityRows,
  (newHasElectricityRows) => {
    seriesVisibility.value.carbon = !newHasElectricityRows
  },
  { immediate: true }
)
</script>
