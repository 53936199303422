<template>
  <AppPage class="bg-neutral-10">
    <AppPageContent>
      <section class="flex flex-row justify-between pt-12">
        <div class="flex flex-col justify-between">
          <div>
            <h1 class="text-subheading-large-bold min-w-0 break-words">Billing</h1>
            <p class="text-body-2 col-span-full mt-4 max-w-[762px]">
              Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
            </p>
          </div>
          <div class="wc-homepage-grid mt-3"></div>
        </div>

        <div class="flex w-3/12 flex-col rounded-lg border border-sagetone-main bg-white">
          <div class="m-4">
            <div class="text-body-2 border-b border-sagetone-light pb-2">Account name</div>
            <div class="text-subheading-2 pt-2">{{ mainStore.account.name }}</div>
          </div>

          <div class="m-4">
            <div class="text-body-2 border-b border-sagetone-light pb-2">Account ID</div>
            <div class="text-subheading-2 pt-2">{{ mainStore.account.id }}</div>
          </div>

          <hr class="mx-4 border-sagetone-main" />

          <div class="m-4 flex flex-row justify-between">
            <div class="text-body-2">Current Balance</div>
            <div class="text-subheading-2">
              <template v-if="invoice">
                {{ formatCurrency(invoice.totalPricePenniesUsd) }}
              </template>
              <template v-else-if="isLoading">
                <Skeleton width="6em" height="1em" />
              </template>
            </div>
          </div>

          <div class="flex flex-row justify-center rounded-b-lg border-t border-sagetone-main bg-sagetone-lightbackground p-4" title="Coming soon!">
            <WcButton size="small" icon="wc-carbon:arrow-right" icon-position="right" text="Complete payment setup" is-disabled />
          </div>
        </div>
      </section>

      <section class="mt-12">
        <table class="w-full table-fixed border-t border-darktone-main">
          <thead>
            <tr class="text-subheading-2 text-left *:p-2 *:align-top">
              <th class="w-4/12">Asset name</th>
              <th class="w-4/12">Address</th>
              <th class="w-1/12">Category</th>
              <th class="w-2/12">Billing period</th>
              <th class="w-1/12 text-right">Total cost</th>
            </tr>
          </thead>
          <tbody class="border-y-[3px] border-darktone-main">
            <template v-if="invoice">
              <tr v-for="lineItem in invoice?.lineItems" :key="lineItem.device.id" class="text-body-2 *:px-2 *:py-3 *:align-top">
                <td class="underline">
                  <router-link :to="{ name: 'wc-assets-asset', params: { assetId: lineItem.device.id } }">
                    {{ lineItem.device.name ?? `Asset ${lineItem.device.id}` }}
                  </router-link>
                </td>
                <td>{{ lineItem.device.address }}</td>
                <td>{{ lineItem.device.category == "consumption" ? "Asset tracking" : "M&V" }}</td>
                <td>
                  <span class="whitespace-nowrap">{{ lineItem.billingPeriod.lower }}</span
                  >&ndash;<wbr /><span class="whitespace-nowrap">{{ lineItem.billingPeriod.upper }}</span>
                </td>
                <td class="text-right">{{ formatCurrency(lineItem.pricePenniesUsd) }}</td>
              </tr>
              <tr v-if="invoice.lineItems.length === 0">
                <td colspan="5" class="px-2 py-4">None</td>
              </tr>
            </template>
            <template v-else-if="isLoading">
              <tr>
                <td colspan="5" class="p-4">
                  <Skeleton class="w-full" height="10em" />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="5" class="p-4">
                  <InlineError error-message="Please try again" />
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr class="text-subheading-2 *:px-2 *:py-3 *:align-top">
              <th class="text-left" scope="row">Total</th>
              <td>
                <template v-if="invoice"> {{ invoice.deviceCount.toLocaleString() }} assets </template>
                <template v-else-if="isLoading">
                  <Skeleton class="w-12" height="1em" />
                </template>
              </td>
              <td></td>
              <td></td>
              <td class="text-right">
                <template v-if="invoice">
                  {{ formatCurrency(invoice.totalPricePenniesUsd) }}
                </template>
                <template v-else-if="isLoading">
                  <Skeleton class="w-12" height="1em" />
                </template>
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { invoicesGetInvoice } from "@/client/sdk.gen"
import { ref, onMounted } from "vue"
import { Invoice } from "@/client/types.gen"
import WcButton from "@/components/button/WcButton.vue"
import AppPage from "@/components/layout/AppPage.vue"
import { useMainStore } from "@/store"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"
import Skeleton from "primevue/skeleton"
import InlineError from "@/components/ui/InlineError.vue"
import AppPageContent from "@/components/layout/AppPageContent.vue"

const mainStore = useMainStore()

const formatCurrency = formatCurrencyInPenniesFactory()

const invoice = ref<Invoice | null>(null)
const isLoading = ref(true)
const error = ref<boolean>(false)
onMounted(async () => {
  isLoading.value = true
  try {
    const response = await invoicesGetInvoice()
    invoice.value = response.data
  } catch {
    error.value = true
  } finally {
    isLoading.value = false
  }
})
</script>
