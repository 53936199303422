<template>
  <AppPageSection class="w-full">
    <div class="flex gap-16 p-1">
      <WcDataWithLabel>
        <template #label>
          <span>Status</span>
        </template>
        <template #content>
          <div class="mb-2 flex items-center">
            <AssetStatusIcon :status="props.asset.status" />
            <div>
              <strong>{{ ASSET_STATUS[props.asset.status]?.summary }}</strong>
              <p v-if="asset.status === AssetStatus.draft">{{ asset.reviewNotes }}</p>
            </div>
          </div>
        </template>
      </WcDataWithLabel>
      <WcDataWithLabel>
        <template #label>
          <span>Audit Status</span>
        </template>
        <template #content>
          <div class="mb-2 flex items-center">
            <AssetAuditStatusIcon :status="props.asset.auditStatus" />
            <div>
              <strong>{{ ASSET_AUDIT_STATUS[props.asset.auditStatus]?.summary }}</strong>
            </div>
          </div>
        </template>
      </WcDataWithLabel>
      <WcDataWithLabel>
        <template #label>
          <span>Recent jobs</span>
        </template>
        <template #content>
          <div v-if="recentJobs.length > 0" class="grid grid-cols-[auto_1fr] gap-x-4 gap-y-1">
            <template v-for="job in recentJobs" :key="job.kind">
              <div class="text-subheading-2 capitalize">{{ formatPossibleSnakeCaseStringForDisplay(job.kind) }}</div>
              <div>
                <span class="capitalize">{{ job.status }}</span>
                <span v-if="job.timestamp" class="ml-2">({{ formatDateAndTime(job.timestamp) }})</span>
              </div>
            </template>
          </div>
          <div v-else>None</div>
        </template>
      </WcDataWithLabel>
    </div>
  </AppPageSection>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { AppPageSection } from "@/components/layout"
import WcDataWithLabel from "@/components/ui/WcDataWithLabel.vue"
import { usePolling } from "@/composables/usePolling"
import type { Asset, AssetJob } from "@/models/asset"
import { ASSET_STATUS, ASSET_AUDIT_STATUS, AssetStatus } from "@/models/asset"
import { useAssetService } from "@/services/service-container"
import { formatDateAndTime } from "@/utils/formatDateAndTime"
import { formatPossibleSnakeCaseStringForDisplay } from "@/utils/formatPossibleSnakeCaseStringForDisplay"
import AssetStatusIcon from "./AssetStatusIcon.vue"
import AssetAuditStatusIcon from "./AssetAuditStatusIcon.vue"

const assetService = useAssetService()

const props = defineProps<{
  asset: Asset
  assetJobs: AssetJob[]
}>()

const assetJobs = ref<AssetJob[]>(props.assetJobs)

const { startPolling } = usePolling(
  async () => {
    assetJobs.value = await assetService.listAssetJobs(props.asset.id)
  },
  { frequency: 5000, maxPollCount: null }
)

onMounted(() => {
  startPolling()
})

const recentJobs = computed(() => {
  if (!assetJobs.value?.length) {
    return []
  }
  const firstJobOfEachKind = assetJobs.value?.reduce(
    (acc, job) => {
      if (!acc[job.kind]) acc[job.kind] = job
      return acc
    },
    {} as Record<string, AssetJob>
  )
  return Object.values(firstJobOfEachKind).sort((a, b) => a.timestamp.localeCompare(b.timestamp))
})
</script>
