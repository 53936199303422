<template>
  <time :datetime="time" :title="formatDateAndTime(time)">{{ formattedTime }}</time>
</template>

<script setup lang="ts">
import { usePolling } from "@/composables/usePolling"
import { ref, computed, onMounted } from "vue"
import { formatDateAndTime } from "@/utils/formatDateAndTime"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

const props = defineProps<{ time: string }>()
const parsedTime = computed(() => parseUTCTimestamp(props.time))

const formattedTime = ref<string>("")
const formatter = new Intl.RelativeTimeFormat("en", { style: "short" })
const { startPolling } = usePolling(
  async () => {
    const elapsedMilliseconds = Date.now() - parsedTime.value.getTime()
    if (elapsedMilliseconds > 86_400_000) {
      formattedTime.value = formatDateAndTime(props.time)
    } else if (elapsedMilliseconds > 3_600_000) {
      const elapsedHours = Math.floor(-elapsedMilliseconds / 3_600_000)
      formattedTime.value = formatter.format(elapsedHours, "hours")
    } else if (elapsedMilliseconds > 60_000) {
      const elapsedMinutes = Math.floor(-elapsedMilliseconds / 60_000)
      formattedTime.value = formatter.format(elapsedMinutes, "minutes")
    } else {
      formattedTime.value = "just now"
    }
  },
  { frequency: 30_000, maxPollCount: null, immediate: true }
)

onMounted(() => {
  startPolling()
})
</script>
