<template>
  <div>
    <h2 class="text-subheading-large-bold mb-4">Select meters</h2>
    <p class="text-body-2 mb-12 max-w-[800px]">
      <template v-if="selectedRegistrationType === RegistrationType.TRACKING">
        Choose one or more meters that will be associated with this asset.
      </template>
      <template v-else>
        Choose the meters that will be associated with this asset. If you don't have a meter you want to use, continue to the next step to fill in
        information about your asset.
      </template>
    </p>

    <div class="flex flex-col gap-12">
      <div v-if="modelValue.length > 0" class="flex flex-col gap-2">
        <div v-for="meter in modelValue" :key="meter.id" class="flex max-w-[944px] flex-col rounded-sm border border-sagetone-light bg-white p-4">
          <div class="flex justify-between gap-2">
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-3">
                <h3 class="text-subheading-2">Meter {{ meter.id }}</h3>
                <span
                  class="text-body-3 inline-flex items-center rounded-full border px-3 capitalize"
                  :class="{
                    'border-cornyellow-200 bg-cornyellow-200': meter.meterType === 'electricity',
                    'border-info-light bg-info-light': meter.meterType !== 'electricity',
                  }"
                  >{{ meter.meterType }}</span
                >
              </div>
              <p class="text-body-2">{{ meter.address }}</p>
            </div>
            <div class="self-center">
              <WcIconButton icon="wc-carbon:trash-can" size="small" @click.stop="removeMeter(meter.id)" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <WcButton :text="modelValue.length > 0 ? 'Add more meters' : 'Select meters'" variant="primary" size="medium" @click="handleSelectMeters" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModals } from "vue-promise-modals"
import type { Meter } from "@/client/types.gen"
import { WcButton, WcIconButton } from "@/components/button"
import { RegistrationType } from "@/models/asset"
import SelectMetersModal from "@/modules/meter/components/SelectMetersModal.vue"

const { openModal } = useModals()

const props = defineProps<{
  modelValue: Meter[]
  selectedRegistrationType: RegistrationType
}>()

const emit = defineEmits<{
  (e: "update:modelValue", value: Meter[]): void
}>()

const removeMeter = (meterId: number) => {
  const newMeters = props.modelValue.filter((meter: Meter) => meter.id !== meterId)
  emit("update:modelValue", newMeters)
}

const handleSelectMeters = async () => {
  let result
  try {
    result = await openModal(SelectMetersModal)

    const existingMeterIds = props.modelValue.map((meter) => meter.id)
    const newMeters = result.meters.filter((meter) => !existingMeterIds.includes(meter.id))
    const allMeters = [...props.modelValue, ...newMeters].sort((a, b) => a.id - b.id)
    emit("update:modelValue", allMeters)
  } catch {
    return
  }
}
</script>
