<template>
  <div class="relative">
    <Multiselect
      v-model="model"
      :input-id="id"
      :name="name"
      :option-group-label="hasGroups ? 'label' : undefined"
      :option-group-children="hasGroups ? 'items' : undefined"
      option-label="label"
      option-value="value"
      :options="options"
      :placeholder="allSelectedLabel"
      class="w-full"
      :class="inputClass"
      :scroll-height="scrollHeight"
      :show-clear="!required"
      :show-toggle-all="false">
      <template #value><slot name="value"></slot></template>
      <template #header>
        <div v-if="enableSelectAll" class="mb-1 mt-5 border-b border-neutral-20 px-5">
          <h4 class="text-subheading-1 p-dropdown-item">{{ header }}</h4>
          <div class="align-items-center mb-2 mt-4 flex">
            <Checkbox v-model="isAllSelected" input-id="allSelected" name="allSelected" :binary="true" @click="() => (model = [])" />
            <label for="allSelected" class="ml-2 cursor-pointer text-base"> {{ allSelectedLabel }} </label>
          </div>
        </div>
      </template>
    </Multiselect>
    <!-- Hidden input for form validation -->
    <input
      v-if="required"
      type="text"
      :required="required"
      class="pointer-events-none absolute left-0 opacity-0"
      :value="model?.length ? 'selected' : ''"
      tabindex="-1" />
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue"
import Checkbox from "primevue/checkbox"
import Multiselect from "primevue/multiselect"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"
import type { SelectOption, SelectOptionGroup } from "./WcDropdown"

const model = defineModel<T[] | null>()
const props = withDefaults(
  defineProps<{
    allSelectedLabel?: string
    enableSelectAll?: boolean
    header?: string
    id?: string
    name: string
    options: SelectOption<T>[] | SelectOptionGroup<T>[]
    required?: boolean
    size?: InputSize
    scrollHeight?: string
  }>(),
  {
    enableSelectAll: true,
  }
)

const inputClass = useInputClass(props)
const isAllSelected = computed(() => (model.value?.length ?? 0) === 0)
const hasGroups = computed(() => (props.options ?? []).some((option) => !!(option as SelectOptionGroup<T>).items))
</script>
