<template>
  <div class="overflow-x-scroll pb-3">
    <WcTable
      v-if="meters.length > 0"
      :data="meters"
      :columns="[
        { key: 'id', label: 'Meter ID', align: 'left', thClass: 'w-1/12' },
        { key: 'address', label: 'Address', align: 'left', thClass: 'w-4/12' },
        { key: 'meterType', label: 'Fuel Type', align: 'left', thClass: 'w-2/12' },
        { key: 'providerMeterId', label: 'Data Provider Meter ID', align: 'left', thClass: showDataProvider ? 'w-1/12' : 'w-2/12' },
        { key: 'providerCustomerId', label: 'Data Provider Customer ID', align: 'left', thClass: showDataProvider ? 'w-1/12' : 'w-2/12' },
        ...(showDataProvider ? [{ key: 'dataProvider', label: 'Data Provider', align: 'left', thClass: 'w-1/12' } as TableHeader] : []),
        { key: 'createdTime', label: 'Created', align: 'left', thClass: 'w-1/12' },
        { key: 'updatedTime', label: 'Updated', align: 'right', thClass: 'w-1/12' },
      ]"
      row-id-field="id"
      :table-class="'text-body-3 min-w-main-content'"
      tr-class="cursor-pointer hover:bg-neutral-10 transition-colors border-b border-neutral-20"
      th-class="text-left py-2"
      td-class="p-3"
      :sortable="false"
      :allow-multiple-selection="allowMultipleSelection"
      :select-on-row-click="selectOnRowClick"
      :selected-row-ids="selectedMeterIds"
      @rows-selected="$emit('rows-selected', $event)"
      @row-clicked="$emit('row-clicked', $event)">
      <template #address="{ row }">
        {{ row.address }}
      </template>
      <template #meterType="{ row }">
        <span class="capitalize">{{ row.meterType }}</span>
      </template>
      <template #dataProvider="{ row }">
        {{ partnerForDataProvider(row.apiProvider)?.name }}
      </template>
      <template #providerMeterId="{ row }">
        {{ parseProviderInfo(row)?.meterId }}
      </template>
      <template #providerCustomerId="{ row }">
        {{ parseProviderInfo(row)?.customerId }}
      </template>
      <template #createdTime="{ row }">
        {{ formatDate(row.createdTime) }}
      </template>
      <template #updatedTime="{ row }">
        {{ formatDate(row.updatedTime) }}
      </template>
    </WcTable>
    <EmptyState v-else title="No meters found">
      <slot name="empty-state">No meters are available.</slot>
    </EmptyState>
  </div>
</template>

<script setup lang="ts">
import type { Meter } from "@/client/types.gen"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import EmptyState from "@/components/ui/EmptyState.vue"
import { formatDate } from "@/utils/formatDate"
import { partnerForDataProvider } from "@/modules/dataProvider/dataProvider.service"

defineProps<{
  meters: Meter[]
  allowMultipleSelection?: boolean
  selectedMeterIds?: number[]
  selectOnRowClick?: boolean
  showDataProvider?: boolean
}>()

defineEmits<{
  "rows-selected": [selectedIds: number[]]
  "row-clicked": [meter: Meter]
}>()

interface BayouCustomInfo {
  bayou_customer_id: string
  bayou_meter_id: string
}

// Providers have different custom objects, so pick out and show relevant info where possible
const parseProviderInfo = (row: Meter) => {
  if (row.custom && typeof row.custom === "object") {
    if (row.apiProvider === "bayou") {
      const customInfo = row.custom as any as BayouCustomInfo
      return {
        meterId: customInfo.bayou_meter_id,
        customerId: customInfo.bayou_customer_id,
      }
    }
  }
  return {
    meterId: "—",
    customerId: "—",
  }
}
</script>
