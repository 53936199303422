<template>
  <form class="flex flex-col gap-2" @submit.prevent="upsertPortfolio(portfolio)">
    <label>
      ID
      <input v-model="portfolio.id" type="text" required pattern="[A-Z0-9_]+" placeholder="P12345 (all caps, alphanumeric)" />
    </label>

    <label>
      Name
      <input v-model="portfolio.name" type="text" required />
    </label>

    <label>
      Status
      <select v-model="portfolio.status" class="p-2" required>
        <option v-for="key in PortfolioStatusType" :key="key" :value="key">{{ key }}</option>
      </select>
    </label>

    <label>
      Units
      <select v-model="portfolio.units" class="p-2" required>
        <option value="wh_electricity">Wh of Electricity</option>
        <option value="g_co2e">Grams of CO2e</option>
      </select>
    </label>

    <label>
      Total EACs to transfer
      <input v-model="portfolio.quantityTotal" type="text" inputmode="numeric" pattern="\d*" required />
    </label>

    <p class="text-sm text-sagetone-main">Total EAC count should be the sum of all stakeholder expectations.</p>

    <WcButton text="Save" type="submit" icon="wc-carbon:save" class="w-fit" />
  </form>
</template>

<script setup lang="ts">
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { computed, onMounted, ref } from "vue"
import _ from "lodash"
import { Portfolio, PortfolioStatusType } from "@/models/order"
import { useProviderService } from "@/services/service-container"
import { Provider } from "@/models/models"
import { WcButton } from "@/components/button"
import { useRouter } from "vue-router"

const props = defineProps<{
  portfolioId?: string
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const providerService = useProviderService()
const router = useRouter()

const providers = ref<Array<Provider>>([])
onMounted(async () => {
  providers.value = await providerService.listProviders()
})

const portfolio = computed(() => {
  if (props.portfolioId !== undefined) {
    return _.cloneDeep(adminPortfoliosStore.getPortfolioById(props.portfolioId)!)
  } else {
    return {} as Portfolio
  }
})

const upsertPortfolio = async (portfolio: Portfolio) => {
  if (props.portfolioId === undefined) {
    const newPortfolio = await adminPortfoliosStore.createPortfolio(portfolio)
    router.push({ name: "wc-admin-portfolio", params: { portfolioId: newPortfolio.id } })
  } else {
    const newPortfolio = await adminPortfoliosStore.updatePortfolio(props.portfolioId, portfolio)
    if (props.portfolioId !== newPortfolio.id) {
      router.push({ name: "wc-admin-portfolio-config", params: { portfolioId: newPortfolio.id } })
    }
  }
}
</script>

<style scoped lang="scss">
input[type="text"],
input[type="date"],
textarea,
select {
  @apply invalid:border-error;
}
</style>
