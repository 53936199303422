import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import AppLayout from "@/components/layout/AppLayout.vue"
import ManageEACs from "@/modules/eacs/ManageEACs.vue"
import ManageEACsDetail from "@/modules/eacs/ManageEACsDetail.vue"
import ActivityLogView from "@/modules/accounts/ActivityLogView.vue"

export const eacRoutes: Array<RouteRecordRaw> = [
  {
    path: "/activity-log",
    name: "wc-activity-log",
    component: ActivityLogView,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/eacs",
    name: "wc-eacs",
    component: ManageEACs,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      breadcrumb: { name: "Dashboard", route: "wc-dashboard" },
    },
    redirect: { name: "wc-eacs-select-eacs" },
    beforeEnter: authorizedRoute,
    children: [
      {
        name: "wc-eacs-select-eacs",
        path: "./",
        component: ManageEACsDetail,
      },
    ],
  },
]
