<template>
  <div v-if="!isInitialLoad" class="overflow-hidden rounded-2xl border border-sagetone-main">
    <div class="text-overline-1 bg-white px-6 py-4">Recent Jobs</div>
    <div class="border-t border-sagetone-main bg-sagetone-lightbackground px-6 pb-6 pt-4">
      <div v-if="jobs.length === 0" class="text-body-2">None</div>
      <div v-else class="flex flex-col gap-y-4 lg:gap-y-6">
        <div v-for="job in jobs" :key="job.kind">
          <div class="text-overline-1 lg:mb-2">{{ formatPossibleSnakeCaseStringForDisplay(job.kind) }}</div>
          <div class="text-ia-writer-duospace-caption">
            <span class="capitalize">
              {{ job.status }}
            </span>
            <span v-if="job.timestamp" class="ml-2">({{ formatDateAndTime(job.timestamp) }})</span>
            <ToolTip
              v-if="job.status === 'failed'"
              class="ph-no-capture top-0.5 z-10 inline-block"
              panel-class="bg-white !-left-36 !-top-20 w-[256px]"
              button-class="!size-[11px] ml-1 !text-hint !border-hint">
              Something is either misconfigured or temporarily not working. Please
              <a class="underline" href="mailto:support@wattcarbon.com">contact support</a> to resolve the issue.
            </ToolTip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { jobsListJobs } from "@/client/sdk.gen"
import { type Job } from "@/client/types.gen"
import { formatDateAndTime } from "@/utils/formatDateAndTime"
import { formatPossibleSnakeCaseStringForDisplay } from "@/utils/formatPossibleSnakeCaseStringForDisplay"
import { usePolling } from "@/composables/usePolling"
import ToolTip from "@/components/ui/ToolTip.vue"

const JOB_KIND_PRIORITIES: Record<string, number> = {
  auditing: 1,
  minting: 3,
  allocating: 4,
}

const props = defineProps<{ assetId: number }>()

// Poll every 5 seconds for 5 minutes (60 * 5 seconds)
const { startPolling } = usePolling(async () => await fetchJobs(props.assetId), {
  frequency: 5_000,
  maxPollCount: 60,
  immediate: true,
})

const isInitialLoad = ref<boolean>(true)
const jobs = ref<Job[]>([])

const sortJobs = (jobs: Job[]): Job[] => {
  return [...jobs].sort((a, b) => {
    const priorityA = JOB_KIND_PRIORITIES[a.kind] ?? 2 // Default priority for unrecognized kinds
    const priorityB = JOB_KIND_PRIORITIES[b.kind] ?? 2
    return priorityA - priorityB
  })
}

const fetchJobs = async (assetId: number) => {
  try {
    const result = await jobsListJobs({ query: { deviceId: assetId } })
    jobs.value = sortJobs(result.data ?? [])
  } catch (error) {
    console.error("There was an error loading jobs for this asset", error)
  } finally {
    isInitialLoad.value = false
  }
}

onMounted(() => {
  startPolling()
})
</script>
