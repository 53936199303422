<template>
  <header class="bg-background-sagelight">
    <div v-if="showAccountContext" class="wc-page-container -mb-8 flex justify-end pt-3">
      <div class="text-body-3 text-sagetone" data-cy="logged-in-as-message">Logged in as {{ store.account.name }}</div>
    </div>
    <div class="wc-page-container w-full py-12">
      <nav v-if="hasBacklink" class="-mt-8 mb-3 flex justify-start">
        <WcCTA
          :to="backLinkTo"
          type="router-link"
          color="primary"
          size="small"
          icon="wc-carbon:arrow-left"
          :text="computedBacklinkText ? `Back to ${computedBacklinkText}` : 'Back'"
          icon-position="left" />
      </nav>
      <div v-if="pageTypeLabel" class="text-overline-1 pb-4">{{ pageTypeLabel }}</div>
      <div class="flex items-baseline justify-between gap-4 max-lg:flex-wrap">
        <h1 class="text-subheading-large-bold min-w-0 break-words">
          <slot></slot>
        </h1>
        <slot name="title-adjacent"></slot>
      </div>
      <div v-if="$slots.description" class="wc-homepage-grid mt-3">
        <p class="text-body-2 col-span-full max-w-[762px]">
          <slot name="description"></slot>
        </p>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useRoute } from "vue-router"
import { WcCTA } from "@/components/button"
import { useMainStore } from "@/store"

const store = useMainStore()
const route = useRoute()

const props = defineProps({
  pageTypeLabel: { type: String },
  backlinkRoute: { type: Object },
  backlinkText: { type: String },
  showAccountContext: { type: Boolean },
})

interface Breadcrumb {
  name: string
  route: string
}

const routeBreadcrumb = computed<Breadcrumb | undefined>(() => {
  return route.meta.breadcrumb as Breadcrumb | undefined
})

const hasBacklink = computed(() => {
  return !!(props.backlinkText || routeBreadcrumb.value?.name) && !!(props.backlinkRoute || routeBreadcrumb.value?.route)
})

const computedBacklinkText = computed(() => {
  return props.backlinkText || routeBreadcrumb.value?.name
})

const backLinkTo = computed(() => {
  if (props.backlinkRoute) {
    return props.backlinkRoute
  } else if (routeBreadcrumb.value?.route) {
    return { name: routeBreadcrumb.value.route }
  }
  return undefined
})
</script>
