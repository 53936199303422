import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

const dateFormatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
export const formatDate = (date: string) => {
  return dateFormatter.format(parseUTCTimestamp(date))
}

export const maybeFormatDate = (date: string | null | undefined) => {
  if (!date) {
    return undefined
  }
  return formatDate(date)
}
