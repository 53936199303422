<template>
  <div class="grid grid-cols-1 items-end gap-6 lg:grid-cols-2 xl:grid-cols-4">
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'replacementType') || EMPTY_FORM" :errors="fieldErrors" />
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'previousFuel') || EMPTY_FORM" :errors="fieldErrors" />
    <WcOpenApiFormFields
      v-model="formValue"
      :form="extractSingleField(formSchema, 'energyEfficiencyImprovements') || EMPTY_FORM"
      :errors="fieldErrors" />
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'commencedOperationDate') || EMPTY_FORM" :errors="fieldErrors" />
  </div>

  <div class="grid grid-cols-1 items-end gap-6 lg:grid-cols-2 xl:grid-cols-4">
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'buildingType') || EMPTY_FORM" :errors="fieldErrors" />
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'areaSqft') || EMPTY_FORM" :errors="fieldErrors" />
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'yearBuilt') || EMPTY_FORM" :errors="fieldErrors" />
    <WcOpenApiFormFields v-model="formValue" :form="extractSingleField(formSchema, 'numberOfStories') || EMPTY_FORM" :errors="fieldErrors" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import WcOpenApiFormFields from "@/components/form/WcOpenApiFormFields.vue"
import { extractSingleField, FormComponent } from "@/components/form/WcOpenApiForm.utils"
import type { ApiValidationError } from "@/components/form/WcOpenApiForm.utils"

const EMPTY_FORM = { type: "form", name: "", title: "", fields: [] } as FormComponent

const props = defineProps<{
  modelValue: Record<string, any>
  formSchema: any
  fieldErrors: ApiValidationError[]
}>()

const emit = defineEmits<{
  (e: "update:modelValue", value: Record<string, any>): void
}>()

const formValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value)
  },
})
</script>
