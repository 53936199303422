<template>
  <div class="mt-4 overflow-x-scroll pb-8">
    <table class="w-full">
      <thead class="border-b border-sagetone">
        <tr>
          <th class="text-left">Name and Location</th>
          <th class="pl-6 text-left">Status</th>
          <th class="pl-6 text-left">Audit</th>
          <th class="text-left">Last Minting</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-sagetone">
        <tr v-for="asset in assets" :key="asset.id">
          <td class="max-w-[40ch] truncate py-2 text-left">
            <div class="flex flex-col">
              <router-link :to="{ name: 'wc-admin-asset', params: { assetId: asset.id } }" class="underline">
                {{ asset.name || `Asset ${asset.id}` }}
              </router-link>
              <span class="text-sm capitalize text-sagetone">{{ asset.city.toLowerCase() }}, {{ asset.state.toUpperCase() }}</span>
            </div>
          </td>
          <td class="py-2 text-left align-top capitalize">
            <div class="flex items-center justify-start"><AssetStatusIcon :status="asset.status as AssetStatus" /> {{ asset.status }}</div>
          </td>
          <td class="py-2 text-left align-top capitalize">
            <div class="flex items-center justify-start">
              <AssetAuditStatusIcon :status="asset.auditStatus as AssetAuditStatus" /> {{ asset.auditStatus }}
            </div>
          </td>
          <td class="py-2 text-left align-top">
            <div class="flex flex-col">
              {{ maybeFormatDate(mostRecentEac(asset.summary.eacs.sum)) ?? "" }}
            </div>
          </td>
          <td class="flex justify-end py-2 pr-2 align-top">
            <button class="text-error disabled:cursor-not-allowed disabled:text-sagetone" @click="removeAsset(asset)">
              <Icon icon="wc-carbon:close-outline" />
              Remove
            </button>
          </td>
        </tr>
        <tr v-if="isLoading">
          <td colspan="5" class="py-2">
            <div class="flex items-center justify-start">
              <Icon icon="mdi:loading" class="size-6 animate-spin" />
            </div>
          </td>
        </tr>
        <tr v-else-if="assets.length == 0">
          <td colspan="5" class="py-2">No assets.</td>
        </tr>
      </tbody>
      <tfoot class="border-t border-sagetone">
        <tr>
          <td class="py-2">
            <button class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone" @click="handleAddAssets()">
              <Icon icon="wc-carbon:add" />
              Add Asset
            </button>
          </td>
          <td colspan="4" class="text-right">
            <PaginationButtons :page-info="pageInfo" @load-items="loadAssets" />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import type { PageInfo } from "@/services/base-fetcher"
import { maybeFormatDate } from "@/utils/formatDate"
import { Icon } from "@iconify/vue"
import { mostRecentEac } from "@/modules/registry/registry.utils"
import { BaseDeviceOutWithSummary, devicesBatchAlterDevicesAdmin, devicesListDevicesAdminPaginated } from "@/client"
import { useModals } from "vue-promise-modals"
import SelectPortfolioAssetsModal from "@/modules/asset/components/SelectPortfolioAssetsModal.vue"
import AssetStatusIcon from "@/modules/asset/components/AssetStatusIcon.vue"
import AssetAuditStatusIcon from "@/modules/asset/components/AssetAuditStatusIcon.vue"
import { useConfirm } from "@/components/confirm/ConfirmContext"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { AssetAuditStatus, AssetStatus } from "@/models/asset"
const props = defineProps<{ portfolioId?: string }>()

const { openModal } = useModals()
const { confirm } = useConfirm()
const isLoading = ref(false)
const assets = ref<BaseDeviceOutWithSummary[]>([])
const pageInfo = ref<PageInfo>()

const loadAssets = async (url?: string, page?: string | null) => {
  try {
    isLoading.value = true
    const filters = {
      portfolioId: props.portfolioId,
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
    }
    const result = await devicesListDevicesAdminPaginated({ query: { ...filters, page } })
    assets.value = result.data as BaseDeviceOutWithSummary[]
    pageInfo.value = result.pageInfo
  } catch (error) {
    console.error("There was an error loading assets", error)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  loadAssets()
})

const handleAddAssets = async () => {
  if (!props.portfolioId) {
    return
  }

  let result
  try {
    result = await openModal(SelectPortfolioAssetsModal, { portfolioId: props.portfolioId })
  } catch {
    return
  }

  await devicesBatchAlterDevicesAdmin({
    body: result.assetIds.map((id) => ({ op: "setPortfolio", deviceId: id, portfolio: { portfolioId: props.portfolioId! } })),
  })

  await loadAssets()
}

const removeAsset = async (asset: BaseDeviceOutWithSummary) => {
  const result = await confirm({
    title: `Remove Asset ${asset.id} from Portfolio`,
    message:
      "Are you sure you want to remove this asset from the portfolio? Existing allocations will remain but future EACs will not be transferred to stakeholders.",
    confirmText: "Remove Asset",
  })
  if (!result) {
    return
  }

  await devicesBatchAlterDevicesAdmin({ body: [{ op: "setPortfolio", deviceId: asset.id, portfolio: { portfolioId: null } }] })
  await loadAssets()
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}
</style>
