<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="wc-markdown" v-html="renderedSource" />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { createMarkdownRenderer } from "./WcMarkdownRenderer"

const md = createMarkdownRenderer()

const renderMarkdown = (source: string | undefined) => {
  if (!source) {
    return ""
  }
  return md.render(source)
}

const { source } = defineProps<{ source: string | undefined }>()
const renderedSource = computed(() => renderMarkdown(source))
</script>

<style lang="scss">
.wc-markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-black mb-2;
  }

  @apply text-[16px] leading-[24px] text-black;

  p {
    @apply text-[16px] leading-[24px] text-black mb-2;
  }

  // Include 16px spacing between p, ul, and ol elements
  :where(p, ul, ol, h1, h2, h3, h4, h5, h6, details) + :where(p, ul, ol, h1, h2, h3, h4, h5, h6, details) {
    @apply mt-4;
  }

  // Include 16px spacing between these elements when nested
  li :where(p + :where(p, ul, ol)) {
    @apply mt-4;
  }

  // Left-align LaTeX blocks instead of center-aligning them
  .katex-display {
    text-align: left;
    > .katex {
      text-align: left;
    }
  }

  a {
    text-decoration: underline;
    @apply text-black;
  }

  li {
    list-style-position: outside;
    @apply mt-2.5 pl-1;
  }

  ol,
  ul {
    @apply pl-5;
  }
  ul > li {
    list-style-type: disc;
    &::marker {
      @apply text-[18px];
    }
  }

  ol > li {
    list-style-type: auto;
  }
  ol[type="a"] > li {
    list-style-type: lower-alpha;
  }

  details {
    @apply mb-3;
  }

  details[open] {
    @apply mb-4 last:mb-0;

    & > summary::before {
      top: 2px;
      left: 4px;
      transform: rotate(180deg) scaleX(1.75);
    }

    & > summary {
      @apply mb-3;
    }

    & > :not(summary) {
      @apply ml-6;
    }
  }

  summary {
    @apply pl-6 cursor-pointer text-[14px] leading-[20px] text-black font-[400];
    position: relative;
    list-style: none;
  }

  summary::-webkit-details-marker,
  summary::marker {
    display: none;
  }

  summary::before {
    content: "∧";
    position: absolute;
    left: 6px;
    transform: rotate(90deg) scaleX(1.75);
    font-size: 9px;
    font-weight: 800;
  }

  details[open] > summary::before {
    top: 1px;
    left: 4px;
    transform: rotate(180deg) scaleX(1.75);
  }
}
</style>
