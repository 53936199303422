<template>
  <Icon v-if="isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />

  <template v-else>
    <table class="w-full">
      <thead class="border-b border-sagetone">
        <tr>
          <th class="text-left">Supplier</th>
          <th class="text-right">Quantity</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-sagetone">
        <tr v-for="supplier in suppliers" :key="supplier.accountId">
          <td class="max-w-[20ch] truncate py-2 text-left underline">
            <router-link :to="{ name: 'wc-admin-account-portfolios', params: { accountId: supplier.accountId } }">
              {{ supplier.name }}
            </router-link>
          </td>
          <td class="py-2 text-right">
            {{ supplier.quantity.toLocaleString() }}
          </td>
          <td class="flex justify-end py-2 pr-2">
            <button class="text-error disabled:cursor-not-allowed disabled:text-sagetone" @click="removeSupplier(supplier)">
              <Icon icon="wc-carbon:close-outline" />
              Remove
            </button>
          </td>
        </tr>
        <tr v-if="suppliers.length == 0">
          <td colspan="3" class="py-2">No suppliers.</td>
        </tr>
      </tbody>
      <tfoot class="border-t border-sagetone">
        <tr>
          <td class="py-2">
            <button class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone" @click="isAddSupplierDialogOpen = true">
              <Icon icon="wc-carbon:add" />
              Add Supplier
            </button>
          </td>
          <td class="text-right">
            {{ totalSupply.toLocaleString() }}
          </td>
          <td class="text-right">
            {{ portfolio.quantityTotal?.toLocaleString() }}
          </td>
        </tr>
      </tfoot>
    </table>
  </template>

  <WcModal :is-open="isAddSupplierDialogOpen" header="Attach Supplier" @update:is-open="(isOpen) => (isAddSupplierDialogOpen = isOpen)">
    <template #panel>
      <form ref="formRef" @submit.prevent="addSupplier">
        <label>
          Supplier
          <select name="supplier" class="p-2" required>
            <option></option>
            <option v-for="s in unattachedSuppliers" :key="s.id" :value="s.id">
              {{ s.name }}
            </option>
          </select>
        </label>

        <label class="mt-2">
          EACs Expected
          <input type="number" name="quantity" class="p-2" min="1" required />
        </label>
      </form>
    </template>
    <template #actions>
      <WcButton text="Cancel" variant="secondary" size="small" @click="isAddSupplierDialogOpen = false" />
      <WcButton text="Attach" size="small" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { computed, ref, onMounted } from "vue"
import { PortfolioSupplierRelationship } from "@/models/order"
import { Icon } from "@iconify/vue"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import _ from "lodash"

const props = defineProps<{
  portfolioId: string
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const adminAccountsStore = useAdminAccountsStore()

const formRef = ref<HTMLFormElement | null>(null)

const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

const isLoading = ref<boolean>(true)
const suppliers = ref<PortfolioSupplierRelationship[]>([])
onMounted(async () => {
  try {
    suppliers.value = await adminPortfoliosStore.listSuppliers(props.portfolioId)
  } finally {
    isLoading.value = false
  }
})

const totalSupply = computed(() => {
  return suppliers.value.reduce((acc, s) => acc + s.quantity, 0)
})

const isAddSupplierDialogOpen = ref<boolean>(false)
const unattachedSuppliers = computed(() => {
  const allSuppliers = adminAccountsStore.accounts.filter((a) => a.type === "organization")
  const unattached = _.differenceWith(allSuppliers, suppliers.value, (s1, s2) => s1.id == s2.accountId)
  return _.sortBy(unattached, ["name"])
})

const addSupplier = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  const supplier = adminAccountsStore.getAccountById(parseInt(formEl.supplier.value))!
  const quantity = parseInt(formEl.quantity.value)

  const newSupplier = await adminPortfoliosStore.addSupplier(portfolio.value, supplier, quantity)
  suppliers.value.push(newSupplier)

  isAddSupplierDialogOpen.value = false
  formEl.reset()
}

const removeSupplier = async (supplier: PortfolioSupplierRelationship) => {
  if (confirm(`Remove ${supplier.name} from this portfolio?`)) {
    await adminPortfoliosStore.removeSupplier(portfolio.value, supplier)

    const supplierIndex = suppliers.value.indexOf(supplier)
    if (supplierIndex !== -1) {
      suppliers.value.splice(supplierIndex, 1)
    }
  }
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}
</style>
