<template>
  <WcModal v-model:is-open="isOpen" size="full" header="Create Asset Group">
    <template #actions>
      <WcButton text="Cancel" size="medium" variant="secondary" dark-mode @click="closeModal" />
      <WcButton text="Create Group" size="medium" variant="primary" dark-mode @click="createAssetGroup" />
    </template>
    <div class="wc-page-container py-12">
      <h2 class="text-subheading-large-bold mb-16">New asset group</h2>
      <div class="sm:w-[424px]">
        <WcInputText v-model="assetGroupName" name="assetGroupName" inset-label="Asset Group Name" />
      </div>
      <div class="mt-12 w-full border-t border-divider-extralight pt-8">
        <div class="mt-4 overflow-x-scroll pb-3">
          <AssetsTable :assets="assetsToAdd" show-eacs show-kind show-updated table-class="w-full" />
        </div>
      </div>
    </div>
  </WcModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useToast } from "vue-toastification"
import { WcButton } from "@/components/button"
import { WcInputText } from "@/components/input"
import WcModal from "@/components/WcModal.vue"
import type { AssetWithSummary } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"
import AssetsTable from "./AssetsTable.vue"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits(["asset-group-added"])

const isOpen = ref<boolean>(false)
const assetsToAdd = ref<AssetWithSummary[]>([])
const assetGroupName = ref<string>("")

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const resetModal = () => {
  assetGroupName.value = ""
}

const createAssetGroup = async () => {
  const assetIdsToAdd = assetsToAdd.value.map((asset) => asset.id)
  try {
    const assetGroup = await assetService.createAssetGroup({ name: assetGroupName.value })
    await assetService.addAssetsToAssetGroup(assetGroup.id, assetIdsToAdd)
    emit("asset-group-added", assetGroup)
    toast.success(`Asset group ${assetGroup.name} created with ${assetIdsToAdd.length} assets`)
    return assetGroup
  } catch (error) {
    toast.error("Failed to create asset group. Please try again.")
    throw error
  }
}

const openModal = (assets: AssetWithSummary[]) => {
  assetsToAdd.value = assets
  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>
