<template>
  <WcModal
    :is-open="true"
    header="Data inputs"
    description="Select meters to connect to assets"
    size="drawer"
    @update:is-open="(value) => !value && handleClose()">
    <div class="flex flex-col">
      <InlineLoading v-if="isLoading" />
      <InlineError v-else-if="hasError" error-message="There was a problem loading meters. Please try again." />
      <div v-else>
        <WcInputText
          v-model="searchQuery"
          icon="wc-carbon:search"
          class="mb-4 max-w-[400px]"
          name="search-meters"
          inset-label="Search"
          size="small" />
        <MetersTable
          v-if="meters.length > 0"
          :meters="meters"
          allow-multiple-selection
          select-on-row-click
          :selected-meter-ids="selectedMeters.map((meter) => meter.id)"
          show-data-provider
          @rows-selected="handleMeterSelection" />
        <EmptyState v-else title="No meters found"> No meters are available. </EmptyState>
        <PaginationButtons class="mt-3" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadMeters" />
      </div>
    </div>

    <template v-if="selectedMeters.length > 0" #actions>
      <div class="flex w-full items-center justify-between gap-2">
        <div class="text-body-2 shrink-0">
          <div class="flex items-baseline gap-2">
            <span class="text-subheading-1">{{ selectedMeters.length }}</span>
            <span class="text-subheading-1">meters selected</span>
          </div>
        </div>
        <div class="flex items-center gap-2">
          <WcButton text="Add selected meters" variant="primary" size="medium" :is-disabled="selectedMeters.length === 0" @click="handleConfirm" />
        </div>
      </div>
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { metersListMetersPaginated } from "@/client/sdk.gen"
import type { Meter } from "@/client/types.gen"
import { WcButton } from "@/components/button"
import { WcInputText } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcModal from "@/components/WcModal.vue"
import type { PageInfo } from "@/services/base-fetcher"
import MetersTable from "./MetersTable.vue"
import { debounce } from "@/utils/debounce"

const emit = defineEmits<{
  (e: "modal-reject"): void
  (e: "modal-resolve", value: { meters: Meter[] }): void
}>()

const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)
const meters = ref<Meter[]>([])
const pageInfo = ref<PageInfo>()
const selectedMeters = ref<Meter[]>([])
const searchQuery = ref<string>("")

const loadMeters = async (url?: string, page?: string | null) => {
  hasError.value = false

  try {
    const result = await metersListMetersPaginated({
      query: {
        ...(searchQuery.value ? { q: searchQuery.value } : {}),
        per_page: 10,
        page,
      },
    })

    meters.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    hasError.value = true
    console.error("Error loading meters", error)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  loadMeters()
})

watch(
  [searchQuery],
  debounce(() => {
    loadMeters()
  })
)

const handleMeterSelection = (selectedIds: number[]) => {
  selectedMeters.value = meters.value.filter((meter) => selectedIds.includes(meter.id))
}

const handleConfirm = () => {
  emit("modal-resolve", { meters: selectedMeters.value })
}

const handleClose = () => {
  resetModal()
  emit("modal-reject")
}

const resetModal = () => {
  isLoading.value = true
  hasError.value = false
  meters.value = []
  pageInfo.value = undefined
  selectedMeters.value = []
  searchQuery.value = ""
}
</script>
