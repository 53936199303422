<template>
  <AppPage>
    <AppPageHeader>
      Data Providers
      <template #title-adjacent>
        <WcCTA text="Connect my data" weight="heavy" icon="wc-carbon:lightning" @click="addDataProviderModal.openModal()" />
      </template>
      <template #description> Connect to data providers and manage integrations </template>
    </AppPageHeader>
    <AppPageContent>
      <InlineLoading v-if="isLoading" />
      <InlineError v-else-if="hasError" error-message="There was a problem loading data providers. Please try again." />
      <div v-else-if="dataProviders?.length" class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div
          v-for="dataProvider in dataProviders"
          :key="dataProvider.id"
          class="flex cursor-pointer flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3 hover:border-blue-70"
          @click="navigateToDataProvider(dataProvider.id)">
          <div class="mb-5 flex items-baseline justify-between gap-2">
            <h3 class="text-subheading-1 min-w-0 break-words">{{ dataProvider.partner?.name || dataProvider.apiProvider }}</h3>
          </div>
          <div v-if="dataProvider" class="text-caption grid grow-0 grid-cols-2 gap-0 text-hint">
            <div>Created: {{ formatDate(dataProvider.createdTime) }}</div>
            <div>Last updated: {{ formatDate(dataProvider.updatedTime) }}</div>
          </div>
        </div>
      </div>
      <EmptyState v-else title="No data providers connected">
        <div class="mb-16">Click below to get started</div>
        <WcButton text="Connect My Data" @click="addDataProviderModal.openModal()" />
      </EmptyState>
    </AppPageContent>
    <AddDataProviderModal ref="addDataProviderModal" @import-complete="loadDataProviders" />
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { WcButton, WcCTA } from "@/components/button"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import type { DataProviderWithMappedPartner } from "@/modules/dataProvider/dataProvider.service"
import { useDataProviderService } from "@/services/service-container"
import { formatDate } from "@/utils/formatDate"
import AddDataProviderModal from "./components/AddDataProviderModal.vue"

const dataProviderService = useDataProviderService()
const router = useRouter()

const addDataProviderModal = ref()
const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)
const dataProviders = ref<DataProviderWithMappedPartner[]>([])

const loadDataProviders = async () => {
  isLoading.value = true
  try {
    dataProviders.value = await dataProviderService.listDataProviders()
  } catch (error) {
    hasError.value = true
    console.error("Error loading data providers", error)
  } finally {
    isLoading.value = false
  }
}

const navigateToDataProvider = (dataProviderId: number) => {
  router.push({ name: "wc-data-provider-detail", params: { dataProviderId } })
}

onMounted(() => {
  loadDataProviders()
})
</script>
