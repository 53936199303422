import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import AppLayout from "@/components/layout/AppLayout.vue"
import MetersView from "./MetersView.vue"

export const meterRoutes: Array<RouteRecordRaw> = [
  {
    path: "/meters",
    name: "wc-meters",
    component: MetersView,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Meters",
      breadcrumb: { name: "Dashboard", route: "wc-dashboard" },
    },
    beforeEnter: authorizedRoute,
  },
]
