<template>
  <div
    class="m-and-v-summary-section w-full"
    :class="{
      ...(props.section.style ? { [props.section.style]: true } : {}),
    }">
    <hr v-if="section.style === 'heading'" class="mt-6 border-t pt-8" />
    <div class="flex gap-12">
      <div class="w-1/2" :class="{ 'full-width-section': isFullWidthSection }">
        <div :class="{ 'sticky top-5': !isFullWidthSection && props.section.visualizations?.length }">
          <WcMarkdown :source="section.textContent" />
        </div>
      </div>
      <div v-if="!isFullWidthSection" class="mb-4 flex w-1/2 flex-col gap-6">
        <div
          v-for="(visualization, index) in section.visualizations"
          :key="index"
          class="rounded-lg border border-sagetone-light p-6"
          :class="{
            'bg-darktone-lightbackground': visualization?.kind === 'markdown',
          }">
          <WcMarkdown v-if="visualization?.kind === 'markdown'" :source="visualization.data.text" />
          <WcChart v-else :chart-options="hydrateVisualizationConfig(visualization.data)" :chart-id="`${section.name}-${index}`" height="500px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import WcMarkdown from "@/components/ui/WcMarkdown.vue"
import { MethodologyDescriptionSection } from "@/models/audit"
import WcChart from "./WcChart.vue"

const props = defineProps<{ section: MethodologyDescriptionSection }>()

const isFullWidthSection = computed(() => props.section.style === "page-heading")

// Inject client-side enhancements to the visualization config
const hydrateVisualizationConfig = (config: any) => {
  // Provide the itemNameFormatter method to make the boxplot transform work
  const boxplotTransform = (config.dataset ?? []).find((dataset: any) => dataset.transform?.type === "boxplot")
  if (boxplotTransform) {
    boxplotTransform.transform.config.itemNameFormatter = (params: any) => params.value
  }

  // Define a tooltip showing the different boxplot stats
  const hasBoxplotSeries = (config.series ?? []).some((series: any) => series.type === "boxplot")
  if (hasBoxplotSeries) {
    config.tooltip = {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params: any) {
        if (params.seriesType === "boxplot") {
          const data = params.data
          return [
            `<b>${params.name}</b>`,
            `Maximum: ${data[5].toFixed(2)} kWh`,
            `Upper Quartile (Q3): ${data[4].toFixed(2)} kWh`,
            `Median: ${data[3].toFixed(2)} kWh`,
            `Lower Quartile (Q1): ${data[2].toFixed(2)} kWh`,
            `Minimum: ${data[1].toFixed(2)} kWh`,
          ].join("<br/>")
        }
        return params.name
      },
    }
  }
  return config
}
</script>

<style lang="scss">
.m-and-v-summary-section {
  .wc-markdown {
    @apply text-[14px] leading-[20px] text-black;
    p {
      @apply text-[14px] leading-[20px] text-black;
    }
    a {
      @apply text-[14px];
    }
    h3 {
      @apply text-[24px] leading-[36px] font-semibold text-black;
    }
    h4 {
      @apply text-[16px] leading-[24px] font-semibold text-black;
    }
    h5 {
      @apply text-[14px] leading-[20px] font-semibold text-black;
    }
    h4 + h5 {
      @apply mt-6;
    }
    pre,
    code {
      @apply overflow-x-auto whitespace-pre-wrap break-words max-w-full;
    }
    summary {
      @apply font-[600];
    }
  }

  .full-width-section {
    @apply w-full;
    hr {
      @apply w-full;
    }
    p,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    details {
      @apply w-[762px];
    }
  }

  &.page-heading {
    p,
    a {
      @apply text-[16px];
    }
  }

  &.heading:has(h5) hr {
    @apply border-divider-extralight;
  }

  &.heading:has(h4) hr {
    @apply border-divider-light;
  }
}
</style>
