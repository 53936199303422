import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import AccountsManageView from "./AccountsManageView.vue"
import AccountDetailsView from "./AccountDetailsView.vue"
import AppLayout from "@/components/layout/AppLayout.vue"

export const accountsRoutes: Array<RouteRecordRaw> = [
  {
    path: "/accounts",
    name: "wc-accounts-dashboard",
    redirect: { name: "wc-dashboard" },
  },
  {
    path: "/accounts/orders",
    name: "wc-order-history",
    redirect: { name: "wc-dashboard" },
  },
  {
    path: "/accounts/portfolio/:portfolioId",
    name: "wc-accounts-portfolio-tracker",
    redirect: { name: "wc-portfolio-tracker" },
  },
  {
    path: "/accounts/manage",
    name: "wc-accounts-manage",
    component: AccountsManageView,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Manage Accounts",
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/accounts/:id",
    name: "wc-accounts-details",
    component: AccountDetailsView,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Account Details",
      breadcrumb: { name: "Manage Accounts", route: "wc-accounts-manage" },
    },
    beforeEnter: authorizedRoute,
  },
]
