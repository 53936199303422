<template>
  <Icon :icon="icon" :title="props.status" class="mr-2 size-4 min-w-4 stroke-[4px] drop-shadow" :color="color" :class="extraClasses" />
</template>

<script setup lang="ts">
import { JobStatus } from "@/client"
import colors from "@/colors"
import { Icon } from "@iconify/vue"
import { computed } from "vue"
const props = defineProps<{ status: JobStatus }>()

const color = computed(() => {
  switch (props.status) {
    case "queued":
      return colors.neutral[40]
    case "running":
      return colors.neutral[90]
    case "complete":
      return colors.success.DEFAULT
    case "failed":
      return colors.error.DEFAULT
    default:
      return "neutral-40"
  }
})

const icon = computed(() => {
  switch (props.status) {
    case "running":
      return "mdi:loading"
    default:
      return "wc-carbon:circle-solid"
  }
})

const extraClasses = computed(() => {
  switch (props.status) {
    case "running":
      return "animate-spin"
    default:
      return ""
  }
})
</script>
