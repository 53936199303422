<template>
  <AppPageSection>
    <AssetMetadata v-if="asset" :asset="asset" :excluded-fields="['status', 'statusDetail']" class="mt-12" />
    <AssetDocuments v-if="asset" :asset="asset" class="mt-12" />
    <WcButton v-if="allowUploadAssetTimeSeries" class="mt-12" text="Upload time series data" @click="handleUploadTimeSeriesData" />
  </AppPageSection>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router"
import type { Asset } from "@/models/asset"
import { WcButton } from "@/components/button"
import { AppPageSection } from "@/components/layout"
import AssetMetadata from "./components/AssetMetadata.vue"
import AssetDocuments from "./components/AssetDocuments.vue"
import { useAllowUploadAssetTimeSeries } from "@/features"

const allowUploadAssetTimeSeries = useAllowUploadAssetTimeSeries()
const router = useRouter()

const props = defineProps<{ asset: Asset }>()

const handleUploadTimeSeriesData = () => {
  router.push({ name: "wc-assets-asset-timeseries-upload", params: { assetId: props.asset.id } })
}
</script>
