export class EacRetirementItem {
  certificateId = ""
  quantity = 0
}

export class EacRetirementCreate {
  certificates: EacRetirementItem[] = []
}

export enum EacUnits {
  wh_electricity_consumed = "wh_electricity_consumed",
  wh_electricity_supplied = "wh_electricity_supplied",
  g_co2e_emitted = "g_co2e_emitted",
  g_co2e_avoided = "g_co2e_avoided",
}

export const ELECTRICITY_UNITS = ["wh_electricity_consumed", "wh_electricity_supplied"]
export const CARBON_UNITS = ["g_co2e_emitted", "g_co2e_avoided"]
export const POSITIVE_UNITS = ["wh_electricity_supplied", "g_co2e_avoided"]
export const NEGATIVE_UNITS = ["wh_electricity_consumed", "g_co2e_emitted"]

export interface AccountBalanceSummary {
  active: Partial<Record<EacUnits, number>>
  retirement: Partial<Record<EacUnits, number>>
}
