import WcConfirm from "@/components/confirm/WcConfirm.vue"
import { useModals } from "vue-promise-modals"

export interface ConfirmOptions {
  title?: string
  message?: string
  confirmText?: string
  cancelText?: string
  icon?: string
}

// Components call this to get access to the confirm function
export function useConfirm() {
  const { openModal } = useModals()
  return {
    confirm: async (options?: ConfirmOptions) => {
      try {
        await openModal(WcConfirm, {
          options: {
            title: "Confirm",
            message: "Are you sure you want to proceed?",
            confirmText: "Confirm",
            cancelText: "Cancel",
            icon: "wc-ic:dangerous-outlined",
            ...options,
          },
        })
        return true
      } catch {
        return false
      }
    },
  }
}
