<template>
  <div class="m-and-v-summary-metadata rounded-xl bg-darktone-dark text-darktone-lightbackground shadow-lg">
    <div class="grid items-start gap-x-2 gap-y-4 px-6 py-8">
      <div class="col-span-2 grid grid-cols-[24px_1fr] gap-x-2">
        <Icon icon="wc-ic:electric-meter" class="-mt-px size-5 text-neutral-40" />
        <div class="grid gap-2">
          <div class="text-overline-1">Resource type</div>
          <template v-if="resourceType?.line1 && resourceType?.line2">
            <div>{{ resourceType?.line1 }}</div>
            <div class="text-body-3">({{ resourceType?.line2 }})</div>
          </template>
          <div v-else-if="resourceType?.line1">{{ resourceType?.line1 }}</div>
          <div v-else>—</div>
        </div>
      </div>

      <div class="col-span-3 grid grid-cols-[24px_1fr] gap-x-2">
        <Icon icon="wc-ic:verified" class="-mt-px size-5 text-neutral-40" />
        <div class="grid gap-2">
          <div class="text-overline-1">EAC IDs (start/end)</div>
          <div v-if="eacIdRange">{{ eacIdRange.lower }} / {{ eacIdRange.upper ?? eacIdRange.lower }}</div>
          <div v-else>—</div>
        </div>
      </div>

      <div class="col-span-2 grid grid-cols-[24px_1fr] gap-x-2">
        <Icon icon="wc-ic:preliminary" class="-mt-px size-5 text-neutral-40" />
        <div class="grid gap-2">
          <div class="text-overline-1">EAC count</div>
          <div>{{ eacCount }}</div>
        </div>
      </div>

      <div class="col-span-3 grid grid-cols-[24px_1fr] gap-x-2">
        <Icon icon="wc-ic:gas-meter" class="-mt-px size-6 text-neutral-40" />
        <div class="grid gap-2">
          <div class="text-overline-1">Utility</div>
          <div>{{ asset?.utility || "—" }}</div>
        </div>
      </div>

      <div class="col-span-5 my-2 border-b border-sagetone-main" />

      <div class="col-span-5 grid grid-cols-[24px_1fr] gap-x-2">
        <Icon icon="wc-ic:function" class="-mt-px size-5 text-neutral-40" />
        <div class="grid gap-2">
          <div class="text-overline-1">Methodology description</div>
          <div>
            <div v-if="story?.methodologyDescription" class="mb-3">
              <WcMarkdown :source="story.methodologyDescription" />
            </div>
            <div v-if="story?.measurementAndVerificationPlanUrl">
              <a :href="story?.measurementAndVerificationPlanUrl" target="_blank" rel="noopener" class="text-body-2 text-darktone-lightbackground">
                M&V plan
                <Icon icon="wc-carbon:arrow-right" class="inline-block text-xl" />
              </a>
            </div>
            <div v-if="!story?.methodologyDescription && !story?.measurementAndVerificationPlanUrl">—</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { Icon } from "@iconify/vue"
import { certificatesGetCertificateSummaryByTransactionId } from "@/client/sdk.gen"
import { CertificateBalanceSummary, StringRange } from "@/client/types.gen"
import WcMarkdown from "@/components/ui/WcMarkdown.vue"
import type { Asset, PublicAsset } from "@/models/asset"
import { ASSET_CATEGORY, ASSET_KIND } from "@/models/asset"
import type { Story } from "@/models/story"
import { useStoryService } from "@/services/service-container"

const storyService = useStoryService()

const props = defineProps<{ asset: PublicAsset | Asset | null; transactionId: string | null; eacCount: string }>()

const story = ref<Story | undefined>()
const eacIdRange = ref<StringRange>()

const resourceType = computed<{ line1?: string; line2?: string }>(() => {
  if (props.asset?.kind) {
    const kindStr = ASSET_KIND[props.asset.kind]
    if (/\(.*\)/.test(kindStr)) {
      const [kind, category] = kindStr.split(/\s*\((.*)\)/)
      return { line1: kind, line2: category }
    } else {
      return { line1: kindStr }
    }
  }
  if (props.asset && "category" in props.asset && props.asset.category) {
    const category = ASSET_CATEGORY[props.asset.category]
    return { line1: category }
  }
  return {}
})

watch(
  () => props.asset,
  async (newAsset) => {
    if (newAsset) {
      story.value = undefined

      try {
        if (newAsset?.storyId) {
          story.value = await storyService.getStory(newAsset.storyId)
        }
      } catch (error) {
        console.error("There was an error loading the story for M&V metadata", error)
      }
    }
  },
  { immediate: true }
)

watch(
  () => props.transactionId,
  async (newTransactionId) => {
    if (newTransactionId) {
      eacIdRange.value = undefined

      try {
        const result = await certificatesGetCertificateSummaryByTransactionId({
          query: { transactionId: newTransactionId },
        })
        const transactionSummary = result.data as CertificateBalanceSummary
        eacIdRange.value = transactionSummary.balances[0].idRange
      } catch (error) {
        console.error("There was an error loading transaction details for M&V metadata", error)
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.m-and-v-summary-metadata {
  .wc-markdown {
    a {
      @apply text-darktone-lightbackground;
    }
  }
}
</style>
