<template>
  <section class="flex justify-between gap-4">
    <div class="sticky top-4 flex h-[calc(100vh-8rem)] w-1/4 flex-col rounded-md bg-white shadow-md">
      <div class="flex h-full flex-col">
        <div class="h-full grow overflow-y-scroll pt-2">
          <Icon v-if="isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
          <div class="grow" data-cy="portfolios-list">
            <p v-if="pricingSchemes.length === 0" class="px-4 py-2 text-sagetone">No pricing schemes</p>
            <router-link
              v-for="ps in pricingSchemes"
              :key="ps.id"
              :to="{ name: 'wc-admin-pricing-scheme', params: { pricingSchemeId: ps.id } }"
              class="flex w-full cursor-pointer justify-between px-4 py-2 text-left text-sm font-medium hover:bg-neutral-20 focus:outline-none focus-visible:ring focus-visible:ring-blue-40/75">
              <div class="flex w-full flex-col justify-between">
                <div class="flex w-full justify-between">
                  <span class="inline-block overflow-x-hidden text-ellipsis pr-2">{{ ps.name }}</span>
                  <span class="inline-block">{{ ps.id }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col">
      <router-view v-if="!isLoading"></router-view>
      <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue"
import { Icon } from "@iconify/vue"
import { pricingSchemesListPricingSchemes } from "@/client/sdk.gen"
import { PricingSchemesGetPricingSchemeResponses } from "@/client/types.gen"

type PricingSchemeOut = PricingSchemesGetPricingSchemeResponses[200]

const isLoading = ref(true)
const pricingSchemes = ref<PricingSchemeOut[]>([])

onMounted(async () => {
  try {
    isLoading.value = true
    const response = await pricingSchemesListPricingSchemes()
    pricingSchemes.value = response.data
  } finally {
    isLoading.value = false
  }
})
</script>

<style scoped lang="scss">
.router-link-active {
  @apply bg-neutral-30;
}
</style>
