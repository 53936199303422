<template>
  <div class="flex">
    <template v-if="!!$slots.opener">
      <slot name="opener" :toggle="toggle" :is-open="visible" />
    </template>
    <template v-else>
      <WcIconButton :class="openerClass" :size="openerSize" icon="wc-carbon:overflow-menu-vertical" @click.prevent="toggle" />
    </template>
    <Menu ref="menu" v-model:visible="visible" :model="menuItems" v-bind="$attrs" popup>
      <template #item="{ item }">
        <div class="flex cursor-pointer items-center gap-3 px-3 py-2" :class="item.class">
          <Icon v-if="item.icon" class="size-4" :icon="item.icon" />
          <div v-else class="size-4" />
          <span class="text-body-2">{{ item.label }}</span>
        </div>
      </template>
    </Menu>
    <div style="display: none">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"
import Menu from "primevue/menu"
import { ref } from "vue"
import type { MenuItem } from "primevue/menuitem"
import type { ButtonSize } from "@/components/button"
import { WcIconButton } from "@/components/button"
import { provideMenuContext } from "./useMenuContext"

defineSlots<{
  default: () => true
  opener: (props: { toggle: (event: Event) => void; isOpen: boolean }) => true
}>()

withDefaults(defineProps<{ openerClass?: string; openerSize?: ButtonSize }>(), {
  openerSize: "large",
})

const visible = ref(false)
const menu = ref()
const menuItems = ref<MenuItem[]>([])

const toggle = (event: Event) => {
  menu.value?.toggle(event)
}

provideMenuContext(menuItems)
</script>

<style scoped lang="scss">
.wc-menu-item--alert {
  color: theme("colors.error.main");
}
.wc-menu-item--sage {
  color: theme("colors.sagetone.dark");
}
.wc-menu-item--green {
  color: theme("colors.darktone.main");
}
</style>
