<template>
  <div>
    <h4 class="text-subheading-1 my-4">Documents</h4>
    <InlineLoading v-if="isLoading" />
    <div v-else-if="!isLoading && documents.length > 0">
      <div v-for="document in documents" :key="document.id" class="my-2">
        <a class="text-body-1-link inline max-w-48 cursor-pointer" @click="() => handleRowClicked(document)">{{ document.name }}</a>
        ({{ ASSET_DOCUMENT_TYPE[document.type] ?? document.type }})
      </div>
    </div>
    <div v-else>None</div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import { ASSET_DOCUMENT_TYPE, Asset, AssetDocument } from "@/models/asset"
import { useAssetService } from "@/services/service-container"

const assetService = useAssetService()

const props = defineProps<{ asset: Asset }>()

const documents = ref<AssetDocument[]>([])
const isLoading = ref<boolean>(true)

const handleRowClicked = (document: AssetDocument) => {
  window.open(document.downloadUrl, "_blank")
}

watch(
  () => props.asset,
  async (asset) => {
    if (!asset) return
    isLoading.value = true
    try {
      documents.value = await assetService.listAssetDocuments(asset.id)
    } catch (error) {
      console.error("There was an error loading this asset", error)
    }
    isLoading.value = false
  },
  { immediate: true }
)
</script>
