import { CARBON_UNITS, EacUnits, ELECTRICITY_UNITS, NEGATIVE_UNITS, POSITIVE_UNITS } from "@/services/api/certificate.model"
import { EacMetric } from "@/client/types.gen"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"
import _ from "lodash"

/*
These methods differentiate between net and total EACs

Net: Subtract negative values (consumed, emitted) from positive values (supplied, avoided)

Total: Sum of EACs, regardless of whether those represent negative values (consumed, emitted) or negative values (supplied, avoided)
*/

export const netWhFromValues = (values: EacMetric[]) => {
  const supplied = values.find((v) => v.units === EacUnits.wh_electricity_supplied)?.amount || 0
  const consumed = values.find((v) => v.units === EacUnits.wh_electricity_consumed)?.amount || 0
  return supplied - consumed
}

export const netGco2eFromValues = (values: EacMetric[]) => {
  const emitted = values.find((v) => v.units === EacUnits.g_co2e_emitted)?.amount || 0
  const avoided = values.find((v) => v.units === EacUnits.g_co2e_avoided)?.amount || 0
  return avoided - emitted
}

export const netEacsFromValues = (values: EacMetric[]) => {
  const positiveEacs = values.filter((entry) => POSITIVE_UNITS.includes(entry.units)).reduce((acc, entry) => acc + entry.amount, 0)
  const negativeEacs = values.filter((entry) => NEGATIVE_UNITS.includes(entry.units)).reduce((acc, entry) => acc + entry.amount, 0)
  return positiveEacs - negativeEacs
}

export const totalWhEacsFromValues = (values: EacMetric[]) => {
  return values.filter((entry) => ELECTRICITY_UNITS.includes(entry.units)).reduce((acc, entry) => acc + entry.amount, 0)
}

export const totalGco2eEacsFromValues = (values: EacMetric[]) => {
  return values.filter((entry) => CARBON_UNITS.includes(entry.units)).reduce((acc, entry) => acc + entry.amount, 0)
}

export const totalEacsFromValues = (values: EacMetric[]) => {
  return totalWhEacsFromValues(values) + totalGco2eEacsFromValues(values)
}

export const mostRecentEac = (values: EacMetric[]) => {
  return _.maxBy(values, (e) => (e.upper ? parseUTCTimestamp(e.upper) : new Date(0)))?.upper
}
