import AccountsService from "@/modules/accounts/accounts.service"
import QueryService from "@/services/api/query.service"
import AuthService from "@/services/auth/auth.service"
import ApiFetcher from "@/services/api-fetcher"
import SiteService from "@/modules/sites/site.service"
import ProviderService from "@/services/api/provider.service"
import PortfolioService from "@/modules/portfolio/portfolio.service"
import StoryService from "@/services/api/story.service"
import CertificateService from "@/services/api/certificate.service"
import AssetService from "@/modules/asset/asset.service"
import TransactionService from "@/services/api/transaction.service"
import DataProviderService from "@/modules/dataProvider/dataProvider.service"

let apiFetcher: ApiFetcher
let queryService: QueryService
let siteService: SiteService
let accountsService: AccountsService
let authService: AuthService
let providerService: ProviderService
let portfolioService: PortfolioService
let storyService: StoryService
let certificateService: CertificateService
let assetService: AssetService
let transactionService: TransactionService
let dataProviderService: DataProviderService

export function useServiceContainerProvider() {
  apiFetcher = new ApiFetcher()
  queryService = new QueryService(apiFetcher)
  siteService = new SiteService(apiFetcher)
  accountsService = new AccountsService(apiFetcher)
  authService = new AuthService(apiFetcher)
  providerService = new ProviderService(apiFetcher)
  portfolioService = new PortfolioService(apiFetcher)
  storyService = new StoryService(apiFetcher)
  certificateService = new CertificateService(apiFetcher)
  assetService = new AssetService(apiFetcher)
  transactionService = new TransactionService(apiFetcher)
  dataProviderService = new DataProviderService(apiFetcher)
}

export function useApiFetcher(): ApiFetcher {
  return apiFetcher
}

export function useAuthService(): AuthService {
  return authService
}

export function useQueryService(): QueryService {
  return queryService
}

export function useAccountsService(): AccountsService {
  return accountsService
}

export function useProviderService(): ProviderService {
  return providerService
}

export function useSiteService(): SiteService {
  return siteService
}

export function usePortfolioService(): PortfolioService {
  return portfolioService
}

export function useStoryService(): StoryService {
  return storyService
}

export function useCertificateService(): CertificateService {
  return certificateService
}

export function useAssetService(): AssetService {
  return assetService
}

export function useTransactionService(): TransactionService {
  return transactionService
}

export function useDataProviderService(): DataProviderService {
  return dataProviderService
}
