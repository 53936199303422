<template>
  <WcModal v-model:is-open="isOpen" size="full" header="Add assets to group">
    <template #actions>
      <WcButton text="Cancel" size="medium" variant="secondary" dark-mode @click="closeModal" />
      <WcButton text="Add to Group" size="medium" variant="primary" dark-mode @click="addAssetsToGroup" />
    </template>
    <div class="wc-page-container py-12">
      <h2 class="text-subheading-large-bold mb-4">Asset groups</h2>
      <p class="text-body-2 mb-12">Select the group you want to add assets to.</p>
      <div class="mb-8 flex grow flex-wrap items-center justify-between gap-3">
        <div class="flex flex-wrap gap-3">
          <WcButton
            v-for="(label, key) in ASSET_GROUP_SORT_BY"
            :key="key"
            :variant="key === sort.key ? 'primary' : 'secondary'"
            color="sage"
            :text="label"
            size="small"
            :icon="iconForSortButton(key)"
            icon-position="right"
            @click="handleToggleSortGroups(key)" />
        </div>
      </div>
      <AssetGroupsSection v-if="!isLoading && !hasError" v-model="selectedAssetGroup" :asset-groups="assetGroups" is-selection-mode />
      <PaginationButtons class="mt-5" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssetGroups" />
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasError" error-message="There was a problem loading asset groups. Please try again." />
    </div>
  </WcModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useToast } from "vue-toastification"
import type { Asset } from "@/models/asset"
import { WcButton } from "@/components/button"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcModal from "@/components/WcModal.vue"
import type { AssetGroupWithSummary } from "@/modules/asset/asset.service"
import { AssetGroupOrderBy, AssetGroupSortBy, ASSET_GROUP_SORT_BY } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"
import type { PageInfo } from "@/services/base-fetcher"
import AssetGroupsSection from "./AssetGroupsSection.vue"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits(["asset-group-updated"])

const isOpen = ref<boolean>(false)
const assetsToAdd = ref<Asset[]>([])
const assetGroups = ref<AssetGroupWithSummary[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const pageInfo = ref<PageInfo>()
const sort = ref<{ key: AssetGroupSortBy; direction: AssetGroupOrderBy }>({
  key: AssetGroupSortBy.group_name,
  direction: AssetGroupOrderBy.asc,
})
const selectedAssetGroup = ref<AssetGroupWithSummary | null>(null)

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const resetModal = () => {
  selectedAssetGroup.value = null
}

const loadAssetGroups = async (url?: string) => {
  try {
    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
    }
    const result = await assetService.listAssetGroups({
      per_page: 12,
      url,
      orderBy: sort.value.direction,
      sortBy: sort.value.key,
      ...filters,
    })
    assetGroups.value = result.data
    pageInfo.value = result.pageInfo
    hasError.value = false
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading asset groups", error)
  }
  isLoading.value = false
}

watch([isOpen, sort], () => {
  if (isOpen.value) {
    loadAssetGroups()
  }
})

const iconForSortButton = (key: AssetGroupSortBy) => {
  if (sort.value.key === key) {
    return sort.value.direction === AssetGroupOrderBy.asc ? "wc-carbon:arrow-up" : "wc-carbon:arrow-down"
  }
  return undefined
}

const handleToggleSortGroups = (key: AssetGroupSortBy) => {
  if (sort.value.key === key) {
    sort.value.direction = sort.value.direction === AssetGroupOrderBy.asc ? AssetGroupOrderBy.desc : AssetGroupOrderBy.asc
  } else {
    sort.value.key = key
    sort.value.direction = AssetGroupOrderBy.asc
  }
  loadAssetGroups()
}

const addAssetsToGroup = async () => {
  if (selectedAssetGroup.value === null) {
    return
  }
  const assetIdsToAdd = assetsToAdd.value.map((asset) => asset.id)
  try {
    await assetService.addAssetsToAssetGroup(selectedAssetGroup.value.id, assetIdsToAdd)
    emit("asset-group-updated")
    toast.success(`${assetIdsToAdd.length} assets were added to ${selectedAssetGroup.value.name}`)
    closeModal()
  } catch (error) {
    toast.error("Failed to add assets to the asset group. Please try again.")
    throw error
  }
}

const openModal = (assets: Asset[]) => {
  assetsToAdd.value = assets
  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>
