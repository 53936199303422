<template>
  <Icon :icon="icon" class="mr-2 size-4 min-w-4 stroke-[4px] drop-shadow" />
</template>

<script setup lang="ts">
import { StringyJobKind } from "@/client/types.gen"
import { Icon } from "@iconify/vue"
import { computed } from "vue"
const props = defineProps<{ kind: StringyJobKind }>()

const icon = computed(() => {
  switch (props.kind) {
    case "auditing":
      return "wc-carbon:magnify"
    case "minting":
      return "wc-carbon:stamp"
    case "allocating":
      return "wc-carbon:choose-item"
    case "initial_meters_pull":
    case "update_meters_pull":
      return "wc-carbon:cloud-download"
    case "fetch_bills":
    case "fetch_intervals":
    case "fetch_all_meter_bills":
      return "wc-carbon:document-download"
    default:
      return "mdi:circle"
  }
})
</script>
