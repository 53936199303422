import ApiFetcher from "@/services/api-fetcher"
import arcadiaLogo from "@/static/img/dataProviders/arcadia-logo.png"
import bayouLogo from "@/static/img/dataProviders/bayou-logo.png"
import utilityApiLogo from "@/static/img/dataProviders/utilityapi-logo.png"

export enum ApiDataProvider {
  arcadia = "arcadia",
  bayou = "bayou",
  derapi_enphase = "derapi_enphase",
  leap = "leap",
  utility_api = "utility_api",
}

export interface DataProvider {
  id: number
  account_id: number
  apiProvider: ApiDataProvider

  createdTime: string
  updatedTime: string
  createdById: string
}

export interface Partner {
  name: string
  logo: string | null
  enabled: boolean
  apiProvider: ApiDataProvider
}

export const PARTNERS = [
  { name: "Bayou Energy", logo: bayouLogo, enabled: true, apiProvider: "bayou" },
  { name: "UtilityAPI", logo: utilityApiLogo, enabled: true, apiProvider: "utility_api" },
  { name: "Arcadia", logo: arcadiaLogo, enabled: true, apiProvider: "arcadia" },
] as any as Partner[]

export interface ContactInfo {
  name?: string | null
  email?: string | null
  phone?: string | null
  url?: string | null
  street1?: string | null
  street2?: string | null
  city?: string | null
  stateProvince?: string | null
  postalCode?: string | null
  country?: string | null
}

export interface DataProviderWithMappedPartner extends DataProvider {
  partner: Partner | null
}

export interface ClientKeyOnlyCredentials {
  api_key: string
}

export interface DataProviderClientCreate {
  api_provider: ApiDataProvider
  credentials: ClientKeyOnlyCredentials
}

export const partnerForDataProvider = (apiProvider: ApiDataProvider): Partner | null => {
  return PARTNERS.find((partner) => partner.apiProvider === apiProvider) || null
}

const addPartnerToDataProvider = (dataProvider: DataProvider): DataProviderWithMappedPartner => {
  return {
    ...dataProvider,
    partner: partnerForDataProvider(dataProvider.apiProvider),
  }
}

export default class DataProviderService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  listDataProviders = async () => {
    const dataProviders = await this.fetcher.httpGet<DataProvider[]>("/data-providers", {})
    return dataProviders.map((dataProvider) => addPartnerToDataProvider(dataProvider))
  }

  createDataProvider = async (provider: DataProviderClientCreate) => {
    const dataProvider = await this.fetcher.httpPost<DataProvider>("/data-providers", provider)
    return addPartnerToDataProvider(dataProvider)
  }
}
