export const formatPossibleSnakeCaseStringForDisplay = (value: string): string => {
  // If the value already contains spaces, return it as-is
  if (value.includes(" ")) {
    return value
  }

  return value
    .split("_")
    .filter((word) => word.length > 0)
    .map((word) => {
      if (word === word.toUpperCase()) return word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(" ")
}
