import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteLocation, RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import AppLayout from "@/components/layout/AppLayout.vue"
import DataProvidersView from "./DataProvidersView.vue"
import DataProviderView from "./DataProviderView.vue"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"

const dataProviderIdProp = (route: RouteLocation) => ({ dataProviderId: parseInt(scalarFromVectorOrScalar(route.params.dataProviderId)) })

export const dataProviderRoutes: Array<RouteRecordRaw> = [
  {
    path: "/data-providers",
    name: "wc-data-providers",
    component: DataProvidersView,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Data Providers",
      breadcrumb: { name: "Dashboard", route: "wc-dashboard" },
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/data-providers/:dataProviderId",
    name: "wc-data-provider-detail",
    component: DataProviderView,
    props: dataProviderIdProp,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Data Provider Detail",
      breadcrumb: { name: "Data Providers", route: "wc-data-providers" },
    },
    beforeEnter: authorizedRoute,
  },
]
