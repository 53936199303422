import type { Methodology } from "@/modules/asset/asset.service"

export interface MethodologyWithDescriptionAndStatus extends Methodology {
  active: boolean
  description?: string
}

export const methodologies: MethodologyWithDescriptionAndStatus[] = [
  {
    kind: "electrification_automatic",
    name: "Electrification",
    active: true,
    description:
      "Choose this option for assets that replace fossil fuel systems with electric alternatives. This methodology measures emissions reductions and efficiency gains from electrification projects.",
    url: "https://methods.openeac.org/methodologies/device-level-heating-household-electrification/2025-02-07",
  },
  {
    kind: "energy_efficiency_eemetered",
    name: "Energy Efficiency",
    active: true,
    description:
      "Select this for assets designed to reduce overall energy consumption. This methodology calculates savings based on whole-building energy savings.",
    url: null,
  },
  {
    kind: "demand_response",
    name: "Demand Response",
    active: true,
    description:
      "Select this methodology if your asset participates in utility demand response programs. This tracks how your asset reduces consumption during the two most carbon intensive hours of the day and quantifies the resulting benefits.",
    url: "https://bpmcm.caiso.com/BPM%20Document%20Library/Demand%20Response/BPM_for_Demand_Response_V5_clean.pdf#page=35",
  },
  {
    kind: "solar",
    name: "Solar",
    active: true,
    description:
      "Choose this methodology for solar power generation assets. This tracks energy production, calculates avoided emissions, and monitors performance against expected yield.",
    url: "https://github.com/wattcarbon/open-eac-alliance/pull/2",
  },
  {
    kind: "storage",
    name: "Storage",
    active: false,
    description:
      "Select this for battery or thermal storage systems. This methodology analyzes charging/discharging patterns, peak shaving, and overall system efficiency.",
    url: null,
  },
  {
    kind: "vehicle_charging",
    name: "Vehicle Charging",
    active: false,
    description:
      "Choose this for EV charging infrastructure. This methodology tracks usage patterns, energy delivered, and can calculate emissions reduction compared to conventional vehicles.",
    url: null,
  },
]
