<template>
  <AppPage>
    <AppPageHeader>
      <template v-if="dataProvider"> {{ dataProvider.partner?.name || dataProvider.apiProvider }} Details </template>
      <template v-else> Data Provider Details </template>
      <template #description>
        <div v-if="dataProvider">View meters and data for this provider</div>
      </template>
    </AppPageHeader>
    <AppPageContent>
      <InlineError v-if="hasError" error-message="There was a problem loading data provider details. Please try again." />
      <AppPageSection v-else-if="dataProvider">
        <div v-if="meters.length > 0 || searchQuery || isLoadingMeters" class="flex flex-wrap items-end justify-between gap-2">
          <div class="flex grow flex-wrap items-center gap-3">
            <WcInputText
              v-model="searchQuery"
              icon="wc-carbon:search"
              class="shrink-0 basis-48"
              name="search-meters"
              inset-label="Search"
              size="small" />
          </div>
        </div>

        <InlineLoading v-if="isLoadingMeters" />
        <InlineError v-else-if="hasMetersError" error-message="There was a problem loading meters. Please try again." />
        <MetersTable v-else-if="meters.length > 0" class="mt-4" :meters="meters" @row-clicked="handleMeterRowClick" />
        <EmptyState v-else-if="!searchQuery" title="No meters found"> No meters are available for this data provider </EmptyState>
        <PaginationButtons class="mt-3" :page-info="pageInfo" :is-disabled="isLoadingMeters" @load-items="loadMeters" />
      </AppPageSection>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { AppPage, AppPageContent, AppPageHeader, AppPageSection } from "@/components/layout"
import { WcInputText } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import type { DataProviderWithMappedPartner } from "@/modules/dataProvider/dataProvider.service"
import { metersListMetersPaginated } from "@/client/sdk.gen"
import type { Meter, ApiDataProvider } from "@/client/types.gen"
import MetersTable from "@/modules/meter/components/MetersTable.vue"
import { useDataProviderService } from "@/services/service-container"
import { debounce } from "@/utils/debounce"
import type { PageInfo } from "@/services/base-fetcher"

const props = defineProps<{
  dataProviderId: number
}>()

const dataProviderService = useDataProviderService()

const isLoadingDataProvider = ref<boolean>(true)
const isLoadingMeters = ref<boolean>(true)
const hasError = ref<boolean>(false)
const dataProvider = ref<DataProviderWithMappedPartner | null>(null)

const hasMetersError = ref<boolean>(false)
const meters = ref<Meter[]>([])
const pageInfo = ref<PageInfo>()
const searchQuery = ref<string>("")

const loadDataProvider = async () => {
  isLoadingDataProvider.value = true
  hasError.value = false

  try {
    const providers = await dataProviderService.listDataProviders()
    dataProvider.value = providers.find((provider) => provider.id === props.dataProviderId) || null
  } catch (error) {
    hasError.value = true
    console.error("Error loading data provider", error)
  } finally {
    isLoadingDataProvider.value = false
  }
}

const loadMeters = async (url?: string, page?: string | null) => {
  if (!dataProvider.value) {
    return
  }

  isLoadingMeters.value = true
  hasMetersError.value = false

  try {
    const result = await metersListMetersPaginated({
      query: {
        data_provider_id: props.dataProviderId,
        api_provider: dataProvider.value.apiProvider as ApiDataProvider,
        per_page: 10,
        page,
        ...(searchQuery.value ? { q: searchQuery.value } : {}),
      },
    })

    meters.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    hasMetersError.value = true
    console.error("Error loading meters", error)
  } finally {
    isLoadingMeters.value = false
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleMeterRowClick = (meter: Meter) => {
  // Do nothing for now
}

const debouncedLoadMeters = debounce(() => {
  loadMeters()
})

watch([searchQuery], () => {
  if (searchQuery.value === "") {
    loadMeters()
  } else {
    debouncedLoadMeters()
  }
})

onMounted(async () => {
  await loadDataProvider()
  if (dataProvider.value) {
    await loadMeters()
  }
})
</script>
