<template>
  <WcModal :is-open="true" :header="options.title || ''" :icon="options.icon" type="disrupt" @update:is-open="(value) => !value && handleCancel()">
    <template #panel>
      <span class="text-body-2 text-black">
        {{ options.message }}
      </span>
    </template>
    <template #actions>
      <WcButton :text="options.cancelText" variant="tertiary" size="small" autofocus @click="handleCancel" />
      <WcButton :text="options.confirmText" size="small" color="alert" @click="handleConfirm" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import type { ConfirmOptions } from "./ConfirmContext"

defineProps<{
  options: ConfirmOptions
}>()

const emit = defineEmits<{
  (e: "modal-reject"): void
  (e: "modal-resolve"): void
}>()

const handleConfirm = () => emit("modal-resolve")
const handleCancel = () => emit("modal-reject")
</script>
