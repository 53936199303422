<template>
  <AppPage>
    <AppPageHeader>
      Portfolios
      <template #description> Manage shared portfolios and collaborations </template>
    </AppPageHeader>

    <AppPageContent>
      <ThirdPartyAssetsSection v-if="!isLoading && !hasError" :portfolios="portfolios" />
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import { PortfolioWithSummary } from "@/models/order"
import ThirdPartyAssetsSection from "@/modules/asset/components/ThirdPartyAssetsSection.vue"
import { usePortfolioService } from "@/services/service-container"
const portfolios = ref<PortfolioWithSummary[]>([])
const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)

const portfolioService = usePortfolioService()

const loadPortfolios = async () => {
  try {
    portfolios.value = await portfolioService.listPortfolios()
  } catch (error) {
    console.error("There was an error loading portfolio info", error)
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  loadPortfolios()
})
</script>
