<template>
  <header class="relative z-40 w-full border-b border-neutral-30 bg-white dark:bg-blue-90">
    <nav
      class="wc-page-container flex min-h-16 w-full max-w-[1440px] flex-wrap items-center justify-between gap-x-10 gap-y-2 lg:flex-nowrap"
      :class="{ spoof: store.isSpoof }">
      <!-- Logo (mobile) -->
      <a
        :href="WWW_BASE_URL"
        class="ph-no-capture order-1 flex h-[32px] w-[166px] shrink-0 cursor-pointer justify-start lg:mt-0 lg:hidden"
        @click="captureHomeEvent">
        <img src="@/static/img/wattcarbon_logo_on-light.svg" alt="WattCarbon" class="dark:hidden" />
      </a>
      <div
        class="text-body-2 order-3 basis-full lg:order-2 lg:basis-auto"
        :class="{ 'hidden lg:block': route.meta.appPortal == AppPortal.Dashboard }">
        <ul class="flex items-center justify-center gap-5 lg:gap-12">
          <!-- Logo (Desktop) -->
          <a
            :href="WWW_BASE_URL"
            class="ph-no-capture hidden h-[32px] w-[166px] shrink-0 cursor-pointer justify-start lg:mt-0 lg:flex"
            @click="captureHomeEvent">
            <img src="@/static/img/wattcarbon_logo_on-light.svg" alt="WattCarbon" class="dark:hidden" />
          </a>
          <li v-if="route.meta.appPortal == AppPortal.Dashboard" class="text-subheading-2 ml-4">
            <a :href="`${WWW_BASE_URL}/products/weats-pro`" class="ph-no-capture py-3" @click="captureEvent">WEATS Pro</a>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Dashboard" class="text-subheading-2">
            <a :href="`${WWW_BASE_URL}/weats`" class="ph-no-capture py-3" @click="captureEvent">WEATS Registry</a>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Dashboard"
            class="hover:text-black"
            @mouseover="showHeaderDropdowns(HeaderDropdowns.Resources)"
            @mouseleave="hideHeaderDropdowns">
            <div
              class="text-subheading-2 flex cursor-pointer items-center gap-2"
              :class="{ 'text-hint': currentHeaderDropdown === HeaderDropdowns.Resources }">
              Resources
            </div>
            <transition name="fade">
              <div v-if="currentHeaderDropdown === HeaderDropdowns.Resources" class="nav-menu">
                <div class="nav-menu-inner">
                  <a :href="`${WWW_BASE_URL}/measurement-and-verification`" class="ph-no-capture text-body-2" @click="captureEvent"
                    >Measurement & Verification</a
                  >
                  <a :href="`${WWW_BASE_URL}/faq`" class="ph-no-capture text-body-2" @click="captureEvent">FAQ</a>
                  <a href="https://blog.wattcarbon.com" class="ph-no-capture text-body-2" target="_blank" @click="captureEvent">Blog</a>
                </div>
              </div>
            </transition>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Dashboard"
            :class="
              getMenuClass([
                'wc-dashboard',
                'wc-assets',
                'wc-asset-groups',
                'wc-activity-log',
                'wc-eacs',
                'wc-accounting',
                'wc-load',
                'wc-portfolio-tracker',
                'wc-procurement',
              ])
            "
            class="text-subheading-2">
            <router-link to="/dashboard" class="ph-no-capture py-3" @click="captureEvent">Dashboard</router-link>
          </li>
        </ul>
      </div>
      <div class="order-2 flex lg:order-3">
        <Menu as="div" class="text-body-2 relative">
          <MenuButton class="flex items-center gap-3">
            <div v-if="!store.isLoggedIn" class="size-[30px] rounded-full bg-highlight-yellow"></div>
            <div v-else class="relative size-[38px]" data-cy="header-menu-icon">
              <div class="absolute inset-[3px] flex items-center justify-center rounded-full bg-highlight-yellow">
                <span class="text-body-2">{{ store.account.name?.[0] }}</span>
              </div>
              <div class="absolute inset-0 rounded-full border border-sagetone-dark"></div>
            </div>
          </MenuButton>
          <transition>
            <MenuItems
              v-if="store.isLoggedIn"
              class="absolute right-0 mt-2 w-[240px] origin-top-right divide-y divide-neutral-20 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-blue-90 dark:text-white">
              <div class="px-3 py-4">
                <section class="pb-1">
                  <div class="group flex w-full cursor-pointer flex-col rounded-sm border-b border-neutral-20 px-2 pb-2 pt-1">
                    <div class="text-body-2 mb-2 text-sagetone">You are logged in as</div>
                    <div class="text-body-1">{{ store.account.name }}</div>
                  </div>
                  <MenuItem as="div" class="mt-1 cursor-pointer" @click="store.isAccountSwitcherModalOpen = true">
                    <div class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-20" data-cy="header-menu-switch-account">
                      <Icon icon="wc-carbon:user-multiple" />
                      <div class="ml-2">Switch account</div>
                    </div>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer" @click="captureEvent">
                    <router-link
                      to="/accounts/manage"
                      class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-20"
                      active-class="bg-highlight-frostblue">
                      <Icon icon="wc-carbon:credentials" />
                      <div class="ml-2">Manage accounts</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem v-if="isAristotleEnabled" as="div" class="mt-1 cursor-pointer" @click="captureEvent">
                    <router-link
                      to="/data-providers"
                      class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-20"
                      active-class="bg-highlight-frostblue">
                      <Icon icon="wc-carbon:lightning" />
                      <div class="ml-2">Data providers</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem v-if="isAristotleEnabled" as="div" class="mt-1 cursor-pointer" @click="captureEvent">
                    <router-link to="/billing" class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-20">
                      <Icon icon="material-symbols:request-quote-outline" />
                      <div class="ml-2">Billing</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="border-t border-neutral-30 pb-1 lg:hidden">
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" :class="getMenuClass(['wc-dashboard'])" @click="captureEvent">
                    <router-link to="/dashboard" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Dashboard</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/weats`" class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1" @click="captureEvent"
                      >WEATS Registry</a
                    >
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a
                      :href="`${WWW_BASE_URL}/products/weats-pro`"
                      class="ph-no-capture text-body-2 group flex w-full items-center rounded-sm px-2 py-1"
                      @click="captureEvent">
                      WEATS Pro
                    </a>
                  </MenuItem>
                  <div class="text-body-3 mb-1 ml-2 mt-2 text-sagetone">Resources</div>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a
                      :href="`${WWW_BASE_URL}/measurement-and-verification`"
                      class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1">
                      <div class="ml-2">Measurement & Verification</div>
                    </a>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/faq`" class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1">
                      <div class="ml-2">FAQ</div>
                    </a>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <a href="https://blog.wattcarbon.com" class="ph-no-capture group flex w-full items-center rounded-sm px-2 py-1" target="_blank">
                      <div class="ml-2">Blog</div>
                    </a>
                  </MenuItem>
                </section>
                <section :class="{ 'border-t border-neutral-30 pb-1': store.isAdmin }">
                  <MenuItem
                    v-if="store.isAdmin"
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <router-link to="/admin" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Admin Portal</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/terms`" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Terms & Conditions</div>
                    </a>
                  </MenuItem>
                </section>
                <section class="border-t border-neutral-30 pb-1">
                  <MenuItem
                    v-if="store.isSpoof"
                    as="div"
                    class="mt-1 cursor-pointer rounded-sm bg-neutral-30 p-2 text-center hover:bg-neutral-20"
                    @click="unspoof">
                    <div>Unspoof</div>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer rounded-sm hover:bg-neutral-20">
                    <router-link to="/signout" class="group flex w-full items-center px-2 py-1">
                      <Icon icon="wc-carbon:logout" />
                      <div class="ml-2">Sign Out</div>
                    </router-link>
                  </MenuItem>
                </section>
              </div>
            </MenuItems>
            <MenuItems
              v-else
              class="text-body-1 absolute right-0 mt-2 w-[240px] origin-top-right divide-y divide-neutral-20 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div class="py-1">
                <section class="border-b border-neutral-20 py-2">
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link to="/register" class="text-body-1-strong group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20">
                      <div class="ml-2">Create an account</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link to="/register" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20">
                      <div class="ml-2">Sign in</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="border-b border-neutral-20 py-2">
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-20" @click="captureEvent">
                    <router-link to="/assets" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-20">
                      <div class="ml-2">Sell EACs</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="py-2">
                  <div class="text-body-3 my-1 ml-4 text-sagetone">Resources</div>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/measurement-and-verification`" class="group flex w-full items-center rounded-sm p-2">
                      <div class="ml-2">Measurement & Verification</div>
                    </a>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/faq`" class="group flex w-full items-center rounded-sm p-2">
                      <div class="ml-2">FAQ</div>
                    </a>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-highlight-frostblue' : 'hover:bg-neutral-20'"
                    @click="captureEvent">
                    <a href="https://blog.wattcarbon.com" class="group flex w-full items-center rounded-sm p-2" target="_blank">
                      <div class="ml-2">Blog</div>
                    </a>
                  </MenuItem>
                </section>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </nav>
  </header>
  <AccountSwitcher />
</template>

<script lang="ts" setup>
import { getEnvironment } from "@/environment"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { useRoute, useRouter } from "vue-router"
import { Icon } from "@iconify/vue"
import { onMounted, ref } from "vue"
import posthog from "posthog-js"
import { useAristotle } from "@/features"
import { useMainStore } from "@/store"
import { AppPortal } from "@/models/models"
import { useAccountsService, useAuthService } from "@/services/service-container"
import AccountSwitcher from "@/modules/accounts/AccountSwitcher.vue"

const { WWW_BASE_URL } = getEnvironment()
const route = useRoute()

const { push } = useRouter()
const store = useMainStore()
const authService = useAuthService()
const accountsService = useAccountsService()
const isAristotleEnabled = useAristotle()

const captureHomeEvent = () => posthog.capture("Clicked on the 'Home' link - Nav Header")
const captureEvent = (e: PointerEvent | MouseEvent) => posthog.capture(`Clicked on the '${(e.target as HTMLElement).textContent}' link - Nav Header`)

const unspoof = async () => {
  await authService.unspoof()
  store.accounts = await accountsService.listMyAccounts()
  await push({ path: "/" })
}

onMounted(async () => {
  if (store.isLoggedIn) {
    store.accounts = await accountsService.listMyAccounts()
  }
})

const getMenuClass = (prefixes: Array<string>) => {
  return prefixes.find((prefix) => route.name?.toString().indexOf(prefix) === 0) ? "active" : ""
}

enum HeaderDropdowns {
  Dashboard = "Dashboard",
  Resources = "Resources",
}
const currentHeaderDropdown = ref<HeaderDropdowns>()
const showHeaderDropdowns = (dropdown: HeaderDropdowns) => {
  currentHeaderDropdown.value = dropdown
}
const hideHeaderDropdowns = () => {
  currentHeaderDropdown.value = undefined
}
</script>

<style scoped lang="scss">
@tailwind utilities;

ul {
  li {
    @apply dark:text-white;

    &:hover {
      @apply text-hint;
    }

    &.active {
      @apply font-bold #{!important};
      @apply lg:-mb-[22px] lg:pb-[19px] lg:border-b-[3px] lg:border-b-sagetone lg:-mx-6 lg:px-6;
    }
  }
}
nav.spoof {
  background-color: theme("colors.blue.70");

  div ul.tabs {
    li {
      @apply border-b-4 border-b-blue-40;

      &:hover,
      &.active {
        @apply border-b-neutral-99;
      }

      &.active {
        @apply shadow-md;
      }
    }
  }
}
.nav-menu {
  @apply absolute -ml-2 w-[216px];

  .nav-menu-inner {
    @apply mt-3 flex flex-col items-start rounded bg-white py-1;
    box-shadow:
      0px 8px 8px 0px rgba(4, 15, 36, 0.04),
      0px 5px 14px 0px rgba(4, 15, 36, 0.06);

    a {
      @apply w-full px-3 py-2 hover:bg-neutral-20;
    }
  }
}
div[role="menuitem"].active {
  @apply bg-highlight-frostblue;
}
</style>
