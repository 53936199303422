<template>
  <div :id="chartId" class="bg-white dark:bg-blue-99"></div>
</template>

<script setup lang="ts">
// Libraries
import * as echarts from "echarts"
import {
  EChartsOption,
  EChartsType,
  DefaultLabelFormatterCallbackParams,
  TooltipComponentFormatterCallbackParams,
  TooltipComponentFormatterCallback,
  XAXisComponentOption,
  DataZoomComponentOption,
  SeriesOption,
} from "echarts"
import { onMounted, watch } from "vue"
import { sanitizeHtml } from "@/utils/sanitizeHtml"

// Models
import type { AssetLoadshapeRow } from "@/modules/asset/assetLoadshape.model"
import { LoadshapeInterval } from "@/modules/asset/assetLoadshape.model"

// Services
import { VizHelper } from "@/components/visualizations/VizHelper"
import { useMainStore } from "@/store"

// ECharts doesn't export this type, so defining here
type CategoryAxisBaseOption = {
  data: Record<string, any>
}

type Props = {
  data: Array<AssetLoadshapeRow>
  enableCarbonIntensity?: boolean
  hasElectricityReferenceModelRows?: boolean
  hasElectricityRows?: boolean
  height?: string
  includeDataZoom?: boolean
  interval?: LoadshapeInterval
  width?: string
  seriesVisibility?: {
    carbon: boolean
    carbonIntensity: boolean
    energy: boolean
    energyReferenceModel: boolean
  }
}

const series = {
  Energy: { name: "Energy", unit: "kWh" },
  "Reference model": { name: "Reference model", unit: "kWh" },
  Carbon: { name: "Carbon", unit: "kg" },
  "Carbon intensity": { name: "Carbon intensity", unit: "kg/MWh" },
}

const props = withDefaults(defineProps<Props>(), {
  enableCarbonIntensity: true,
  hasElectricityReferenceModelRows: false,
  hasElectricityRows: false,
  includeDataZoom: false,
  interval: LoadshapeInterval.hourly,
  seriesVisibility: () => ({
    carbon: false,
    carbonIntensity: false,
    energy: true,
    energyReferenceModel: true,
  }),
})
const emit = defineEmits(["onChange"])

const store = useMainStore()

const chartId = "energy-line-chart-" + new Date().getMilliseconds()

type SeriesDataValue = number | null

const constructSeriesList = (data: {
  carbon: SeriesDataValue[]
  energy: SeriesDataValue[]
  energyReferenceModel: SeriesDataValue[]
  intensity: SeriesDataValue[]
}): SeriesOption[] => {
  const { carbon, energy, energyReferenceModel, intensity } = data
  const hasASingleEnergyValue = energy.filter((value) => value !== null && value > 0).length === 1
  const hasASingleCarbonValue = carbon.filter((value) => value !== null && value > 0).length === 1
  const hasASingleIntensityValue = intensity.filter((value) => value !== null && value > 0).length === 1

  const energySeries = {
    name: "Energy",
    type: "line" as any,
    data: energy,
    symbol: hasASingleEnergyValue ? "circle" : "none",
    symbolSize: 10,
    itemStyle: {
      color: store.colors.energy,
    },
    z: 2,
  }

  const energyReferenceModelSeries = {
    name: "Reference model",
    type: "line" as any,
    data: energyReferenceModel,
    symbol: "none",
    symbolSize: 10,
    itemStyle: {
      color: "rgba(205, 215, 218, 0.8)",
    },
    lineStyle: {
      opacity: 0,
    },
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgba(205, 215, 218, 0.4)",
        },
        {
          offset: 1,
          color: "rgba(205, 215, 218, 0.05)",
        },
      ]),
    },
    z: 1,
  }

  const carbonSeries = {
    name: "Carbon",
    type: "line" as any,
    data: carbon,
    symbol: hasASingleCarbonValue ? "circle" : "none",
    symbolSize: 10,
    itemStyle: {
      color: store.colors.carbon,
    },
    yAxisIndex: 1,
    z: 2,
  }

  const intensitySeries = {
    name: "Carbon intensity",
    type: "line" as any,
    data: intensity,
    symbol: hasASingleIntensityValue ? "circle" : "none",
    symbolSize: 10,
    itemStyle: {
      color: store.colors.carbonIntensity,
    },
    lineStyle: {
      color: store.colors.carbonIntensity,
      opacity: hasASingleIntensityValue ? 0 : 1,
      type: "dotted",
    },
    yAxisIndex: 2,
    z: 3,
  }

  if (props.hasElectricityRows) {
    const series = [energySeries]
    if (props.hasElectricityReferenceModelRows) {
      series.push(energyReferenceModelSeries)
    }
    series.push(carbonSeries)
    if (props.enableCarbonIntensity) {
      series.push(intensitySeries)
    }
    return series
  } else {
    return [carbonSeries]
  }
}

const option: EChartsOption = {
  legend: {
    show: false,
    data: Object.keys(series),
    inactiveColor: "#777",
    textStyle: {
      color: store.colors.text,
      fontFamily: "'iA Writer Duospace', monospace",
    },
    right: 0,
    top: 0,
  },
  grid: {
    left: 50,
    top: 34,
    right: 60,
    bottom: props.includeDataZoom ? 80 : 20,
  },
  barGap: 0,
  tooltip: {
    trigger: "axis",
    axisPointer: {
      animation: false,
      type: "line",
      lineStyle: {
        color: store.colors.text,
        width: 2,
        opacity: 1,
      },
    },
    textStyle: {
      fontFamily: "'iA Writer Duospace', monospace",
    },
    formatter: ((params: DefaultLabelFormatterCallbackParams[]) => {
      const seriesParams = params.filter((param) => (param as any).axisType === "xAxis.category")

      if (seriesParams == null || seriesParams.length === 0) {
        return ""
      }

      const header = `<div class="mb-1">${seriesParams[0].name}</div>`
      const container = `
      <div class="grid grid-cols-[auto_auto_auto] gap-1">
        ${seriesParams
          .map((param) => {
            const value = typeof param.value === "number" ? param.value.toFixed(2) : ""
            const unit = value != "" ? series[param.seriesName as keyof typeof series].unit : ""
            return `
              <span>${param.marker} ${param.seriesName}</span>
              <span class="font-semibold text-right ml-1">${value}</span>
              <span class="text-left">${unit}</span>
            `
          })
          .join("")}
      </div>`

      return sanitizeHtml(header + container)
    }) as TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> | undefined | string,
  },
  xAxis: {
    type: "category",
    data: [],
    boundaryGap: false,
    axisLine: { lineStyle: { color: "#99A5A8" } },
    axisLabel: {
      color: store.colors.text,
      fontSize: 10,
      fontFamily: "'iA Writer Duospace', monospace",
      margin: 8,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: [
    {
      type: "value",
      name: series["Energy"].unit,
      scale: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      splitLine: {
        lineStyle: {
          color: "#E5EAEB",
        },
      },
      nameTextStyle: {
        fontFamily: "'iA Writer Duospace', monospace",
        fontSize: 12,
        align: "right",
        padding: [0, 4, 8, 0],
      },
      nameLocation: "end",
      nameGap: 15,
      axisLabel: {
        color: store.colors.text,
        formatter: (value: number) => Math.round(value).toString(),
        fontSize: 10,
        fontFamily: "'iA Writer Duospace', monospace",
        margin: 4,
      },
      axisPointer: {
        show: true,
        label: {
          formatter: ({ value }: { value: any }) => (value != null ? `${Math.round(value)} ${series["Energy"].unit}` : value),
          fontFamily: "'iA Writer Duospace', monospace",
        },
      },
    },
    {
      type: "value",
      name: series["Carbon"].unit,
      scale: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      splitLine: {
        lineStyle: {
          color: "#E5EAEB",
        },
      },
      nameTextStyle: {
        fontFamily: "'iA Writer Duospace', monospace",
        fontSize: 12,
        align: "left",
        padding: [0, 0, 8, 4],
      },
      nameLocation: "end",
      nameGap: 15,
      axisLabel: {
        color: store.colors.text,
        formatter: (value: number) => Math.round(value).toString(),
        fontSize: 10,
        fontFamily: "'iA Writer Duospace', monospace",
        margin: 4,
      },
      axisPointer: {
        show: true,
        label: {
          formatter: ({ value }: { value: any }) => (value != null ? `${Math.round(value)} ${series["Carbon"].unit}` : value),
          fontFamily: "'iA Writer Duospace', monospace",
        },
      },
    },
    {
      type: "value",
      show: false,
    },
  ],
  dataZoom: props.includeDataZoom
    ? {
        type: "slider",
        textStyle: {
          color: store.colors.text,
          fontFamily: "'iA Writer Duospace', monospace",
        },
        backgroundColor: store.colors.background,
        borderColor: store.colors.background,
        dataBackground: {
          lineStyle: {
            opacity: 1,
            color: store.colors.grid,
          },
        },
        fillerColor: "rgb(0,0,0,.03)",
        brushStyle: {
          color: store.colors.energy,
          borderColor: store.colors.energy,
          opacity: 0.2,
        },
        handleStyle: {
          color: store.colors.grid,
          borderColor: store.colors.grid,
          opacity: 0.7,
        },
        moveHandleStyle: {
          color: store.colors.grid,
          borderColor: store.colors.grid,
          opacity: 0.4,
        },
        emphasis: {
          handleStyle: {
            color: store.colors.grid,
            borderColor: store.colors.grid,
            opacity: 0.8,
          },
          moveHandleStyle: {
            color: store.colors.grid,
            borderColor: store.colors.grid,
            opacity: 0.5,
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: store.colors.grid,
            opacity: 1,
          },
          areaStyle: {
            color: store.colors.energy,
            opacity: 1,
          },
        },
        brushSelect: true,
      }
    : undefined,
  series: constructSeriesList({ energy: [], carbon: [], intensity: [], energyReferenceModel: [] }),
}

let chart: EChartsType

const renderChart = (rows: Array<AssetLoadshapeRow>) => {
  const dates = rows.map((h: AssetLoadshapeRow) => {
    if (props.interval === LoadshapeInterval.hourly) {
      return h.UTCHourLabel
    }
    if (props.interval === LoadshapeInterval.daily) {
      return h.UTCDayLabel
    }
    if (props.interval === LoadshapeInterval.timeOfDay) {
      return h.TimeOfDayLabel
    }
    return h.UTCMonthLabel
  })
  const _energy = rows.map((h: AssetLoadshapeRow) => h.NetElectricityKilowattHours)
  const _energyReferenceModel = rows.map((h: AssetLoadshapeRow) => h.ComparisonNetElectricityKilowattHours)
  const _carbon = rows.map((h: AssetLoadshapeRow) => h.NetCarbonEmittedKilograms)
  const _intensity = rows.map((h: AssetLoadshapeRow) => h.CarbonIntensityKgPerMWh)
  const intervals = 7

  const nonNullEnergyValues = _energy.filter((value) => value !== null) as number[]
  const nonNullEnergyReferenceModelValues = _energyReferenceModel.filter((value) => value !== null) as number[]
  const { ticks: energyTicks } = VizHelper.getAxisTicks(
    Math.min(...nonNullEnergyValues, ...nonNullEnergyReferenceModelValues),
    Math.max(...nonNullEnergyValues, ...nonNullEnergyReferenceModelValues),
    intervals
  )

  const nonNullCarbonValues = _carbon.filter((value) => value !== null) as number[]
  const { ticks: carbonTicks } = VizHelper.getAxisTicks(Math.min(...nonNullCarbonValues), Math.max(...nonNullCarbonValues), intervals)

  const energyMin = Math.min(...energyTicks)
  const energyMax = Math.max(...energyTicks)
  const carbonMax = Math.max(...carbonTicks)
  const carbonMin = Math.min(...carbonTicks)

  const yMax = energyMax > carbonMax ? energyMax : carbonMax
  const yMin = energyMin < carbonMin ? energyMin : carbonMin

  // Determine which series to show in the legend
  const legendData = []
  const selected: Record<string, boolean> = {}

  if (props.hasElectricityRows) {
    legendData.push("Energy")
    selected["Energy"] = props.seriesVisibility.energy

    legendData.push("Reference model")
    selected["Reference model"] = props.seriesVisibility.energyReferenceModel

    legendData.push("Carbon")
    selected["Carbon"] = props.seriesVisibility.carbon

    if (props.enableCarbonIntensity) {
      legendData.push("Carbon intensity")
      selected["Carbon intensity"] = props.seriesVisibility.carbonIntensity
    }
  } else {
    legendData.push("Carbon")
    selected["Carbon"] = props.seriesVisibility.carbon
  }

  chart.setOption(
    {
      dataZoom: props.includeDataZoom
        ? [
            {
              start: 0,
              end: 100,
            },
          ]
        : undefined,
      legend: {
        show: false,
        data: legendData,
        selected: selected,
      },
      xAxis: {
        data: dates,
      },
      yAxis: [
        {
          type: "value",
          min: yMin,
          max: yMax,
          show: props.hasElectricityRows,
          axisPointer: {
            show: props.hasElectricityRows,
          },
        },
        {
          type: "value",
          min: yMin,
          max: yMax,
        },
      ],
      series: constructSeriesList({
        energy: _energy,
        energyReferenceModel: _energyReferenceModel,
        carbon: _carbon,
        intensity: _intensity,
      }),
    },
    { replaceMerge: ["series", "legend"] }
  )
}

onMounted(() => {
  const chartDom = document.getElementById(chartId)
  if (!chartDom) {
    return
  }
  chart = echarts.init(chartDom, undefined, {
    width: props.width,
    height: props.height,
  })
  if (option) {
    chart.setOption(option)
  }

  chart.on("dataZoom", () => {
    const options = chart.getOption()
    const dataZooms = options.dataZoom as Array<DataZoomComponentOption>
    const xAxis = options.xAxis as Array<XAXisComponentOption>
    const { startValue, endValue } = dataZooms[0]
    const xAxisData = (xAxis[0] as CategoryAxisBaseOption).data
    const startDate = xAxisData[startValue as string]
    const endDate = xAxisData[endValue as string]
    emit("onChange", { startDate, endDate })
  })
  renderChart(props.data)
  new ResizeObserver(() => chart.resize()).observe(chartDom)
})

watch(
  () => props.data,
  (value: Array<AssetLoadshapeRow>) => {
    renderChart(value)
  }
)

watch(
  () => props.seriesVisibility,
  () => {
    renderChart(props.data)
  },
  { deep: true }
)
</script>
