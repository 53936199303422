<template>
  <Icon v-if="isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />

  <template v-else>
    <table class="w-full">
      <thead class="border-b border-sagetone">
        <tr>
          <th class="text-left">Stakeholder</th>
          <th class="text-left">Date</th>
          <th class="text-right">Quantity</th>
          <th class="text-right">Paid</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-sagetone">
        <tr v-for="stake in stakes" :key="stake.accountId">
          <td class="max-w-[20ch] truncate py-2 text-left underline">
            <router-link :to="{ name: 'wc-admin-account-portfolios', params: { accountId: stake.accountId } }">
              {{ stake.name }}
            </router-link>
          </td>
          <td class="py-2 text-left">
            {{ formatDate(stake.createdTime) }}
          </td>
          <td class="py-2 text-right">
            {{ stake.quantity.toLocaleString() }}
          </td>
          <td class="py-2 text-right">
            <template v-if="stake.paidPenniesUsd">
              {{ formatCurrencyInPennies(stake.paidPenniesUsd) }}
            </template>
          </td>
          <td class="flex justify-end py-2 pr-2">
            <button class="text-error disabled:cursor-not-allowed disabled:text-sagetone" @click="removeStake(stake)">
              <Icon icon="wc-carbon:close-outline" />
              Remove
            </button>
          </td>
        </tr>
        <tr v-if="stakes.length == 0">
          <td colspan="5" class="py-2">No stakeholders.</td>
        </tr>
      </tbody>
      <tfoot class="border-t border-sagetone">
        <tr>
          <td class="py-2">
            <button class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone" @click="isAddStakeDialogOpen = true">
              <Icon icon="wc-carbon:add" />
              Add Stakeholder
            </button>
          </td>
          <td></td>
          <td class="text-right">
            {{ totalDemand.toLocaleString() }}
          </td>
          <td class="text-right">
            {{ formatCurrencyInPennies(totalPaid) }}
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </template>

  <WcModal :is-open="isAddStakeDialogOpen" header="Add Stake" @update:is-open="(isOpen) => (isAddStakeDialogOpen = isOpen)">
    <template #panel>
      <form ref="formRef" @submit.prevent="addStake">
        <label>
          Stakeholder
          <select name="stakeholder" class="p-2" required>
            <option></option>
            <option v-for="s in unattachedAccounts" :key="s.id" :value="s.id">
              {{ s.name }}
            </option>
          </select>
        </label>

        <label class="mt-2">
          EACs Expected

          <input type="number" name="quantity" class="p-2" min="1" required />
        </label>

        <label class="mt-2">
          USD Paid

          <input type="text" name="paidUsd" class="p-2" />
        </label>
      </form>
    </template>
    <template #actions>
      <WcButton text="Cancel" variant="secondary" size="small" @click="isAddStakeDialogOpen = false" />
      <WcButton text="Attach" size="small" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { computed, ref, onMounted } from "vue"
import { PortfolioStakeholderRelationship } from "@/models/order"
import { Icon } from "@iconify/vue"
import { WcButton } from "@/components/button"
import { formatDate } from "@/utils/formatDate"
import WcModal from "@/components/WcModal.vue"
import _ from "lodash"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"

const props = defineProps<{
  portfolioId: string
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const adminAccountsStore = useAdminAccountsStore()

const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

const formRef = ref<HTMLFormElement | null>(null)

const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

const isLoading = ref<boolean>(true)
const stakes = ref<PortfolioStakeholderRelationship[]>([])
onMounted(async () => {
  try {
    stakes.value = await adminPortfoliosStore.listStakes(props.portfolioId)
  } finally {
    isLoading.value = false
  }
})

const totalDemand = computed(() => {
  return stakes.value.reduce((acc, s) => acc + s.quantity, 0)
})

const totalPaid = computed(() => {
  return stakes.value.reduce((acc, s) => acc + (s.paidPenniesUsd ?? 0), 0)
})

const isAddStakeDialogOpen = ref<boolean>(false)
const unattachedAccounts = computed(() => {
  const allOrgs = adminAccountsStore.accounts.filter((a) => a.type === "organization")
  const unattached = _.differenceWith(allOrgs, stakes.value, (s1, s2) => s1.id == s2.accountId)
  return _.sortBy(unattached, ["name"])
})

const addStake = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  const stakeholder = adminAccountsStore.getAccountById(parseInt(formEl.stakeholder.value))!
  const quantity = parseInt(formEl.quantity.value)
  const paidPenniesUsd = parseFloat(formEl.paidUsd.value) * 100

  const newStake = await adminPortfoliosStore.addStake(portfolio.value, stakeholder, quantity, paidPenniesUsd)
  stakes.value.push(newStake)

  isAddStakeDialogOpen.value = false
  formEl.reset()
}

const removeStake = async (stake: PortfolioStakeholderRelationship) => {
  if (confirm(`Remove ${stake.name} from this portfolio?`)) {
    await adminPortfoliosStore.removeStake(portfolio.value, stake)

    const stakeIndex = stakes.value.indexOf(stake)
    if (stakeIndex !== -1) {
      stakes.value.splice(stakeIndex, 1)
    }
  }
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}
</style>
