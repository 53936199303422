import { ref, onUnmounted } from "vue"

// Composable for locking the scroll of the page body. Used when displaying modals to prevent background scrolling
export function useScrollLock() {
  const isLocked = ref(false)

  let scrollPosition = 0
  let originalStyles = {
    position: "",
    top: "",
    width: "",
  }

  function lockScroll() {
    if (isLocked.value) {
      return
    }

    // Store scroll position
    scrollPosition = window.scrollY || document.documentElement.scrollTop

    // Save styles before modifying
    originalStyles = {
      position: document.body.style.position,
      top: document.body.style.top,
      width: document.body.style.width,
    }

    // Apply styles to lock body
    document.body.style.position = "fixed"
    document.body.style.top = `-${scrollPosition}px`
    document.body.style.width = "100%"

    isLocked.value = true
  }

  function unlockScroll() {
    if (!isLocked.value) {
      return
    }

    // Restore original styles
    document.body.style.position = originalStyles.position
    document.body.style.top = originalStyles.top
    document.body.style.width = originalStyles.width

    // Restore scroll position
    window.scrollTo(0, scrollPosition)

    isLocked.value = false
  }

  onUnmounted(() => {
    if (isLocked.value) {
      unlockScroll()
    }
  })

  return {
    lockScroll,
    unlockScroll,
  }
}
