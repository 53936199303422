<template>
  <AppPage>
    <AppPageHeader>
      Asset Management
      <template #title-adjacent>
        <div class="flex gap-5">
          <WcCTA text="Connect my data" weight="heavy" icon="wc-carbon:lightning" />
        </div>
      </template>
      <template #description>
        View and manage assets that you have registered on WEATS. Each of these represents either an energy-consuming asset or an energy intervention
        resulting in EACs. Examples of energy interventions include solar panels, batteries, demand response events, and buildings that underwent an
        energy efficiency upgrade.
        <LearnMore :href="`${WWW_BASE_URL}/faq#suppliers`" />
      </template>
    </AppPageHeader>
    <AppPageContent>
      <AppPageSection>
        <div class="flex flex-wrap items-end justify-between gap-2">
          <div class="flex grow flex-wrap items-center gap-3">
            <WcDropdown
              v-model="filterAssetStatus"
              class="shrink-0 basis-40"
              name="filterAssetStatus"
              :options="statusOptions"
              inset-label="Status"
              size="small" />
            <WcCalendarRange
              v-model="filterAssetCreatedDateRange"
              class="shrink-0 basis-40"
              :class="{ 'basis-56': (filterAssetCreatedDateRange || [])[1] != null }"
              name="filterAssetCreatedDateRange"
              inset-label="Created date"
              size="small" />
            <WcInputText
              v-model="filterAssetLocationKeyword"
              icon="wc-carbon:search"
              class="shrink-0 basis-48"
              name="filterAssetLocationKeyword"
              inset-label="Filter by location..."
              size="small" />
            <WcCTA
              class="shrink-0 basis-52 pl-1"
              icon-position="right"
              icon="wc-carbon:close-outline"
              text="Clear Filters"
              size="small"
              @click="handleClearFilters" />
          </div>
          <WcViewModeButton v-model="viewMode" class="ml-auto" size="small" />
        </div>
        <div>
          <div v-if="viewMode === ViewMode.table">
            <div class="mt-4 overflow-x-scroll pb-3">
              <AssetsTable
                :assets="assets"
                allow-multiple-selection
                :selected-row-ids="selectedAssetIds"
                show-eacs
                show-kind
                @row-clicked="handleRowClicked"
                @rows-selected="handleRowsSelected" />
              <PaginationButtons class="mt-3" :page-info="assetsPageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
            </div>
            <div v-if="selectedAssetIds.length" class="mt-4 flex items-center justify-between">
              <div class="text-subheading-1">{{ selectedAssetIds.length }} selected</div>
              <div class="flex flex-wrap justify-end gap-3">
                <WcButton variant="secondary" text="Cancel" size="small" @click="handleClearSelection" />
                <WcButton v-if="assetGroups.length > 0" text="Add to Group" size="small" @click="handleAddAssetsToGroup" />
                <WcButton text="Create New Group" size="small" @click="handleCreateGroupWithAssets" />
              </div>
            </div>
          </div>
          <div v-if="viewMode === ViewMode.map && assetLocations?.length > 0">
            <div class="relative my-4 size-full h-[600px]">
              <WcMap :locations="assetLocations ?? []" class="mb-8" locations-are-precise>
                <template #popup="asset">
                  <AssetPopup :asset="asset" />
                </template>
              </WcMap>
              <PaginationButtons :page-info="assetsPageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
            </div>
          </div>
        </div>
      </AppPageSection>
      <EmptyState v-if="!isLoading && !hasAssetError && assetTotalCount === 0" title="No registered assets">
        <WcButton text="Create a new asset" size="medium" class="mt-4" color="sage" @click="showCreateAssetModal" />
      </EmptyState>
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasAssetError" error-message="There was a problem loading assets. Please try again." />
    </AppPageContent>
    <AddAssetGroupModal ref="addAssetGroupModal" @asset-group-added="handleAssetGroupAdded" />
    <AddAssetGroupWithAssetsModal ref="addAssetGroupWithAssetsModal" @asset-group-added="handleAssetGroupAdded" />
    <AddAssetsToGroupModal ref="addAssetsToGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
  </AppPage>
</template>

<script setup lang="ts">
import { format } from "date-fns"
import { computed, onMounted, ref, watch } from "vue"
import { useModals } from "vue-promise-modals"
import { useRouter } from "vue-router"
import { WcButton, WcCTA } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { ViewMode, WcCalendarRange, WcDropdown, WcInputText, WcViewModeButton } from "@/components/input"
import { AppPage, AppPageContent, AppPageHeader, AppPageSection } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import LearnMore from "@/components/ui/LearnMore.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcMap from "@/components/WcMap/WcMap.vue"
import { mapAssetsToLocations } from "@/modules/asset/asset.service"
import type { AssetGroupWithSummary, AssetWithSummary } from "@/modules/asset/asset.service"
import type { AssetGroup, AssetsSummary, AssetStatus } from "@/models/asset"
import { ASSET_STATUS } from "@/models/asset"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import { handleAssetRowClick } from "./asset.router"
import AssetPopup from "./components/AssetPopup.vue"
import AssetsTable from "./components/AssetsTable.vue"
import { debounce } from "@/utils/debounce"
import { getEnvironment } from "@/environment"
import AddAssetGroupModal from "./components/AddAssetGroupModal.vue"
import AddAssetGroupWithAssetsModal from "./components/AddAssetGroupWithAssetsModal.vue"
import AddAssetModal from "./components/AddAssetModal.vue"
import AddAssetsToGroupModal from "./components/AddAssetsToGroupModal.vue"

const { WWW_BASE_URL } = getEnvironment()

const router = useRouter()
const assetService = useAssetService()
const { openModal } = useModals()

const assetGroups = ref<AssetGroupWithSummary[]>([])
const assets = ref<AssetWithSummary[]>([])
const hasAssetError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const assetsPageInfo = ref<PageInfo>()
const viewMode = ref<ViewMode>(ViewMode.table)

const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)
const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const assetCountsByStatus = ref<AssetsSummary["countsByStatus"]>()
const assetTotalCount = ref<number>(0)
const selectedAssetIds = ref<number[]>([])
const addAssetGroupModal = ref()
const addAssetGroupWithAssetsModal = ref()
const addAssetsToGroupModal = ref()

const loadAssets = async (url?: string) => {
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]
    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
    }
    const result = await assetService.listAssets({ url, per_page: 10, ...filters })
    assets.value = result.data
    assetsPageInfo.value = result.pageInfo
    selectedAssetIds.value = []
  } catch (error) {
    hasAssetError.value = true
    console.error("There was an error loading assets", error)
  }
  isLoading.value = false
}

const loadAssetsSummary = async () => {
  try {
    const result = await assetService.getAssetsSummary()
    assetCountsByStatus.value = result.countsByStatus
    assetTotalCount.value = result.assetCount
  } catch (error) {
    console.error("There was an error loading assets summary info", error)
  }
}

onMounted(async () => {
  loadAssetsSummary()
  loadAssets()
})

watch([filterAssetStatus], () => {
  loadAssets()
})

// Debounced asset filters
watch(
  [filterAssetCreatedDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAssets()
  })
)
const assetLocations = computed(() => mapAssetsToLocations(assets.value))

const handleRowClicked = (asset: { id: number; kind: string }, event: MouseEvent | KeyboardEvent) => {
  handleAssetRowClick(asset, event, router)
}

const handleRowsSelected = (selectedRowIDs: number[]) => {
  selectedAssetIds.value = selectedRowIDs
}

const handleClearSelection = () => {
  selectedAssetIds.value = []
}

const handleAddAssetsToGroup = () => {
  const assetsToAdd = assets.value.filter((asset) => selectedAssetIds.value.includes(asset.id))
  addAssetsToGroupModal.value.openModal(assetsToAdd)
}

const handleCreateGroupWithAssets = () => {
  const assetsToAdd = assets.value.filter((asset) => selectedAssetIds.value.includes(asset.id))
  addAssetGroupWithAssetsModal.value.openModal(assetsToAdd)
}

const handleClearFilters = () => {
  filterAssetStatus.value = null
  filterAssetLocationKeyword.value = null
  filterAssetCreatedDateRange.value = null
  loadAssets()
}

const handleAssetGroupUpdated = () => {
  loadAssets()
}

const handleAssetGroupAdded = (addedGroup: AssetGroup) => {
  router.push({ name: "wc-asset-groups-asset-group", params: { assetGroupId: addedGroup.id } })
}

const showCreateAssetModal = async () => {
  try {
    const result = await openModal(AddAssetModal)
    router.push({ name: "wc-assets-asset", params: { assetId: result.assetId } })
  } catch {
    // Modal was dismissed, do nothing
  }
}
</script>
