<template>
  <component :is="layout">
    <router-view />
    <CookiesCard v-if="isCookiesCardVisible" @close-card="closeCard" />
  </component>
  <DialogHost />
</template>
<script lang="ts" setup>
import { computed, watch, ref } from "vue"
import { useMainStore } from "@/store"
import * as Sentry from "@sentry/vue"
import posthog from "posthog-js"
import { useRoute } from "vue-router"
import { useServiceContainerProvider } from "@/services/service-container"
import LandingLayout from "@/modules/auth/LandingLayout.vue"
import { setPageTitle, setPageDescription, setPageImage } from "@/metatags"
import CookiesCard from "@/components/CookiesCard.vue"
import { getEnvironment } from "./environment"
import { gtmService } from "@/services/gtm.service"
import { linkedInInsightsService } from "@/services/linkedInInsights.service"
import { DialogHost } from "vue-promise-modals"

const store = useMainStore()

useServiceContainerProvider()

const route = useRoute()
const layout = computed(() => route.meta.layout || LandingLayout)

const checkCookies = document.cookie.includes("wattcarboncookies")
const userHasAcceptedAllCookies = checkCookies && document.cookie.includes("wattcarboncookies=true")
if (userHasAcceptedAllCookies) {
  gtmService.grantConsent()

  linkedInInsightsService.grantConsent()

  // Tell posthog to opt the user in if they've already opted in based on `wattcarboncookies=true`. Posthog's localstorage opt-in item doesn't persist across subdomains (www vs web)
  posthog.opt_in_capturing()
}

const { DEPLOYMENT } = getEnvironment()

const isCookiesCardVisible = ref(!checkCookies)
const closeCard = () => (isCookiesCardVisible.value = false)
watch(
  () => route,
  (route) => {
    setPageTitle(route.meta.title as string)
    setPageDescription(route.meta.description as string)
    setPageImage(route.meta.image as string)
  },
  { immediate: true, deep: true }
)

watch(
  () => store.auth,
  async (auth) => {
    if (auth.user) {
      const accountId = auth.user.account_id
      const isWCUser = (auth.user.username ?? "").endsWith("@wattcarbon.com")
      const isSpoof = (auth.user.roles ?? []).includes("wc:spoof")
      const id = isSpoof ? `spoofed:${auth.user.id}` : auth.user.id
      const email = isSpoof ? `spoofed:${auth.user.username}` : auth.user.username

      Sentry.setUser({ email })
      Sentry.setContext("account", { id: accountId })

      posthog.identify(id, {
        accountId,
        appVersion: __APP_VERSION__,
        email,
        environment: DEPLOYMENT,
        isSpoof,
        isWCUser,
      })
    } else {
      Sentry.setUser(null)
      Sentry.setContext("account", null)

      posthog.reset()
    }
  },
  { immediate: true }
)
</script>
