import { RouteLocation } from "vue-router"
import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import PortfolioView from "./PortfolioView.vue"
import PortfoliosView from "./PortfoliosView.vue"
import AppLayout from "@/components/layout/AppLayout.vue"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"

const portfolioIdProp = (route: RouteLocation) => ({ portfolioId: scalarFromVectorOrScalar(route.params.portfolioId) })

export const portfolioRoutes: Array<RouteRecordRaw> = [
  {
    path: "/portfolios",
    name: "wc-portfolios",
    component: PortfoliosView,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Portfolios",
    },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/portfolios/:portfolioId",
    name: "wc-portfolio-tracker",
    component: PortfolioView,
    props: portfolioIdProp,
    meta: {
      layout: AppLayout,
      appPortal: AppPortal.Dashboard,
      title: "Portfolio Tracker",
      breadcrumb: { name: "Dashboard", route: "wc-dashboard" },
    },
    beforeEnter: authorizedRoute,
  },
]
