<template>
  <table v-if="pricingScheme.pricingFunction === 'measurement_and_verification'" class="w-full">
    <colgroup span="1" class="border-r border-sagetone"></colgroup>

    <thead>
      <tr>
        <td></td>
        <th scope="col" :colspan="squareFootageColumns.length">Square Footage</th>
      </tr>
      <tr>
        <th></th>
        <th v-for="[min, max] in squareFootageColumns" :key="min" class="pl-4 text-right font-normal">
          {{ (min ?? 0).toLocaleString() }}&ndash;{{ max.toLocaleString() }}
        </th>
      </tr>
    </thead>
    <tbody class="border-t border-sagetone">
      <tr v-for="[buildingType, description] in buildingTypeRows" :key="buildingType" class="even:bg-highlight-pastelazure">
        <th scope="row" class="py-1 text-left font-normal">{{ description }}</th>
        <td v-for="[, max] in squareFootageColumns" :key="max" class="text-right">
          {{ getValueForThreshold(pricingScheme.monthlyPricePerDevicePenniesUsdByKindAndAreaSqft[buildingType] ?? [], max) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { computed, toRef } from "vue"
import { PricingSchemesGetPricingSchemeResponses, AreaBasedPricePoint } from "@/client/types.gen"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"
import _ from "lodash"

const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

type PricingSchemeOut = PricingSchemesGetPricingSchemeResponses[200]

const props = defineProps<{
  pricingScheme: PricingSchemeOut
}>()
const pricingScheme = toRef(props, "pricingScheme")

const buildingTypeRows = computed(() => {
  if (!pricingScheme.value) {
    return []
  }

  return _.sortBy(
    [
      ["single_family_detached", "Single Family (detached)"],
      ["single_family_attached", "Single Family (attached)"],
      ["multi_family_2_4", "Multi-Family (2-4 units)"],
      ["multi_family_5", "Multi-Family (5+ units)"],
      ["mobile_home", "Mobile Home"],
      ["small_office", "Office (small)"],
      ["medium_office", "Office (medium)"],
      ["large_office", "Office (large)"],
      ["small_hotel", "Hotel (small)"],
      ["large_hotel", "Hotel (large)"],
      ["retail_stripmall", "Stripmall"],
      ["retail_standalone", "Standalone Retail"],
      ["quick_service_restaurant", "Restaurant (quick service)"],
      ["full_service_restaurant", "Restaurant (full service)"],
      ["warehouse", "Warehouse"],
      ["outpatient", "Hospital (outpatient)"],
      ["hospital", "Hospital"],
      ["primary_school", "School (primary)"],
      ["secondary_school", "School (secondary)"],
    ],
    ([, value]) => value
  )
})

const squareFootageColumns = computed(() => {
  if (!pricingScheme.value || pricingScheme.value.pricingFunction !== "measurement_and_verification") {
    return []
  }

  const thresholds = new Set<number>()
  for (const [_, pricePoints] of Object.entries(pricingScheme.value.monthlyPricePerDevicePenniesUsdByKindAndAreaSqft)) {
    for (const pricePoint of pricePoints) {
      thresholds.add(pricePoint.maximumAreaSqft)
    }
  }

  return thresholds
    .keys()
    .toArray()
    .sort((a: number, b: number) => a - b)
    .map((value, index, array) => [array[index - 1] ?? undefined, value])
})

const getValueForThreshold = (pricePoints: AreaBasedPricePoint[], threshold: number) => {
  const pricePoint = pricePoints.find((pricePoint: AreaBasedPricePoint) => pricePoint.maximumAreaSqft === threshold)
  if (pricePoint !== undefined) {
    return formatCurrencyInPennies(pricePoint.pricePenniesUsd)
  } else {
    return ""
  }
}
</script>
